import React from "react";
import api from "~/services/api";
import { Label, Input, Select } from "~/componentes/CustomForm";

import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function CreateContent({ estados }) {
  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;

        response = await api.post("/signed/cliente/administracao/organizacao/unidade", {
          ...data,
        });

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao cadastrar essa Unidade, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja cadastrar essa Unidade?`,
        successModal: `Unidade cadastrada com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">Preencha as informações corretamente, e se atente ao (*) pois são campos obrigatórios</p>
        </div>
        <div className="row">
          <Label title="Nome *" className="col s6 m6 l6">
            <Input name="nome" />
          </Label>
          <Label title="Estado *" className="col s6 m6 l2">
            <Select
              isNumber
              list={{ list: estados, value: "id", label: "nome" }}
              name="idEstado"
              firstOption
            />
          </Label>
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateContent;
