import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent() {
  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell className="col-w-2" name="id" inputfilter>
            Id
          </SLTableCell>
          <SLTableCell name="titulo" inputfilter>
            Titulo
          </SLTableCell>
          <SLTableCell name="validade" inputfilter>
            Validade
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
