import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const Menu = styled.div`
  position: relative;
  width: 14rem;
  height: 100%;
  color: ${Colors.Color7};
  background: ${Colors.Color1};
  transition: 0.5s;
  padding: 0rem 1.2rem 1rem 0.4rem;
  
  .menu-scroll {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0em;
    transition: 0.5s;
    height: 100%;
    margin-top: 0rem;

    .menu-full {
      width: 100%;
    }

    ${({ showMaxMenu }) => {
      if (showMaxMenu) {
        return `overflow: auto; width: 100%;`;
      } else {
        return `width: 0em; overflow: hidden;`;
      }
    }}

    .menu-button {
      width: 100%;
      margin-bottom: 0.8rem;
      margin-top: 1rem;
      padding: 0 1rem;
    }
  }
`;
