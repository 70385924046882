export default function formCheckDisabled(props, disableButtons) {
  const { disabled, noLoading } = props;

  if (disabled) {
    return true;
  } else if (noLoading) {
    return false;
  } else {
    return disableButtons;
  }
}
