import React from "react";
import { Materialize } from "~/styles/materializeGrid";
import Input from "~/componentes/CustomForm/Input";
import SenhaInput from "~/componentes/CustomForm/SenhaInput";
import Label from "~/componentes/CustomForm/Label";

function ThisFormElements() {
  return (
    <>
      <Materialize>
        <div className="row">
          <Label className="col s12 m12 l12" title="Email">
            <Input type="email" name="email" required />
          </Label>
          <Label className="col s12 m12 l12" title="Senha">
            <SenhaInput name="senha" required />
          </Label>
        </div>
      </Materialize>
    </>
  );
}

export default ThisFormElements;
