import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { LandPage, Main } from './styles';
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import { Materialize } from "~/styles/materializeGrid";
import { Label, Input, Select } from "~/componentes/CustomForm";

export default function Painel() {
  // const [data, setData] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [loadedData, setLoadedData] = useState(false);
  const { perfil } = useSelector(state => state.auth);
  const [chartPrincipal, setChartPrincipal] = useState([]);
  const [chartSecundario, setChartSecundario] = useState([]);
  const [chartTerceario, setChartTerceario] = useState([]);
  const [dataDe, setDataDe] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10));
  const [dataAte, setDataAte] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10));
  const [centroCustos, setCentroCustos] = useState();
  const [idCentroCusto, setIdCentroCusto] = useState();
  const [servicos, setServicos] = useState();
  const [idServico, setIdServico] = useState();

  useEffect(() => {
    api
      .get('/signed/cliente/gestorservico/dashboard/painel', { params: { dataDe, dataAte, idCentroCusto, idServico } })
      .then(response => setChartPrincipal(response.data));
    api
      .get('/signed/cliente/gestorservico/dashboard/painel/servicos', { params: { dataDe, dataAte, idCentroCusto, idServico } })
      .then(response => setChartSecundario(response.data));
    api
      .get('/signed/cliente/gestorservico/dashboard/painel/dispositivos', { params: { dataDe, dataAte, idCentroCusto, idServico } })
      .then(response => setChartTerceario(response.data));
  }, [dataDe, dataAte, idCentroCusto, idServico]);

  const options = {
    chart: {
      title: "",
    },
    colors: ["#FFAB48", "#FFD147", "#B6DF53", "#2ED3B5"],
  };

  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);

  useEffect(() => {
    async function handleRequests() {
      api
        .get('/signed/geral/acessos/check')
        .then(response => setModulos(response.data));
    }

    handleRequests();

    api
      .get('/signed/cliente/gestorservico/gerenciamento/centroCustos')
      .then(response => setCentroCustos(response.data));

    api
      .get('/signed/cliente/gestorservico/gerenciamento/servicos')
      .then(response => setServicos(response.data));
  }, []);

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Materialize className="materialize">
            <div className="row">
              <div className="divTitle">
                <div className="div-icone">
                  <h4 className="title">Painel</h4>
                  <h5 className="sub-title">Visualize as informações e tenha uma análise prévia dos serviços</h5>
                </div>
              </div>
            </div>
            <div className="row filtro">
              <Label title="" className="col s3 m3 l3 no-padd-l">
                <Select
                  isNumber
                  list={{ list: centroCustos, value: 'id', label: 'titulo' }}
                  name="idCentroCusto"
                  firstOption="Selecione um Centro de Custo"
                  onChange={(e) => {
                    const v = e.currentTarget.value;
                    setIdCentroCusto(v);
                  }}
                />
              </Label>
              <Label title="" className="col s3 m3 l3">
                <Select
                  isNumber
                  list={{ list: servicos, value: 'id', label: 'titulo' }}
                  name="idServico"
                  firstOption="Selecione um Serviço"
                  onChange={(e) => {
                    const v = e.currentTarget.value;
                    setIdServico(v);
                  }}
                />
              </Label>
              <Label title="" className="col s2 m2 l2">
                <Input
                  type="date"
                  name="dataDe"
                  defaultValue={dataDe}
                  onBlur={(e) => {
                    const v = e.currentTarget.value || "";
                    setDataDe(v);
                  }}
                />
              </Label>
              <Label title="" className="col s2 m2 l2">
                <Input
                  type="date"
                  name="dataAte"
                  defaultValue={dataAte}
                  onBlur={(e) => {
                    const v = e.currentTarget.value || "";
                    setDataAte(v);
                  }}
                />
              </Label>
            </div>
          </Materialize>
          <div className="back-section">
            <div className="section-1">
              <div className="chart-barra">
                <div className="title">
                  Serviços por Dia
                </div>
                {chartPrincipal.length > 0 && <Chart
                  className="modo-gamb"
                  chartType="Bar"
                  width="100%"
                  height="20rem"
                  data={chartPrincipal}
                  options={options}
                />}
              </div>
            </div>
            <div className="section-2">
              <div className="left">
                <div className="title">
                  Contagem por Serviços
                </div>
                {chartSecundario.length > 0 && <Chart
                  className="modo-gamb"
                  chartType="PieChart"
                  data={chartSecundario}
                  options={{
                    chart: {
                      title: "",
                    },
                    colors: ["#2ED3B5", "#FFAB48", "#FFD147", "#B6DF53", "#2E7AD3", "#FF5780"],
                  }}
                  width={"100%"}
                  height={"18rem"}
                />}
              </div>
              <div className="right">
                <div className="title">
                  Contagem por Dispositivos
                </div>
                <div className="list">
                  {
                    chartTerceario.map((item) => (
                      <div className="item-list">
                        <div className="cab-list">
                          <p>{item[0]}</p>
                        </div>
                        <div className="val-list">
                          <p>{item[1]}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </LandPage>
    </Main>
  );
}
