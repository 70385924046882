import React from "react";

import { Container } from "./styles";
import loading from "~/assets/icones/loading1.svg";
// import LoadingDots from "~/componentes/LoadingDots";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleDisableButton } from "~/store/modules/disableButtons/actions";
import {
  responseModalToggle,
} from "~/store/modules/confirmacaoModal/actions";

export default function Carregando() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(responseModalToggle());
    dispatch(toggleDisableButton());

    return () => {
      dispatch(responseModalToggle());
    };
  }, [dispatch]);

  return (
    <Container fundoSombra={true}>
      <div className="loadingBody">
        <span className="select"></span>
        <div className="body">
          <p className="title">Carregando...</p>
          <p className="message">Espera só um pouquinho</p>
        </div>
        <img className="loadingIcon" src={loading} alt="loading" />
      </div>
    </Container>
  );
}
