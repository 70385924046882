import styled from "styled-components";
import layout from "./layout";
import Button from "~/componentes/Button";
import { Colors } from "~/config/Colors";

export const StyleColoredButton = styled(Button)`
  border: none;
  font-family: 'Nunito', 'cursive';
  outline: none;
  min-width: fit-content;
  padding: 0 1.7em;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.1s;
  height: 2.2em;
  font-size: 0.8em;
  font-weight: ${(props) => layout(props)?.fontWeight ? layout(props)?.fontWeight : "600"};

  // background-color: ${(props) => layout(props)?.background ? layout(props)?.background : Colors.Color31};
  background-color: ${(props) => layout(props)?.background ? layout(props)?.background : Colors.Color31};
  color: ${(props) => layout(props)?.color ? layout(props)?.color : "none"};
  border: 0.25em solid ${(props) => layout(props)?.borderColor ? layout(props)?.borderColor : "none"};
  border-radius: 0.625em;

  &:active {
    box-shadow: none;
    transform: translateY(0.3em);
  }

  &:hover {
    background-color: ${(props) => layout(props)?.hoverBackground ? layout(props)?.hoverBackground : "none"};
    border: 0.25em solid ${(props) => layout(props)?.hoverBackground ? layout(props)?.hoverBackground : "none"};
  }
`;
