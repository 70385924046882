import styled from 'styled-components';
import SistemaList from '~/pages/Sistema/componentes/SistemaList';
import { Scrollbars } from 'react-custom-scrollbars';
import { Colors } from "~/config/Colors";

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  .col {
    margin: 0 !important;
  }

  .no-padd-l {
    padding-left: 0 !important;
  }

  .filtro {
    margin: 1rem 0 1rem 0;
  }

  .content {
    padding: 0.5rem 0.5rem 2rem 0.5rem;

    .materialize {
      padding: 0.5rem 1.2rem 0rem 1.2rem;
    }
    
    .divTitle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .div-icone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: ${Colors.Color2};
        border-radius: 0.4em;
        padding: 0.15em 0.8em 0.15em 0.3em;

        .title {
          font-size: 1.1rem;
          font-weight: 700;
          color: ${Colors?.Color3};
        }

        .sub-title {
          font-size: 0.75rem;
          font-weight: 500;
          color: ${Colors?.Color15};
        }
      }
    }

    .back-section {
      padding: 1.2em 1.2rem 1.2em 1.2rem;
      background: ${Colors.Color24};
      
      .section-1 {
        width: 100%;
        height: 26rem;
        padding: 1em;
        border-radius: 0.8em;
        background: ${Colors.Color2};

        .modo-gamb {
          rect {
            fill: ${Colors.Color2};
          }
        }
        
        .title {
          width: fit-content;
          padding: 0.4rem 0.8rem;
          background: ${Colors.Color16};
          color: ${Colors.Color17};
          border-radius: 0.6rem;
          font-size: 0.8rem;
          font-weight: 800;
          margin-bottom: 1.4rem;
        }
      }

      .section-2 {
        width: 100%;
        height: 24rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1em;

        .modo-gamb {
          rect {
            fill: ${Colors.Color2};
          }
        }

        .left {
          width: 49%;
          padding: 1em;
          background: ${Colors.Color2};
          border-radius: 0.8em;

          .title {
            width: fit-content;
            padding: 0.4rem 0.8rem;
            background: ${Colors.Color16};
            color: ${Colors.Color17};
            border-radius: 0.6rem;
            font-size: 0.8rem;
            font-weight: 800;
            margin-bottom: 1.4rem;
          }
        }

        .right {
          width: 49%;
          padding: 1em;
          background: ${Colors.Color2};
          border-radius: 0.8em;

          .title {
            width: fit-content;
            padding: 0.4rem 0.8rem;
            background: ${Colors.Color16};
            color: ${Colors.Color17};
            border-radius: 0.6rem;
            font-size: 0.8rem;
            font-weight: 800;
            margin-bottom: 1.4rem;
          }

          .list {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;

            .item-list {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-direction: row;
              height: 2em;
              border-radius: 2em;
              width: 100%;

              .cab-list {
                background: ${Colors.Color16};
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border-radius: 0.3em 0 0 0.3em;
                padding: 0.2em 1em;
                width: 100%;

                p {
                  font-size: 0.8em;
                  color: ${Colors.Color17};
                }
              }
              
              .val-list {
                background: ${Colors.Color19};
                width: 5.5em;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-radius: 0 0.3em 0.3em 0;
                padding: 0.3em 0em;

                p {
                  font-weight: 800;
                  font-size: 0.7em;
                  color: ${Colors.Color22};
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LandPage = styled(Scrollbars)`
  width: calc(100%) !important;
`;

export const Container = styled(SistemaList)``;

