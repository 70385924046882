import styled from "styled-components";
import Modal from "~/componentes/Modal";
import { Colors } from "~/config/Colors";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const ButtonMenuPerfil = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 0rem;
  border: 0;
  background: ${Colors.Color1};
  width: 11rem;

  .texto {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 1rem;
    line-height: 0.8rem;
    
    .nome {
      font-size: 0.7rem;
      color: ${Colors.Color3};
    }
    
    .filial {
      font-size: 0.65rem;
      color: ${Colors.Color28};
    }
  }
  
  .circul-perfil {
    width: 2.3rem;
    height: 2.3rem;
    background: ${Colors.Color13};
    border-radius: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 1.15rem;
      color: ${Colors.Color2};
      line-height: 0.6rem;
    }
  }
`;

export const Content = styled(Modal)`
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0.5rem;
  width: 20rem;
  max-width: 100%;
  box-shadow: 0px 0px 3px 0.1px rgba(0, 0, 0, 0.2);
  z-index: 4;
  padding: 1.2rem;
  background: ${Colors.Color2};
  border-radius: 1em;

  .margin-top {
    margin-top: 1em;
    display: block;
  }

  .sec-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    padding: 0em;
    margin-bottom: 0.5em;

    .title {
      color: ${Colors.Color3};
      font-size: 0.7125rem;
      font-weight: 700;
      line-height: 1em;
      text-transform: uppercase;
    }

    img {
      width: 1em;
    }
  }

  .header-acesso {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5em;

    .lateral-e {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: ${Colors.bgColor4};
      border-radius: 2em;
      padding: 0 1em;
      height: 1.6em;

      .title {
        color: ${Colors.ftColor3};
        font-size: 0.75em;
        font-weight: 600;
        margin: 0;
      }
    }

    .lateral-d {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: ${Colors.bgColor4};
      border-radius: 2em;
      padding: 0 0.5em;

      img {
        width: 0.8em;
      }
    }
  }

  .perfil {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    button {
      margin: 0;
      margin-right: 1em;
      margin-left: 0.5em;
      width: 2.2em;
      height: 2.2em;

      p {
        font-size: 1.2em;
        line-height: 0.9em;
      }
    }

    .content {
      margin-left: 1em;
      
      h5 {
        color: ${Colors.ftColor3};
        font-size: 0.7em;
        font-weight: 600;
      }

      h6 {
        color: ${Colors.ftColor1};
        font-weight: 400;
      }
    }
  }
`;

export const OpcaoButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5em 1em;
  border: 0;
  border-radius: 0.5em;
  background: ${Colors.Color2};
  
  .img-acesso {
    width: 1.5em;
  }

  .texto-acesso {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0.6em;

    h6 {
      color: ${Colors.Color7};
      font-size: 0.7em;
      line-height: 1em;
      font-weight: 700;
    }
  }

`;

export const AcessoButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 0.6rem;
  ${(props) => (props.ativo ? `background: ${Colors.Color16};` : `background: ${Colors.Color2};`)};

  .img-acesso {
    width: 1.5em;
  }

  .texto-acesso {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0.6em;

    h5 {
      color: ${Colors.Color7};
      font-size: 0.65rem;
      line-height: 1.5em;
      font-weight: 700;
    }

    h6 {
      color: ${Colors.Color7};
      font-size: 0.7em;
      line-height: 1em;
      font-weight: 700;
    }
  }

  ::before {
    content: "";
    position: absolute;
    width: 0.2em;
    height: 60%;
    left: 0em;
    top: 20%;
    ${(props) => (props.ativo ? `background: ${Colors.Color13};` : `background: ${Colors.Color2};`)};
    border-radius: 0.2em;
  }
`;
