import React, { memo, useReducer } from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useMemo } from "react";
import ThisTopComponent from "./ThisTopComponent";
import thisReducer from "./thisStore/reducer";
import sistemaListStore, {
  sistemaListUpdateData,
  sistemaListUpdateProps,
  sistemaListUpdateFilterConfig,
  sistemaListUpdateEntityData,
} from "./thisStore/index";
import { IsFunction, IsObject } from "~/utils/CheckDataType";
import { useCallback } from "react";
import FindKeyValue from "~/utils/FindKeyValue";
import { SistemaComponenteInterno } from "../..";
import thisHandleSelectFilter from "../utils/thisHandleSelectFilter";
import { loadingToggle } from "~/store/modules/loadingModal/actions";

import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

import { store } from "~/store";
import ShowLoading from "~/utils/ShowLoading";

/**
 * @param {Object} options Informe o modal que deseja aparecer (create, edit, show), e o data que deve ser passado.
 * @param {Boolean} options.create
 * @param {Boolean} options.edit
 * @param {Boolean} options.show
 * @param {Boolean} options.data
 */
// eslint-disable-next-line no-unused-vars
function setModalDoc({ create, edit, show, data }) { }

/**
 * @param {Object} props
 *  @param {Object} props.tabFilter Usado para filtrar os dados assim que é feito a request do FindAll. Caso seja passado um objeto, será buscado feito uma busca pela chave e comparado o valor, pode ser passado uma função em cada chave ou uma função no lugar do objeto.
 *  @param {Function} props.setter Função que retorna os dados de forma filtrados, caso seja fornecido o pageSize, ou configurações de inputs e selects.
 *  @param {Function} props.entitySetter Identico ao setter dos modais desse componente. Obtém o dado da entidade que sofreu interação através dos botões padrões deste componente ou do modalSetter.
 *  @param {Function} props.reloadSetter Recebe uma função que refaz a requisição para popular a lista.
 *  @param {Object[]} props.data Caso não queira enviar um request com o findAll, esse servirá como a lista de Array de objetos usado para popular a tabela.
 *  @param {Function} props.setCustomFilterModal
 *  @param {Object} props.requests Aceita requisições para utilizar na lógica da lista. O findAll substitui a propriedade data.
 *  @param {Function} props.requests.findAll Faz uma requisição que espera como retorno um array de dados para popular a tabela.
 *  @param {Function} props.requests.deleteOne Caso seja passada uma função, é enviado como parâmetro o dado obtido do findOne ou da linha caso não o tenha informado.
 *  @param {Function} props.requests.restoreOne Caso seja passada uma função, é enviado como parâmetro o dado obtido do findOne ou da linha caso não o tenha informado.
 *  @param {Function} props.requests.findOne Caso seja passada uma função, é enviado como parâmetro o dado obtido da linha clicada.
 *  @param {Number} props.pageSize Padrão 8. A quantidade de registros de dado por página. Se false, não será usado paginação.
 *  @param {Boolean} props.defaultStyle Adiciona uma estilização à div que envolve a lista.
 *  @param {Object[]} props.inputs Se informado, irá adicionar filtragens através dos inputs.
 *  @param {string} props.inputs.filter Obrigatório, informa a chave de busca do filtro.
 *  @param {string} props.inputs.label Obrigatório, informe o nome que deve aparecer no label ou no select.
 *  @param {Object[]} props.selects Se informado, irá adicionar filtragens através dos selects.
 *  @param {string} props.selects.filter informa a chave de busca do filtro.
 *  @param {string} props.selects.outLabel informa a chave de busca do filtro.
 *  @param {Array} props.selects.list Recebe um array e lista as options.
 *  @param {string} props.selects.value Informe o nome da chave que servirá como o value da option.
 *  @param {string} props.selects.label Informe o nome da chave que servirá como o label da option. Tentará usar o value caso não seja informado um label.
 */
function ThisMainComponent({
  setter,
  loaded = false,
  setLoaded,
  entitySetter,
  reloadSetter,
  data: dataProp,
  requests,
  pageSize,
  defaultStyle,
  tabFilter: tabProps,
  setCustomFilterModal,
  inputs: inputsProp,
  selects: selectsProp,
  children,
  initialDataReducer,
  setInitialDataReducer,
  informationPage,
  setterTabFilter,
  manual = false,
  topMain = true,
  setterModoComponent,
  setConfirmSelects,
  ...rest
}) {
  const initialData = {
    globalData: [],
    data: [],
    selects: [],
    inputs: [],
    requests,
  };

  useEffect(() => {
    setInitialDataReducer && setInitialDataReducer(initialData);
  }, [setInitialDataReducer, initialData]);

  const [state, dispatch] = useReducer(thisReducer, initialData);
  const { inputs, selects, entityData, modal } = state || {};

  const [tabFilter, setTabFilter] = useState(tabProps?.initial);
  const [data, setData] = useState([]);
  const [loadedData, setLoadedData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [propFilteredData, setPropFilteredData] = useState([]);

  const Wrapper = defaultStyle ? SistemaComponenteInterno : "div";

  const haveFilter = useMemo(() => inputs?.length > 0 || selects?.length > 0, [inputs, selects]);

  useEffect(() => {
    IsFunction(entitySetter) && entitySetter(entityData);
  }, [entityData, entitySetter]);

  useEffect(() => {
    if (inputsProp || selectsProp) {
      const thisSelects = thisHandleSelectFilter(selectsProp);

      dispatch(sistemaListUpdateFilterConfig({ inputs: inputsProp, selects: thisSelects, setCustomFilterModal }));
    }
  }, [inputsProp, selectsProp, setCustomFilterModal]);

  const renderData = useMemo(() => {
    if (!haveFilter && !pageSize) {
      return data;
    } else if (!pageSize) {
      return filteredData;
    } else {
      return pageData;
    }
  }, [data, filteredData, haveFilter, pageData, pageSize]);

  useEffect(() => {
    renderData && dispatch(sistemaListUpdateData(renderData));
    setter && setter(renderData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderData]);

  useEffect(() => {
    if (entityData && (!modal || (!modal?.create && !modal?.show && !modal?.edit && !modal?.captar && !modal.share && !modal.aprovar))) {
      dispatch(sistemaListUpdateEntityData());
    }
  }, [dispatch, entityData, modal]);

  const reloadData = useCallback(() => IsFunction(requests?.findAll) && ShowLoading(requests.findAll, { successModal: false, errorModal: false }).then((response) => setData(response?.data || [])), [
    requests,
  ]);

  useEffect(() => {
    if (Array.isArray(dataProp)) {
      setData(dataProp);
    } else if (
      (
        (loadedData === false && loaded === false)
        || (loadedData === true && loaded === false)
      )
      && IsFunction(requests?.findAll)
    ) {
      setLoadedData(true);

      if (IsFunction(setLoaded)) setLoaded(true);

      reloadData();
    }
  }, [dataProp, setLoaded, loaded, loadedData, reloadData, requests]);

  useEffect(() => {
    let newData = data;
    if (tabFilter) {
      if (setterTabFilter) {
        setterTabFilter(tabFilter);
      } else {
        newData = data?.filter?.((e) => {
          if (IsFunction(tabFilter)) {
            return tabFilter(e);
          } else if (IsObject(tabFilter)) {
            return Object.entries(tabFilter).every(([key, value]) => {
              let dataValue = FindKeyValue(key, e);
              if (IsFunction(value)) {
                return value(dataValue);
              }
              return dataValue === value;
            });
          }
          return true;
        });
      }
    }

    setter && setter(newData);
    setPropFilteredData(newData);
  }, [setter, data, tabFilter]);

  useEffect(() => {
    dispatch(
      sistemaListUpdateProps({
        requests,
        reloadData,
      })
    );
  }, [reloadData, requests]);

  useEffect(() => {
    IsFunction(reloadSetter) && reloadSetter(() => reloadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadSetter]);

  return (
    <sistemaListStore.Provider value={{ state, dispatch }}>
      {manual == false && (<ThisTopComponent
        {...{
          data: propFilteredData,
          setFilteredData,
          informationPage,
          setTabFilter,
          tabProps,
          topMain,
          setterModoComponent,
          pageSize,
          haveFilter,
          filteredData,
          pageData,
          setPageData,
          setConfirmSelects,
        }}
      />)}

      <Wrapper {...rest}>
        {children}
      </Wrapper>
      <div className="paginacao">
        {tabProps?.list?.length > 0 && (
          <TabsWrapper tabHeight={2.4} tab={tabProps?.tabAtivo}>
            {tabProps?.list?.map((tab, i) => {
              return (
                <Tab onClick={() => setTabFilter(tab?.value)} {...tab}>
                  <p>{tab?.title}</p>
                </Tab>
              );
            })}
          </TabsWrapper>
        )}
      </div>
    </sistemaListStore.Provider>
  );
}

export default memo(ThisMainComponent);
