import React, { useEffect, useState } from "react";
import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import { LandPage, Container, Main } from './styles';
import { Colors } from "~/config/Colors";
import {
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";
import { useSelector } from "react-redux";
import TableComponent from "./TableComponent";

export default function Dispositivos() {
  const [data, setData] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [loadedData, setLoadedData] = useState(false);
  const { perfil } = useSelector(state => state.auth);

  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);

  useEffect(() => {
    async function handleRequests() {
      api
        .get('/signed/geral/acessos/check')
        .then(response => setModulos(response.data));
    }

    handleRequests();
  }, []);

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Container
            requests={{
              findAll: () =>
                api.get('/signed/cliente/gestorservico/gerenciamento/dispositivos', { params: { tipo: "A" } }),
              findOne: thisData =>
                api.get('/signed/cliente/gestorservico/gerenciamento/dispositivo', {
                  params: { id: thisData.id },
                }),
              deleteOne: thisData =>
                api.delete('/signed/cliente/gestorservico/gerenciamento/dispositivo', {
                  data: { id: thisData.id },
                }),
            }}
            setter={setData}
            setLoaded={setLoadedData}
            loaded={loadedData}
            informationPage={{
              title: 'Dispositivos',
              subTitle: "Adiocione os Dispositivos",
              icone: 'submenu-dispositivo',
            }}
            tabFilter={{
              initial: { ativo: true },
              tabAtivo: 0,
              list: [
                {
                  corDestaque: Colors?.Color4,
                  corFundo: Colors?.Color8,
                  title: 'Ativo',
                  value: { ativo: true },
                },
                {
                  corDestaque: localStorage.getItem("tema") == "E" ? '#9A3636' : '#e68282',
                  corFundo: Colors?.Color8,
                  title: 'Desativado',
                  value: { ativo: false },
                },
              ],
            }}
            pageSize={7}
            inputs={[
              { filter: 'titulo', label: 'Titulo' },
              { filter: 'codigoDevice', label: 'Device' },
            ]}
          >
            <SLEditModal title="Atualizar Dispositivo" setter={setMountedData}>
              {mountedData && modulos && (
                <CreateEditContent {...{ modulos, mountedData }} />
              )}
            </SLEditModal>

            <SLCreateModal title="Adicionar Dispositivo">
              {modulos && <CreateEditContent {...{ modulos }} />}
            </SLCreateModal>

            <TableComponent />
          </Container>
        </div>
      </LandPage>
    </Main>
  );
}
