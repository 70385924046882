import React from 'react';

import { ItemMenu } from './styles';

function MenuButton({
  desktop,
  menu,
  selected,
}) {
  return (
    <ItemMenu desktop={desktop} selected={selected}>
      <div className="content-item-menu">
        <p>{menu.nome}</p>
      </div>
    </ItemMenu>
  );
}

export default MenuButton;
