import styled from 'styled-components';
import { SLForm } from '~/pages/Sistema/componentes/SistemaList';
import { Colors } from '~/config/Colors';

export const Container = styled(SLForm)`
  width: 100%;
  max-width: 100%;

  .row {
    margin-bottom: 0.5em;
  }

  .row-left {
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .no-margin {
    margin: 0 !important;
  }

  .header {
    padding: 0 0.6em;
    margin-bottom: 1em;

    .h-descricao {
      font-size: 0.7125em;
      line-height: 0.8em;
      color: ${Colors.Color7};
    }
  }

  .title_p {
    text-align: left;
    margin-left: 1em;
    max-width: 100%;
    font-weight: 500;
    font-size: 0.75em;
    line-height: 1.8em;
    margin-bottom: 0.5em;
    color: ${Colors.Color7};
    ${props => props.row && `width: 6em; margin-bottom: 0em;`}
  }

  .header-os {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.6em;

    .header-left {
      .title_p {
        margin-left: 0em;
      }
    }

    .header-right {

      .check-all {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title_p {
          margin: 0em;
          margin-right: 1em;
        }

        .card-check {
          cursor: pointer;
          width: 1.5em;
          height: 1.5em;
          background: ${Colors.Color23};
          border-radius: 0.4em;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        .checked {

          span {
            width: 1em;
            height: 1em;
            background: ${Colors.Color12};
            display: block;
            border-radius: 0.3em;
          }
        }
      }
    }
  }

  .atv-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 0.5em;
    border-radius: 0.4em;

    .atv-content-top {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background: ${Colors.Color23};
      border-radius: 0.4em;
      padding: 0.5em 1em 0.5em 1em;

      .col {
        border-radius: 0.4em;
        padding: 0.2em 0.4em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        p {
          font-size: 0.75em;
          font-weight: 500;
          color: ${Colors.Color7};
          width: 100%;
        }

        p.ativo {
          color: ${Colors.Color4};
        }
      }

      .center {
        text-align: center;
      }

      .card-check {
        cursor: pointer;
        width: 1.5em;
        height: 1.5em;
        background: ${Colors.Color13};
        border-radius: 0.4em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .checked {

        span {
          width: 1em;
          height: 1em;
          background: ${Colors.Color12};
          display: block;
          border-radius: 0.3em;
        }
      }
    }

    .col0-5 {
      width: 2.5em !important;
    }
    
    .col1 {
      width: 5em !important;
    }
    
    .col2 {
      width: 10em !important;
    }
    
    .col3 {
      width: 15em !important;
    }

    .col4 {
      width: 20em !important;
    }

    .col5 {
      width: 25em !important;
    }
  }
`;
