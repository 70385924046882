import styled from "styled-components";
import Check from "~/componentes/CustomForm/Check";
import CheckWrapper from "~/componentes/CustomForm/CheckWrapper";
import { Colors } from "~/config/Colors";

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;

  .Acoordion_title_p {
    text-align: left;
    margin-bottom: -0.25em;
    max-width: 100%;
    font-weight: 500;
    font-size: 0.8em;
    line-height: 1.8em;
    margin-bottom: 0.3em;
    color: ${Colors.Color7};
  }

  ${(props) => props.minimizable && `position: relative;`}

  .Acoordion_Button_minimizable {
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    border: none;
    background: inherit;

    i.green {
      color: #4fa754;
    }

    i.red {
      color: #ff4031;
    }
  }
`;

export const AcessosContent = styled(CheckWrapper)`
  background: ${Colors.Color2};

  .item-group-acoordion {
    margin-bottom: 0.5em;

    .group-acoordion {
      // border: 0.1em solid ${Colors.Color8};
      border-radius: 0.5em;
    }
  }
`;

export const BoxContainer = styled.div`
  margin: -0.1em 0em 0.5em 0em;
`;

export const ModuloDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0.3em;
  cursor: pointer;
  // border-bottom: 0.1em solid ${Colors.Color1};
  // border-top: 0.1em solid ${Colors.Color1};
  background: ${Colors.Color1};
  margin-bottom: 0.5em;
  border-radius: 0.6em;

  .Acessos_Box_selecionados_icon_div {
    display: flex;

    i,
    p {
      align-self: center;
      height: fit-content;
    }

    p {
      color: ${Colors.Color7};
      margin-right: 0.8em;
      font-size: 0.75em;
      line-height: normal;
    }
  }

  .buttonItemAcoordion {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: ${Colors.Color2};
    border-radius: 0.35em;
    width: 1.8em;
    height: 1.35em;

    i {
      color: ${Colors.Color7};
      font-size: 1.2em;
    }
  }
`;

export const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  h6 {
    margin-left: 0.4em;
    text-align: left;
    font-weight: 400;
    font-size: 0.75em;
    color: ${(props) => {
      if (props.subItem === true)
        return Colors.Color7;
      else
        return Colors.Color7;
    }};
  }
`;

export const MenusDiv = styled.div`
  list-style: none;
  padding: 0 0 0 1.625em;

  .subItem {
    margin: 0.75em 0;
  }
`;

export const CheckBox = styled(Check)`
  margin: 0 0.75em 0 0;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  background: #d0d7db;
  border-radius: 0.125em;
  transition: 0.3s ease;
  outline: none;
  border: none;
  box-shadow: none;
  position: relative;

  :hover {
    box-shadow: none;
  }

  :checked {
    background: #1ca9d0;
  }
  :checked ~ h6 {
    color: #293f57;
  }

  ::before {
    position: absolute;
    content: "";
    width: 0.875em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    transform: rotate(-50deg);
    top: 0.563em;
    left: 0.313em;
    transition: 0.3s ease;
    opacity: 0;
  }
  ::after {
    position: absolute;
    content: "";
    width: 0.375em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    top: 0.689em;
    left: 0.188em;
    transition: 0.3s ease;
    opacity: 0;
  }

  :checked::before,
  :checked::after {
    opacity: 1;
  }

  :disabled {
    background: #dedede;
    color: #ffffff;
    border: none;
  }
`;
