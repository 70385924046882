import React from "react";
import FileInput from "../..";
import { useState } from "react";

import { Container, AddFile, PreviewImageStyle, FileButton } from "./styles";
import { useEffect } from "react";

/**
 * Esse input não possui ligação com o Form.
 * @param {Object} props
 * @param {string} props.maxHeight É o mesmo que passar o imgStyle={{maxHeight: "value"}};
 * @param {Object} props.imgStyle Style do elemento img
 * @param {Object} props.dotBoxStyle Style da caixa vazia.
 * @param {any} props.defaultPreview Caso seja passado uma imagem, será utilizada como o default.
 * @param {Function} props.innerRef Função que recebe a ref como parâmetro.
 * @param {Function} props.previewFile Recebe uma função onde a utiliza passando como argumento um objeto do tipo file no formato de preview para html.
 * @param {formFileDoc} props.formFile Recebe uma função que será utiliza passando como argumento um objeto do tipo file no formato utilizado para form.
 * @param {FormData} props.formData FormData que será usado para adicionar o arquivo.
 */
function PreviewFileInput({
  maxHeight,
  preventCancelClick = true,
  defaultPreview,
  imgStyle,
  readOnly,
  dotBoxStyle,
  previewFile,
  innerRef,
  className,
  style,
  checkPreview,
  ...rest
}) {
  const [preview, setPreview] = useState();
  const [thisRef, setThisRef] = useState();

  useEffect(() => {
    if (defaultPreview) {
      setPreview(defaultPreview);
    }
  }, [defaultPreview]);

  useEffect(() => {
    checkPreview && checkPreview(!!preview);
  }, [preview, checkPreview]);

  return (
    <Container maxHeight={maxHeight} style={style} className={className}>
      {!readOnly && (
        <FileInput
          {...rest}
          preventCancelClick={preventCancelClick}
          previewFile={(data) => {
            setPreview(data);
            previewFile && previewFile(data);
          }}
          innerRef={(e) => {
            setThisRef(e);
            innerRef && innerRef(e);
          }}
          style={{ display: "none" }}
        />
      )}
      {preview ? (
        <>
          <div className="PreviewFileStyle_Img">
            <PreviewImageStyle
              maxHeight={maxHeight}
              style={imgStyle}
              src={preview}
              alt="Preview"
              onClick={() => {
                if (readOnly) {
                  window.open(defaultPreview, "_blank");
                } else {
                  thisRef?.click && thisRef.click();
                }
              }}
            />
          </div>
          {!readOnly && (
            <div className="PreviewFileInput_Button">
              <FileButton
                type="button"
                className="PreviewFileInput_Container_removeButton"
                onClick={() => {
                  setPreview();
                  thisRef && (thisRef.value = "");
                }}
              >
                <i className="material-icons">delete</i>
              </FileButton>
              {preview === defaultPreview && (
                <FileButton
                  style={{ marginLeft: "1em" }}
                  type="button"
                  className="PreviewFileInput_Container_verButton"
                  onClick={() => window.open(defaultPreview, "_blank")}
                >
                  <i className="material-icons">visibility</i>
                </FileButton>
              )}
            </div>
          )}
        </>
      ) : (
        <AddFile
          style={dotBoxStyle}
          type="button"
          onClick={() => thisRef?.click()}
        >
          <h4>
            <i className="material-icons">get_app</i>{" "}
            {readOnly ? "Não há arquivos" : "Arquivo"}
          </h4>
        </AddFile>
      )}
    </Container>
  );
}

export default PreviewFileInput;
