import React, { useState } from "react";

import {
  AcessosContent,
  ModuloDiv,
  CheckBoxLabel,
  MenusDiv,
  BoxContainer,
  Container,
} from "./styles";
import CheckBox from "../CustomForm/Check/styles/CheckBox";
import Button from "../Button";
import { useEffect } from "react";

function Box({ item, showOnly, selecionados }) {
  const [collapsed, setCollapsed] = useState(true);
  const moduloRef = React.useRef();

  return (
    <BoxContainer className="li_primary">
      <ModuloDiv
        onClick={(e) =>
          !moduloRef?.current?.contains(e.target) && setCollapsed(!collapsed)
        }
      >
        <CheckBoxLabel ref={moduloRef}>
          {!showOnly && <CheckBox value={item?.lista?.map((e) => e.key) || []} />}
          <h6>{item?.title}</h6>
        </CheckBoxLabel>
        <div className="Acessos_Box_selecionados_icon_div">
          {!showOnly && (
            <p>
              {selecionados}/{item?.lista?.length}
            </p>
          )}
          <div className="buttonItemAcoordion">
            <i className={`material-icons`}>
              {collapsed ? "add" : "remove"}
            </i>
          </div>
        </div>
      </ModuloDiv>

      {!collapsed && (
        <MenusDiv>
          {item?.lista.map((subItem) => (
            <div key={subItem?.path} className="subItem">
              <CheckBoxLabel subItem={true}>
                {!showOnly && <CheckBox subItem={true} value={subItem?.key} />}
                <h6>
                  {showOnly && "- "}
                  {subItem?.title}
                </h6>
              </CheckBoxLabel>
            </div>
          ))}
        </MenusDiv>
      )}
    </BoxContainer>
  );
}

/**
 * @param {Object} props
 * @param {Array} props.itens Recebe uma lista de itens com sub itens.
 * @param {Array} props.defaultSelect Recebe uma lista de códigos de menus para popular inicialmente os dados e checkBoxes.
 * @param {Array} props.disabledData Disabilita a seleção de qualquer menu que esteja nessa lista.
 * @param {Array} props.defaultDisabledData Disabilita e selecionada qualquer menu que esteja nessa lista.
 * @param {string} props.reloadDependency Reinicia os checks para as configurações iniciais sempre que a dependência for alterada.
 * @param {string} props.title Nome do título
 * @param {string} props.name nome da chave que será enviada para o Form.
 * @param {string} props.showOnly Se true, irá desativar toda a parte referente a um formulário, mantendo apenas visualização.
 * @param {string} props.filterSubItemId Array de códigos de menus. Usado para realizar um filtro, mostrando apenas os menus que tenham os códigos informados.
 * @param {string} props.minimizable Adiciona um botão de minimizar a listagem de módulos.
 * @param {string} props.keepEmptyArray  Padrão true, mantém o array no Form mesmo que esteja vazio.
 * @param {string} props.setter Função para obter os dados sem precisar do Form.
 */
function Acoordion({
  itens,
  defaultSelect,
  disabledData,
  defaultDisabledData,
  reloadDependency,
  title = "Acessos",
  name = "acessos",
  showOnly,
  filterSubItemId,
  minimizable,
  keepEmptyArray = true,
  setter,
  ...rest
}) {
  const [collapsed, setCollapsed] = useState(false);
  const [selecionados, setSelecionados] = useState([]);
  const [handledItens, setHandledItens] = useState();

  useEffect(() => {
    if (Array.isArray(itens) && itens?.length > 0) {
      if (Array.isArray(filterSubItemId) && filterSubItemId.length > 0) {
        let thisItens = [];

        itens.forEach((item) => {
          const { listaSubItem, ...restItem } = item || {};
          let thisSubItens = item?.listaSubItem?.filter((e) => filterSubItemId.includes(e.key));

          if (Array.isArray(thisSubItens) && thisSubItens.length > 0) {
            thisItens.push({ ...restItem, listaSubItem: thisSubItens });
          }
        });

        if (thisItens.length > 0) {
          setHandledItens(thisItens);
        }
      } else {
        setHandledItens(itens);
      }
    }
  }, [itens, filterSubItemId]);


  function addItensGroupAcoordion(lista = []) {
    let itens = [];

    for (let index = 0; index < lista.length; index += 2) {
      if (lista.length <= (index + 1)) {
        let item1 = lista[index];
        itens.push(
          <div className="row">
            <div className="item-group-acoordion col s12 m12 l6">
              {item1?.title && <p className="Acoordion_title_p">{item1?.title}</p>}
              <div className="group-acoordion">
                {
                  item1?.lista?.map((subItem) => (
                    <Box
                      key={subItem?.path}
                      item={subItem}
                      showOnly={showOnly}
                      selecionados={
                        subItem?.lista?.filter((r) => selecionados?.includes(r.key))?.length || 0
                      }
                    />
                  ))
                }
              </div>
            </div>
          </div>
        );
      } else {
        let item1 = lista[index];
        let item2 = lista[index + 1];
        itens.push(
          <div className="row">
            <div className="item-group-acoordion col s12 m12 l6">
              {item1?.title && <p className="Acoordion_title_p">{item1?.title}</p>}
              <div className="group-acoordion">
                {
                  item1?.lista?.map((subItem) => (
                    <Box
                      key={subItem?.path}
                      item={subItem}
                      showOnly={showOnly}
                      selecionados={
                        subItem?.lista?.filter((r) => selecionados?.includes(r.key))?.length || 0
                      }
                    />
                  ))
                }
              </div>
            </div>
            <div className="item-group-acoordion col s12 m12 l6">
              {item2?.title && <p className="Acoordion_title_p">{item2?.title}</p>}
              <div className="group-acoordion">
                {
                  item2?.lista?.map((subItem) => (
                    <Box
                      key={subItem?.path}
                      item={subItem}
                      showOnly={showOnly}
                      selecionados={
                        subItem?.lista?.filter((r) => selecionados?.includes(r.key))?.length || 0
                      }
                    />
                  ))
                }
              </div>
            </div>
          </div>
        );
      }
    }

    return itens;
  }

  return (
    <Container minimizable={minimizable} {...rest}>
      {(!minimizable || (minimizable && !collapsed)) && (
        <AcessosContent
          {...(showOnly
            ? { as: "div" }
            : {
              reloadDependency,
              defaultData: defaultSelect,
              defaultDisabledData,
              disabledData,
              isArray: true,
              keepEmptyArray,
              setter: (thisData) => {
                setSelecionados(thisData);
                setter && setter(thisData);
              },
            })}
          name={name}
        >
          {addItensGroupAcoordion(handledItens)}
        </AcessosContent>
      )}
      {minimizable && (
        <Button
          className="AcessosList_Button_minimizable"
          type="button"
          onClick={() => setCollapsed(!collapsed)}
        >
          <i className={`material-icons ${collapsed ? "green" : "red"}`}>
            {collapsed ? "add" : "remove"}
          </i>
        </Button>
      )}
    </Container>
  );
}

export default Acoordion;
