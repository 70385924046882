import React, { useEffect, useState } from 'react';
import api from '~/services/api';
import EditContent from './EditContent';
import CreateContent from './CreateContent';
import { LandPage, Container, Main } from './styles';
import { Colors } from "~/config/Colors";
import TableComponent from './TableComponent';
import {
  SLEditModal,
  SLCreateModal,
} from '~/pages/Sistema/componentes/SistemaList';
import { useSelector } from 'react-redux';

export default function Perfis() {
  const [gruposPerfis, setGruposPerfis] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [loadedData, setLoadedData] = useState(false);
  const { perfil } = useSelector(state => state.auth);

  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);

  useEffect(() => {
    api
      .get('/signed/empresa/administracao/gerenciamento/gruposperfis')
      .then(response => setGruposPerfis(response.data));
    api
      .get('/signed/empresa/administracao/organizacao/unidades')
      .then(response => setUnidades(response.data));
  }, []);

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Container
            requests={{
              findAll: () =>
                api.get('/signed/empresa/administracao/gerenciamento/perfis'),
              findOne: thisData =>
                api.get('/signed/empresa/administracao/gerenciamento/perfil', {
                  params: { id: thisData.id },
                }),
              deleteOne: thisData =>
                api.delete(
                  '/signed/empresa/administracao/gerenciamento/perfil',
                  {
                    data: { id: thisData.id },
                  },
                ),
            }}
            setLoaded={setLoadedData}
            loaded={loadedData}
            informationPage={{
              title: 'Perfis',
              subTitle: 'Cadaste aqui os usuários e quais acessos eles terão',
              icone: 'submenu-usuarios',
            }}
            pageSize={6}
            tabFilter={{
              initial: { ativo: true },
              tabAtivo: 1,
              list: [
                { corDestaque: "#F4907A", corFundo: "#FFF0ED", title: "Desativado", value: { ativo: false, } },
                { corDestaque: Colors?.Color4, corFundo: Colors?.Color8, title: "Ativo", value: { ativo: true } },
              ]
            }}
          >
            <TableComponent {...{ unidades, gruposPerfis }} />

            <SLEditModal title="Atualizar Perfil" setter={setMountedData}>
              {mountedData && unidades && (
                <EditContent {...{ unidades, mountedData }} />
              )}
            </SLEditModal>

            <SLCreateModal title="Novo Perfil">
              {unidades && <CreateContent {...{ unidades }} />}
            </SLCreateModal>
          </Container>
        </div>
      </LandPage>
    </Main>
  );
}
