import React, { useState } from 'react';
import { Container, ThisCard, ScrollOS } from './styles';

// import IconClientesAtivo from '~/assets/icones/IconClientesAtivo.svg';
// import IconClientesAtivoB from '~/assets/icones/IconClientesAtivoB.svg';
function CustomBodyContent({ data }) {
  const [linhaAtiva, setLinhaAtiva] = useState([]);

  return (
    <Container>
      <ScrollOS>
        <div className="content-ct">
          {data?.map((thisData, i) => (
            <ThisCard
              className={
                linhaAtiva === thisData?.id ? 'card-item ativo' : 'card-item'
              }
              {...{ data: thisData, linhaAtiva, setLinhaAtiva }}
              key={thisData?.id || i}
              id={thisData?.id || i}
              index={i}
              ativo={linhaAtiva === thisData?.id ? true : false}
            >
              <div className="card-content">
                <div className="card-body">
                  <div className="card-text">
                    <p className="descricao">#{thisData?.codigo}</p>
                    <p className="titulo">{thisData?.nomeFantasia}</p>
                    <p className="descricao-cord">{thisData?.email}</p>
                  </div>
                </div>
                <div className="card">
                  {/* <img src={localStorage.getItem("tema") == "E" ? IconClientesAtivo : IconClientesAtivoB} alt="Vazio" /> */}
                </div>
              </div>
              <div className="card-bottom">
                {thisData?.produtos?.map((thisProduto, o) => (
                  <div className="card-grupo">
                    <p className="grupoperfil">{thisProduto?.produto?.titulo}</p>
                  </div>
                ))}
              </div>
            </ThisCard>
          ))}
        </div>
      </ScrollOS>
    </Container>
  );
}

export default CustomBodyContent;
