import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Materialize } from "~/styles/materializeGrid";
import { Label, TextArea, Input } from "~/componentes/CustomForm";

import { ScrollOS, Container, ButtonMenuPerfil, ThisCardAtvi, ThisCard, ThisCardOS, ThisCardTopOS, Content, TopMenu, Main, MainScroll, ButtonMenu } from "./styles";
import OsTemplate from './Template/OsTemplate';
import NunitoSemiBold from '~/utils/Nunito-semiBold.js';

import { renderToString } from "react-dom/server";
import { jsPDF } from 'jspdf';
import { store } from "~/store";
import { loadingToggle } from "~/store/modules/loadingModal/actions";
import api from '~/services/api';
import UserDevice from "~/componentes/UserDevice";

export default function RelatorioDashboard() {
  const dispatch = store.dispatch;
  const [data, setData] = useState();
  const { desktop } = UserDevice();
  let { hash } = useParams();
  const [dataPage, setDataPage] = useState([]);

  const handleDownload = async () => {
    dispatch(loadingToggle());

    let htmlElement = <OsTemplate data={data} />;
    let elementAsString = renderToString(htmlElement);

    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: 'p'
    });
    doc.addFileToVFS('Nunito-semibold.ttf', NunitoSemiBold);
    doc.addFont('Nunito-semibold.ttf', 'Nunito', 'normal');
    doc.setFont('Nunito');

    doc.setFontSize(10);
    doc.html(elementAsString, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")), "_self");
        dispatch(loadingToggle());
      },
    });
    // doc.save("two-by-four.pdf");
  };

  useEffect(() => {
    if (data) {
      handleDownload();
    }
  }, [data]);

  useEffect(() => {
    api.get("/unsigned/relatorioenviado", { params: { osHash: hash } })
      .then(response => setData(response.data));
  }, []);

  return (
    <>
      <Container desktop={desktop}>
        {/* <OsTemplate data={data} /> */}
      </Container>
    </>
  );
}