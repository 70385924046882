import styled from 'styled-components';
import SistemaList from '~/pages/Sistema/componentes/SistemaList';

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const LandPage = styled.div`
  width: calc(100%) !important;

  .content {
    padding: 0em 1em 0em 1em;
    height: 100%;

    .container-pj {
      height: 100%;
    }
  }
`;

export const Container = styled(SistemaList)``;