import { IsObject } from "~/utils/CheckDataType";

export default function thisHandleSelectFilter(selects) {
  function handleSelect(data) {
    const { filter, outLabel, list, value, label, ...thisRest } = data || {};

    if (filter && list && value) {
      return {
        filter: filter,
        outLabel,
        list: {
          list,
          value,
          label,
        },
        ...thisRest,
      };
    }
    return undefined;
  }

  if (Array.isArray(selects)) {
    let thisResult = [];

    selects.forEach((thisSelect) => {
      const thisValue = handleSelect(thisSelect);

      if (thisValue) {
        thisResult.push(thisValue);
      }
    });

    return thisResult;
  } else if (IsObject(selects)) {
    return handleSelect(selects);
  }

  return undefined;
}
