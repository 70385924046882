import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

function TableComponent({ estados }) {
  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell className="col-w-1" name="codigo" inputfilter>
            Id
          </SLTableCell>
          <SLTableCell name="nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell className="col-w-2 text-center" selectfilter={{ list: estados, value: "uf", label: "nome", outLabel: "Estado" }} name="estado.uf">
            Uf
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
