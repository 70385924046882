import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1em;

  button {
    margin: 0 0 0em 1em;
  }
`;
