import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  .ordem {
  width: 4em;
  margin-left: 0.5em;
  }
  .SingleValueList_div {
    display: flex;
    align-items: center;
    background: ${Colors.Color13};
    color: ${Colors.Color2};
    width: fit-content;
    padding: 0.5em;
    margin: 0em 0.5em 0.5em 0;
    border-radius: 0.5em;

    .SingleValueList_div_IconButton {
      color: ${Colors.Color2};
      font-size: 0.8em;
      margin-right: 0.5em;
    }

    span {
      font-size: 0.8em;
    }
  }
`;
