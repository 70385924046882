import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const Container = styled.div`
  color: #333333;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 1em;

  .SistemaModalHeader_h4_title {
    font-weight: 400;
    font-size: 1.2em;
    color: ${Colors.Color4};
    margin-bottom: 0.5em;
  }

  .SistemaList_ModalHeader_closeButton {
    color: ${Colors.ft_color_1};
    position: absolute;
    top: 0;
    right: 0;
  }
`;
