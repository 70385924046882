import React, { useState, useEffect, useContext } from "react";

import { ContainerTable, StyledTable } from "./styles";
import FindKeyValue from "~/utils/FindKeyValue";
import { IsObject, IsFunction } from "~/utils/CheckDataType";
import sistemaListStore, {
  sistemaListUpdateFilterConfig,
} from "../../ThisMainComponent/thisStore";
import { useMemo } from "react";
// import CustomScrollBar from "~/componentes/CustomScrollBar";
import Pagination from "~/componentes/Pagination";
import thisHandleSelectFilter from "../../utils/thisHandleSelectFilter";
import ThisLine from "./ThisLine";

function ThisTable({
  children,
  noActions,
  inputs: inputsProp,
  selects: selectsProp,
  noEdit,
  pageSize,
  noDelete,
  noShow,
  noRestore,
  buttonIcon,
  data: dataProp,
  ...rest
}) {
  const { state, dispatch } = useContext(sistemaListStore);
  const { data: dataState } = state || {};
  const [bodyCells, setBodyCells] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [linhaAtiva, setLinhaAtiva] = useState([]);

  const data = useMemo(() => (pageSize ? pageData : dataProp || dataState), [
    dataProp,
    dataState,
    pageData,
    pageSize,
  ]);

  useEffect(() => {
    let thisCells = [];
    let inputs = Array.isArray(inputsProp) ? [...inputsProp] : [];
    const checkSelectsProp = thisHandleSelectFilter(selectsProp);
    let selects = Array.isArray(checkSelectsProp) ? [...checkSelectsProp] : [];

    React.Children.forEach(children, (child) => {
      const { name, inputfilter, selectfilter, children: childChildren, ...rest } =
        child?.props || {};

      if (name) {
        thisCells.push({ name, ...rest });

        function checkOutLabel(filt) {
          return typeof filt === "string"
            ? filt
            : typeof childChildren === "string" && childChildren;
        }

        if (inputfilter) {
          const thisInputLabel = checkOutLabel(inputfilter);

          thisInputLabel && inputs.push({ filter: name, label: thisInputLabel });
        }

        if (IsObject(selectfilter)) {
          const { outLabel, ...thisRest } = selectfilter;
          const thisOutLabel = checkOutLabel(outLabel);

          const thisCheckSelect = thisHandleSelectFilter({
            ...thisRest,
            filter: name,
            outLabel: thisOutLabel,
          });

          if (thisCheckSelect) {
            selects.push(thisCheckSelect);
          }
        }
      } else {
        thisCells.push({});
      }
    });

    setBodyCells(thisCells);
    dispatch(sistemaListUpdateFilterConfig({ inputs, selects }));
  }, [children, dispatch, inputsProp, selectsProp]);

  function handleTd(thisCell, obj) {
    const { name, isDateOnly, formatValue } = thisCell || {};

    let thisValue = name ? FindKeyValue(name, obj) : "";

    if (thisValue) {
      if (IsFunction(formatValue)) {
        return formatValue(thisValue);
      } else if (isDateOnly) {
        return new Date(thisValue).toLocaleDateString("pt-BR", { timeZone: "UTC" });
      }
    }

    return thisValue;
  }

  // const emptyRows = useMemo(() => {
  //   let newRows = [];

  //   for (let rowIndex = data?.length || 0; rowIndex < pageSize; rowIndex++) {
  //     newRows.push(rowIndex);
  //   }

  //   return newRows;
  // }, [data, pageSize]);

  return (
    <div className="sec-tabela">
      {pageSize && (
        <Pagination
          data={dataProp || dataState}
          size={pageSize}
          getInfo={({ pageData }) => setPageData(pageData)}
        />
      )}
      <ContainerTable>
        {/* <CustomScrollBar> */}
        <StyledTable {...rest}>
          <thead>
            <tr>
              {children}
            </tr>
          </thead>
          <tbody>
            {data?.map((thisData, i) => (
              <ThisLine
                key={thisData?.id || i}
                className={linhaAtiva === thisData?.id ? "ativo" : ""}
                {...{
                  id: thisData?.id,
                  linhaAtiva,
                  setLinhaAtiva,
                  data: thisData,
                  noEdit,
                  noDelete,
                  noRestore,
                }}
              >
                {bodyCells?.map((thisCell, ci) => (
                  thisCell?.addTextP
                    ? <td key={thisCell.name || ci} {...thisCell}><p {...{ className: thisCell?.className }}>{handleTd(thisCell, thisData)}</p></td>
                    : <td key={thisCell.name || ci} {...thisCell}>{handleTd(thisCell, thisData)}</td>
                ))}
              </ThisLine>
            ))}
            {/* {emptyRows?.map((e) => (
                <tr key={e}>
                  {bodyCells?.map((r, i) => (
                    <td key={i} />
                  ))}
                  <td />
                </tr>
              ))} */}
          </tbody>
        </StyledTable>
        {/* </CustomScrollBar> */}
      </ContainerTable>
    </div>
  );
}

export default ThisTable;
