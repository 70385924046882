import React, { useState } from 'react';
import { Container, ThisCard } from './styles';
import IconSubMenuCC from '~/assets/submenus/submenu-grupos.svg';

function CustomBodyContent({ data }) {
  const [linhaAtiva, setLinhaAtiva] = useState([]);

  return (
    <Container>
      {data?.map((thisData, i) => (
        <ThisCard
          className={
            linhaAtiva === thisData?.id ? 'card-item ativo' : 'card-item'
          }
          {...{ data: thisData, linhaAtiva, setLinhaAtiva }}
          key={thisData?.id || i}
          id={thisData?.id || i}
          index={i}
          ativo={linhaAtiva === thisData?.id ? true : false}
        >
          <div className="card-content">
            <div className="card-body">
              <div className="card-text">
                <p className="descricao">#{thisData?.codigo}</p>
                <p className="titulo">{thisData?.nome}</p>
              </div>
            </div>
            <div className="card">
              <img src={IconSubMenuCC} alt="Vazio" />
            </div>
          </div>
          <div className="card-bottom">
            <div className="card-grupo">
              <p className="grupoperfil">{thisData?.quantidadePerfis} {thisData?.quantidadePerfis > 1 ? "Perfis" : "Perfil"}</p>
            </div>
          </div>
        </ThisCard>
      ))}
    </Container>
  );
}

export default CustomBodyContent;
