import styled from 'styled-components';
import { Colors } from '~/config/Colors';
import Card from '~/pages/Sistema/componentes/Card';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

export const ThisCard = styled(Card)`
  margin-bottom: 1.3em;
  border-radius: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc((100% / 4) - 1em);
  height: 7.5em;
  position: relative;
  overflow: hidden;
  background: ${Colors.Color25};
  margin-right: 1em;

  .card-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin-top: 0.5em;

    .card-grupo {
      background: ${Colors.Color20};
      border-radius: 0.4em;
      padding: 0.2em 0.6em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 0.5em;
      margin-right: 0.5em;
      margin-bottom: 0.5em;

      .grupoperfil {
        font-size: 0.65em;
        font-weight: 800;
        color: ${Colors.Color4};
      }
    }
  }

  .card-color {
    width: 3em;
    height: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4em;
    background: ${props => (props?.cor ? '#' + props?.cor : Colors.Color4)};
  }
  
  .card-content {
    display: flex;

    .card-body {
      width: 100%;

      .card-text {
        display: flex;
        flex-direction: column;

        .descricao {
          font-size: 0.66em;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          display: -webkit-box;
          overflow: hidden;
          font-weight: 800;
          color: ${Colors.Color4};
        }

        .titulo {
          font-size: 0.8em;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 95%;
          font-weight: 800;
          color: #8F9DAF;
    
        }

        .sub-titulo {
          font-size: 0.55rem;
          height: 0.8rem;
          font-weight: 800;
          color: ${Colors.Color7};
        }
      }
    }
  }
`;
