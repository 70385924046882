import styled from "styled-components";
import Input from "../..";
import { Colors } from "~/config/Colors";

const ThisInput = styled(Input)`
  background-color: ${Colors.bg_color_2};
  color: ${Colors.ft_color_6} !important;

  font-size: 1em;
  line-height: 1;
  width: 20em;
  max-width: 100%;
  height: 3em;
  border: 0;
  border-radius: 2em;
  padding: 0em 1.5em;
  align-self: center;

  &::placeholder {
    color: #fff;
  }

  &:disabled {
    background-color: #256172aa;
  }
`;

export default ThisInput;
