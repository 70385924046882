/**
 * Procurar pelo valor de uma determinada chave em um json.
 * @param {string} key Caso a chave esteja em um nível mais profundo, deve ser escrito usando a notação por pontos. Exemplo: "escola.aluno.nome"
 * @param {Object} object
 */
export default function FindKeyValue(key, object) {
  if (key) {
    function isObject(data) {
      return Object.prototype.toString.call(data) === "[object Object]";
    }

    object = isObject(object) ? object : {};

    let path = key.split(".");

    function findValue([key, ...rest], thisObject) {
      let checkObject = thisObject ? thisObject : {};
      if (rest.length > 0) {
        if (Array.isArray(checkObject[key])) {
          let i = 0;
          let values = [];
          for (const item of checkObject[key]) {
            const exist = findValue(rest, item);
            if (exist) {
              values.push(exist);
            }
            i++;
          }
          return values;
        } else {
          return findValue(rest, checkObject[key]);
        }
      } else {
        return checkObject[key];
      }
    }

    return findValue(path, object);
  }
  return object;
}
