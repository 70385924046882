import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '~/services/api';
import { Label, Input, Select } from '~/componentes/CustomForm';

import ShowConfirmacao from '~/utils/ShowConfirmacao';
import { Container } from './styles';
import CustomScrollBar from '~/componentes/CustomScrollBar';
import { Materialize } from '~/styles/materializeGrid';
import { SLModalFooter } from '~/pages/Sistema/componentes/SistemaList';
import GroupAcoordion from '~/componentes/GroupAcoordion';
import { changePerfis } from '~/store/modules/auth/actions';

function EditContent({ mountedData, unidades }) {
  const dispatch = useDispatch();
  const { auth } = useSelector(state => state);

  const [modulos, setModulos] = useState([]);
  const [gruposPerfis, setGruposPerfis] = useState([]);
  const [tipo, setTipo] = useState(mountedData?.tipo);
  const [ativo, setAtivo] = useState(mountedData?.ativo?.toString());
  const [grupoPerfil, setGrupoPerfil] = useState(mountedData?.grupoPerfil);
  const [acessos, setAcessos] = useState(typeof mountedData?.acessos == "string" ? JSON.parse(mountedData?.acessos) : mountedData?.acessos);

  console.log(typeof mountedData?.acessos == "string" ? JSON.parse(mountedData?.acessos) : mountedData?.acessos);
  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    ShowConfirmacao(
      async () => {
        let response = await api.put(
          '/signed/cliente/administracao/gerenciamento/perfil',
          {
            ...data,
            id: mountedData.id,
            tipo,
            idGrupoPerfil: grupoPerfil?.id,
            ativo,
          },
        );

        if (response?.status === 200) {
          dispatch(changePerfis(auth));
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao atualizar esse Grupo de Usuário, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja atualizar esse Grupo de Usuário?`,
        successModal: `Grupo de Usuário atualizado com sucesso!`,
      },
    );
  }

  useEffect(() => {
    api
      .get('/signed/geral/acessos/check', { params: { tipo: "CLI" } })
      .then(response => setModulos(response.data));
    api
      .get('/signed/cliente/administracao/gerenciamento/gruposperfis', {
        params: { tipo: "CLI", acesso: true },
      })
      .then(response => setGruposPerfis(response.data));
  }, [mountedData]);

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">
            Preencha as informações corretamente, e se atente ao (*) pois são
            campos obrigatórios
          </p>
        </div>
        <div className="row">
          <Label title="Nome *" className="col s8 m8 l8">
            <Input
              name="nome"
              disabled={true}
              defaultValue={mountedData?.usuario?.nome}
            />
          </Label>
          <Label title="Cpf *" className="col s4 m4 l4">
            <Input
              name="cpf"
              disabled={true}
              defaultValue={mountedData?.usuario?.cpf}
            />
          </Label>
        </div>
        <div className="row">
          <Label title="Email *" className="col s12 m12 l6">
            <Input
              name="email"
              disabled={true}
              defaultValue={mountedData?.usuario?.email}
            />
          </Label>
          <Label title="Data Nascimento *" className="col s6 m6 l3">
            <Input
              type="date"
              name="dataNascimento"
              disabled={true}
              defaultValue={mountedData?.usuario?.dataNascimento}
            />
          </Label>
          <Label title="Ativo *" className="col s6 m6 l3">
            <Select
              isNumber
              list={{
                list: [
                  { id: 'true', nome: 'Sim' },
                  { id: 'false', nome: 'Não' },
                ],
                value: 'id',
                label: 'nome',
              }}
              name="ativo"
              onChange={e => {
                const v = e?.currentTarget?.value || 'true';
                setAtivo(v);
              }}
              defaultValue={ativo}
            />
          </Label>
        </div>
        <div className="row">
          {/* <Label title="Tipo *" className="col s4 m4 l4">
            <Select
              isNumber
              list={{
                list: [
                  { tipo: 'ADM', nome: 'Admin' },
                  { tipo: 'COR', nome: 'Coordenador' },
                  { tipo: 'FUN', nome: 'Funcionario' },
                ],
                value: 'tipo',
                label: 'nome',
              }}
              name="tipo"
              defaultValue={mountedData?.tipo}
              onChange={e => {
                const v = e.currentTarget.value;
                setTipo(v);
              }}
            />
          </Label> */}
          <Label title="Grupo *" className="col s4 m4 l4">
            <Select
              isNumber
              list={{ list: gruposPerfis, value: 'id', label: 'nome' }}
              name="idGrupoPerfil"
              defaultValue={mountedData?.idGrupoPerfil}
              onChange={e => {
                const v = e?.currentTarget?.value || 0;
                const perfil = gruposPerfis.find(o => o?.id === parseInt(v));
                setGrupoPerfil(perfil);
                setAcessos(perfil ? typeof perfil?.acessos == "string" ? JSON.parse(perfil?.acessos) : perfil?.acessos : []);
              }}
              required="true"
            />
          </Label>
          <Label title="Unidade *" className="col s4 m4 l4">
            <Select
              isNumber
              defaultValue={mountedData?.idUnidade}
              list={{ list: unidades, value: 'id', label: 'nome' }}
              name="idUnidade"
              firstOption
              required="true"
            />
          </Label>
        </div>
        <div className="row">
          <p className="col s12 m12 l12 text-separador">
            Selecione o Tipo e Marque os Acessos do Perfil abaixo:
          </p>
        </div>
        <div className="row">
          {modulos.length > 0 && (
            <GroupAcoordion
              defaultSelect={acessos}
              title={'Acessos '}
              name={'acessos'}
              itens={modulos}
            />
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default EditContent;
