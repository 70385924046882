import React from "react";

import sistemaListStore, {
  sistemaListShowModal,
} from "../../../ThisMainComponent/thisStore";
import { Form } from "~/componentes/CustomForm";
import { SLModalFooter } from "../../..";
import { ScrollBarCustom } from "./styles";

/**
 * @param {any} data
 * @param {Object} options
 * @param {Function} options.closeModal Fecha o modal
 * @param {Function} options.reloadData Recarrega os dados da lista
 * @param {Function} options.closeReload Recarrega os dados da lista e fecha o modal.
 */
// eslint-disable-next-line no-unused-vars
function onSubmitDoc(data, { closeModal, reloadData, closeReload }) { }

/**
 * @param {Object} props
 * @param {onSubmitDoc} props.onSubmit
 * @param {Boolean} props.isFormData
 * @param {Boolean} props.defaultScroll Adiciona o Scroll.
 * @param {Boolean} props.defaultButtons Adiciona os botões.
 * @param {Boolean} props.defaultStyle Adiciona os botões e o scroll.
 */
function ThisForm({
  children,
  onSubmit,
  defaultScroll,
  defaultButtons,
  defaultStyle,
  ...rest
}) {
  const { state, dispatch } = React.useContext(sistemaListStore);

  function handleSubmit(data) {
    onSubmit &&
      onSubmit(data, {
        closeModal: () => dispatch(sistemaListShowModal(false)),
        reloadData: state.reloadData,
        closeReload: () => {
          state.reloadData();
          dispatch(sistemaListShowModal(false));
        },
      });
  }

  return (
    <Form onSubmit={handleSubmit} {...rest}>
      {defaultStyle || defaultScroll ? (
        <ScrollBarCustom autoHeightMax="100%">
          {children}
        </ScrollBarCustom>
      ) : (
        children
      )}
      {/* {(defaultStyle || defaultButtons) && <SLModalFooter />} */}
      <SLModalFooter />
    </Form>
  );
}

export default ThisForm;
