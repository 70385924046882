import React, { useMemo } from "react";
import { StyleColoredButton } from "./styles";
import { IsObject } from "~/utils/CheckDataType";
import { MaterialIconNames } from "~/config/IconNames";

/**
 * @param {Object} props
 * @param {String} props.link Envolve o botão com o componente Link, para que seja aplicado um redirecionado ao clicar.
 * @param {Boolean} props.noLoading Remove a trava do botão caso algo esteja sendo carregado.
 * @param {Boolean} props.small Utiliza uma versão menor do botão. Utilizado apenas nas versões estilizadas.
 * @param {Object} props.redLayout Aplica o layout vermelho.
 * @param {Object} props.greenLayout Aplica o layout verde.
 * @param {Object} props.blueLayout Aplica o layout azul.
 * @param {MaterialIconNames} props.materialIcon Adiciona um ícone do materialIcon.
 */
function ColoredButton({ children, materialIcon, ...rest }) {
  const icon = useMemo(() => {
    if (materialIcon) {
      if (typeof materialIcon === "string") {
        return materialIcon;
      } else if (IsObject(materialIcon)) {
        const thisKey = Object.keys(materialIcon)[0];

        return MaterialIconNames?.[thisKey] || "";
      }
    }
  }, [materialIcon]);

  return (
    <StyleColoredButton nochildren={!children ? "true" : undefined} {...rest}>
      {icon && (
        <i className="material-icons ColoredButton_materialIcon_propIcon">{icon}</i>
      )}
      {children}
    </StyleColoredButton>
  );
}

export default ColoredButton;
