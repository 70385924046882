import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserDevice from "~/componentes/UserDevice";
import api from "~/services/api";
import { signOut } from "~/store/modules/auth/actions";
import { tipoAcesso } from "~/config/definicoesPadrao";
import acessos from "../../acessos";

function useMenuHandler({ setShowLateralMenu }) {
  const {
    auth: { token, preferencias, usuario, perfil },
  } = useSelector((state) => state) || {};
  const reduxDispatch = useDispatch();

  const { desktop } = UserDevice();
  const [loaded, setLoaded] = useState(false);
  const [modulosAcesso, setModulosAcesso] = useState([]);
  /**
   * @type {[{moduloPath: string, menuPath: string, props: {}}, Function]}
   */
  const [componentPath, setComponentPath] = useState();

  useEffect(() => {
    async function fetchModulos() {
      const response = await api.get(`/signed/geral/acessos`, { params: { tipoPerfil: perfil?.tipo }}).catch(() => undefined);

      if (!response || response?.status !== 200 || !Array.isArray(response?.data) || !(response?.data?.length > 0)) {
        reduxDispatch(signOut());
      } else {
        const moduloPath = localStorage.getItem("modulo");
        const menuPath = localStorage.getItem("menu");
        const subMenuPath = localStorage.getItem("subMenu");

        setComponentPath({ moduloPath, menuPath, subMenuPath });
        setModulosAcesso(response.data);
        setLoaded(true);
      }
    }

    fetchModulos();
  }, [token, preferencias, reduxDispatch]);

  const { RenderedComponent, currentModulo, currentMenu, currentSubMenu } = useMemo(() => {
    if (loaded) {
      const { moduloPath, menuPath, subMenuPath } = componentPath || {};

      let thisModulo = modulosAcesso?.find?.((e) => e?.path === moduloPath);
      let thisMenu = thisModulo?.menus?.find?.((e) => e?.path === menuPath);
      let thisSubMenu = thisMenu?.subMenus?.find?.((e) => e?.path === subMenuPath);

      if (!thisModulo) {
        thisModulo = modulosAcesso?.[0];
        thisMenu = thisModulo?.menus?.[0];
        thisSubMenu = thisMenu?.subMenus?.[0];
      }

      if (!thisMenu) {
        thisMenu = thisModulo?.menus?.[0];
      }

      if (!thisSubMenu) {
        thisSubMenu = thisMenu?.subMenus?.[0];
      }

      let thisComponent = acessos?.[tipoAcesso[perfil?.tipo]]?.[thisModulo?.path]?.[thisMenu?.path];

      if (thisMenu?.possuiSubMenu) {
        thisComponent = thisComponent?.[thisSubMenu?.path];
      }

      return {
        currentModulo: thisModulo,
        currentMenu: thisMenu,
        currentSubMenu: !thisMenu?.possuiSubMenu ? false : thisSubMenu,
        RenderedComponent: thisComponent,
      };
    } else {
      return {
        currentModulo: undefined,
        currentMenu: undefined,
        currentSubMenu: undefined,
        RenderedComponent: undefined,
      };
    }
  }, [componentPath, loaded, perfil, modulosAcesso]);

  useEffect(() => {
    if (loaded) {
      localStorage.setItem("modulo", currentModulo?.path);
      localStorage.setItem("menu", currentMenu?.path);
      localStorage.setItem("subMenu", currentSubMenu?.path);
    }
  }, [currentModulo, currentMenu, currentSubMenu, loaded]);

  useEffect(() => {
    setShowLateralMenu(!!desktop);
  }, [desktop, setShowLateralMenu]);

  return {
    desktop,
    modulosAcesso,
    componentPath,
    setComponentPath,
    RenderedComponent,
    currentModulo,
    currentMenu,
    currentSubMenu,
    preferencias,
    usuario,
    perfil,
  };
}

export default useMenuHandler;
