import React, { useEffect, useState } from "react";
import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import { LandPage, Container, Main } from './styles';
import CustomBodyContent from "./CustomBodyContent";
import { Colors } from "~/config/Colors";
import {
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";
import { useSelector } from "react-redux";

export default function EtapaOracamento() {
  const [data, setData] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [loadedData, setLoadedData] = useState(false);
  const { perfil } = useSelector(state => state.auth);

  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);


  useEffect(() => {
    async function handleRequests() {
      api
        .get('/signed/geral/acessos/check')
        .then(response => setModulos(response.data));
    }

    handleRequests();
  }, []);

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Container
            className="container-ct"
            requests={{
              findAll: () =>
                api.get('/signed/empresa/gestorservico/cliente/clientes', {
                  params: { paranoid: false },
                }),
              findOne: thisData =>
                api.get('/signed/empresa/gestorservico/cliente/cliente', {
                  params: { id: thisData.id },
                }),
              deleteOne: thisData =>
                api.delete('/signed/empresa/gestorservico/cliente/cliente', {
                  data: { id: thisData.id },
                }),
            }}
            setter={setData}
            setLoaded={setLoadedData}
            loaded={loadedData}
            informationPage={{
              title: 'Clientes',
              subTitle: "Adicione os seus Clientes",
              icone: 'submenu-clientes',
            }}
            tabFilter={{
              initial: { ativo: true },
              tabAtivo: 1,
              list: [
                {
                  corDestaque: localStorage.getItem("tema") == "E" ? '#9A3636' : '#e68282',
                  corFundo: Colors?.Color8,
                  title: 'Desativado',
                  value: { ativo: false },
                },
                {
                  corDestaque: Colors?.Color4,
                  corFundo: Colors?.Color8,
                  title: 'Ativo',
                  value: { ativo: true },
                },
              ],
            }}
            inputs={[
              { filter: 'titulo', label: 'Titulo' },
              { filter: 'id', label: 'Id' },
            ]}
          >
            <SLEditModal title="Atualizar Cliente" setter={setMountedData}>
              {mountedData && modulos && (
                <CreateEditContent {...{ modulos, mountedData }} />
              )}
            </SLEditModal>

            <SLCreateModal title="Adicionar Cliente">
              {modulos && <CreateEditContent {...{ modulos }} />}
            </SLCreateModal>

            <CustomBodyContent {...{ data }} />
          </Container>
        </div>
      </LandPage>
    </Main>
  );
}
