import React from "react";
import FindKeyValue from "~/utils/FindKeyValue";

import { Container } from "./styles";
import IconButton from "../Button/Styles/IconButton";
import { Input } from "~/componentes/CustomForm";

function SingleValueList({ data, dataKey, materialIcon, iconClick, onClick, ...rest }) {
  return (
    <Container {...rest}>
      {data?.map((e, i) => (
        <div key={e?.cod || i} className="SingleValueList_div" {...{ onClick }}>
          {materialIcon && (
            <IconButton
              className="SingleValueList_div_IconButton"
              materialIcon={{ close: true }}
              onClick={() => iconClick && iconClick(e)}
            />
          )}
          <span>{FindKeyValue(dataKey, e)}</span>
          {
              // <Input className="ordem" name="ordem" required="true" defaultValue={data?.ordem} onChange={(e) => {
              //   const v = e.currentTarget.value || "";
              // }} />
          }
        </div>
      ))}
    </Container>
  );
}

export default SingleValueList;
