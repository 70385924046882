import React, { useEffect, useState } from "react";
import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import { LandPage, Container, Main } from './styles';
import CustomBodyContent from "./CustomBodyContent";
import { Colors } from "~/config/Colors";
import {
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";
import { useSelector } from "react-redux";

export default function ConfigJobEmail() {
  const [data, setData] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [loadedData, setLoadedData] = useState(false);
  const { perfil } = useSelector(state => state.auth);

  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);

  useEffect(() => {
    async function handleRequests() {
      api
        .get('/signed/geral/acessos/check')
        .then(response => setModulos(response.data));
    }

    handleRequests();
  }, []);

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Container
            requests={{
              findAll: () =>
                api.get('/signed/cliente/pesquisa/email/configjobemails'),
              findOne: thisData =>
                api.get('/signed/cliente/pesquisa/email/configjobemail', {
                  params: { id: thisData.id },
                }),
              deleteOne: thisData =>
                api.delete('/signed/cliente/pesquisa/email/configjobemail', {
                  data: { id: thisData.id },
                }),
            }}
            setter={setData}
            setLoaded={setLoadedData}
            loaded={loadedData}
            informationPage={{
              title: 'Configuração do JOB de Email',
              subTitle: "Crie configurações de envio de email que será executado em um JOB",
              icone: 'submenu-pesquisa',
            }}
            pageSize={8}
            inputs={[
              { filter: 'titulo', label: 'Titulo' },
              { filter: 'id', label: 'Id' },
            ]}
          >
            <SLEditModal title="Atualizar Configuração" setter={setMountedData}>
              {mountedData && modulos && (
                <CreateEditContent {...{ modulos, mountedData }} />
              )}
            </SLEditModal>

            <SLCreateModal title="Adicionar Configuração">
              {modulos && <CreateEditContent {...{ modulos }} />}
            </SLCreateModal>

            <CustomBodyContent {...{ data }} />
          </Container>
        </div>
      </LandPage>
    </Main>
  );
}
