import React, { useState, useEffect } from "react";

import { Container, FlexDiv } from "./styles";
import Label from "~/componentes/CustomForm/Label";
import { Input } from "~/componentes/CustomForm";
import api from "~/services/api";
import setRegex from "~/utils/setRegex";

/**
 * @param {Object} props
 * @param {Function} props.setter Recebe o dado referente ao usuário.
 */
function FindOrCreateUser({ setter, representante }) {
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState();

  async function handleSearch() {
    await api
      .get("/signed/geral/usuario", { params: { email } })
      .then((r) => {
        if (r?.data?.id) {
          setUserData(r.data);
          setDisabled(true);
        }
      })
      .catch((err) => {
        setUserData();
        setDisabled(false);
      });
  }

  useEffect(() => {
    if (setter) {
      setter({ ...userData, email });
    }
  }, [userData, setter, email]);

  return (
    <Container>
      <div className="row">
        <Label className="col s12 m12 l12" title={representante ? "Email Representante: *" : "Email: *"}>
          <FlexDiv>
            <Input
              as="input"
              type="email"
              required
              value={email}
              onChange={(e) => {
                const v = e.currentTarget.value;
                setUserData();
                setDisabled(true);
                setEmail(v);
              }}
              onKeyPress={(e) => {
                const thisKey = e.key;
                if (thisKey === "Enter") {
                  e.preventDefault();
                  handleSearch();
                }
              }}
            />
            <button type="button" onClick={handleSearch} className="btn-search">
              <span className="material-icons">search</span>
            </button>
          </FlexDiv>
        </Label>
        <Label title={representante ? "Nome Representante: *" : "Nome: *"} className="col s12 m12 l6">
          <Input
            required
            disabled={disabled}
            value={userData?.nome || ""}
            onChange={(e) => {
              const v = e.currentTarget.value;
              setUserData((prev) => ({ ...prev, nome: v }));
            }}
          />
        </Label>

        <div className="wrapper-input">
          <Label title={representante ? "Cpf Rep.: *" : "Cpf: *"} className="col s12 m12 l3">
            <Input
              required
              disabled={disabled}
              value={userData?.cpf ? setRegex.setCpf(userData.cpf) : ""}
              onChange={(e) => {
                const v = e.currentTarget.value;
                setUserData((prev) => ({ ...prev, cpf: setRegex.setCpf(v) }));
              }}
            />
          </Label>
        </div>
        <div className="wrapper-input">
          <Label title={representante ? "Data de Nascimento Rep.: *" : "Data de Nascimento: *"} className="col s12 m12 l3">
            <Input
              required
              disabled={disabled}
              type="date"
              value={userData?.dataNascimento || ""}
              onChange={(e) => {
                const v = e.currentTarget.value;
                setUserData((prev) => ({ ...prev, dataNascimento: v }));
              }}
            />
          </Label>
        </div>
      </div>
    </Container>
  );
}

export default FindOrCreateUser;
