import styled from "styled-components";

export const Container = styled.div.attrs(({ setType }) => ({ as: setType || "div" }))`
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  -webkit-line-clamp: ${(props) => props?.lines || 1};

  line-height: ${(props) => props.lineHeight || 1.2};

  ${(props) => {
    if (!props.relativeHeight) {
      const lineHeight = props?.lineHeight || 1.2;
      const lines = props?.lines || 1;
      let thisHeight = `${lineHeight * lines}em`;
      return `height: ${thisHeight};`;
    }
  }}
`;

// function checkHeight(props) {
//   const lineHeight = props?.lineHeight || 1.2;
//   if (props?.lines) {
//     return `${lineHeight * props.lines}em`;
//   }
//   return `${lineHeight * 3}em`;
// }

// export const Container = styled.div`
//   font-size: 1em;
//   color: #999;

//   /* hide text if it more than N lines  */
//   overflow: hidden;
//   /* for set '...' in absolute position */
//   position: relative;
//   /* use this value to count block height */
//   line-height: ${(props) => props.lineHeight || 1.2};
//   /* max-height = line-height (1.2) * lines max number (3) */
//   ${(props) => !props.relativeHeight && `height: ${checkHeight(props)};`}
//   max-height: ${(props) => checkHeight(props)};
//   /* fix problem when last visible word doesn't adjoin right side  */
//   text-align: justify;
//   /* place for '...' */
//   /* margin-right: -1em; */
//   padding-right: 1em;

//   /* create the ... */
// :before {
//   /* points in the end */
//   content: "...";
//   /* absolute position */
//   position: absolute;
//   /* set position to right bottom corner of block */
//   right: 0.25em;
//   bottom: 0em;
// }
// /* hide ... if we have text, which is less than or equal to max lines */
// :after {
//   /* points in the end */
//   content: "";
//   /* absolute position */
//   position: absolute;
//   /* set position to right bottom corner of text */
//   right: 0;
//   /* set width and height */
//   width: 1em;
//   height: 1em;
//   margin-top: 0.2em;
//   /* bg color = bg color under block */
//   background: white;
// }
// `;
