import { store } from "~/store";
import { confirmacaoModalToggle } from "~/store/modules/confirmacaoModal/actions";

/**
 * @param {Function} request Aceita uma requisição que será executada ao confirmar o modal.
 * @param {Object} options Configura o comportamento padrão do modal.
 * @param {string} options.message Texto que irá sobrepor o padrão no corpo do modal.
 * @param {any} options.successModal Padrão true. Se false não mostrará modal de sucesso, se String, informará um texto para ser mostrado.
 * @param {Object} options.errorModal Padrão true. Recebe um boolean, string ou objecto de configuração. Se string, será mostrado a mensagem
 *  informada, se for true, será usado o erro para buscar por um padrão de erro do Youch, retornando a mensagem
 *  contida no primeiro erro que tenha como true a chave errorHandled.
 * @param {string} options.errorModal.defaultMessage Caso seja seja informado, o errorModal buscará pelo erro tratado, caso não haja, será retornado essa mensagem no lugar da padrão.
 */
export default function ShowConfirmacao(request, options) {
  const dispatch = store.dispatch;

  return dispatch(confirmacaoModalToggle({ ...options, request }));
}
