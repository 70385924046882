import styled from "styled-components";
import { Colors } from "~/config/Colors";

const CustomSelect = styled.select`
  font-size: 0.73em;
  line-height: 1;
  height: 2.5em;
  //box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
  border: 0;
  // border: 0.1em solid ${Colors.Color1};
  border-radius: 0.5em;
  width: fit-content;
  max-width: 100%;
  padding: 0em 0.5em;

  ${({ notForm }) => {
    if (notForm == true) {
      return `
        background-color: ${Colors.Color8};
        color: ${Colors.Color7} !important;
      `;
    } else {
      return `
        background-color: ${Colors.Color8};
        color: ${Colors.Color7} !important;
      `;
    }
  }}

  option {
    background-color: ${Colors.Color2};
    color: ${Colors.Color7} !important;
    font-size: 0.8rem;
  }

  option:disabled {
    background-color: ${Colors.Color9};
    color: ${Colors.Color7} !important;
  }

  :disabled {
    background-color: ${Colors.Color37};
    border: 0.1em solid ${Colors.Color37};
    opacity: 1;
  }

  :focus,
  :hover {
    // border: 0.1em solid ${Colors.Color4};
  }
`;

export default CustomSelect;
