import styled from "styled-components";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";
import { Colors } from "~/config/Colors";

export const Container = styled(SLForm)`
  width: 100%;
  max-width: 100%;

  .padding-form {
    padding: 0 1em 1em 1em;
  }

  .row {
    margin-bottom: 0.5em;
  }

  .header {
    padding: 0 0.6em;
    margin-bottom: 1em;

    .h-descricao {
      font-size: 0.7125em;
      line-height: 0.8em;
      color: ${Colors.Color7};
    }
  }

  
  .card-atv-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0.5rem;

    .top-header{
      display: flex;
      justify-content: space-between;

      .titulo {
        display: flex;
        flex-direction: row;
        align-items: center;

        .fundo-icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 1.40375rem;
          height: 1.40375rem;
          background: ${Colors.Color8};
          border-radius: 0.4375rem;

          .atividade {
            width: 0.95rem;
          }
        }

        .item {
          font-size: 0.75rem;
          color: ${Colors.Color4};
          font-weight: 700;
          margin-left: 0.5rem;
        }
      }
    }

    .icon-button {
      border: none;
      cursor: pointer;
      background: ${Colors.Color1};
      color: ${Colors.Color7};
      font-size: 1.125rem;
    }
  }

  .card-atv-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .top-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      height: 2rem;
    }

    .padding-left {
      padding: 0;
      padding-left: 0.7em !important;
    }

    .row-top {
      margin-top: 0em !important;
      display: flex;
      flex-direction: row;
    }

    .geraProjeto {
    
      input, TextArea {
        width: 100%;
        margin-top: 0em !important;
      }
    }
  }

  
  .titulos-tab{
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5em;
    background: ${Colors.Color8};
    color: ${Colors.Color7};
    border-radius: 0.5rem;

    span {
      color: ${Colors.Color7};
    }

    .titulo {
      font-size: 0.75rem;
      font-weight: 700;
      margin-left: 0.5rem;
    }

    .col {
      height: 2.5rem;
      padding: 0.5em 0em 0.5em 0.5rem;
      display: flex;
      align-items: center;
    }

    .col1 {
      width: 5rem;
    }
    
    .col2 {
      width: 10rem;
    }
    
    .col3 {
      width: 15rem;
    }
    
    .col4 {
      width: 20rem;
    }
    
    .col5 {
      width: 25rem;
    }
    
    .col6 {
      width: 30rem;
    }
  }

`;