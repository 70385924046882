import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { Label, Input, Select } from "~/componentes/CustomForm";

import { Container } from "./styles";
import ItensList from "../ItensList";
import ItensSatisfacaoList from "../ItensSatisfacaoList";
import { Materialize } from "~/styles/materializeGrid";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function CreateEditContent({ mountedData }) {
  const [itens, setItens] = useState(mountedData?.itens || [
    { id: 1, titulo: "Muito Satisfeito", estrela: 1, ordem: 1 },
    { id: 2, titulo: "Satisfeito", estrela: 2, ordem: 2 },
    { id: 3, titulo: "Normal", estrela: 3, ordem: 3 },
    { id: 4, titulo: "Insatisfeito", estrela: 4, ordem: 4 },
    { id: 5, titulo: "Muito Insatisfeito", estrela: 5, ordem: 5 },
  ]);
  const [tipo, setTipo] = useState(!mountedData?.tipo ? "SF" : mountedData?.tipo);
  const [pesquisa, setPesquisas] = useState([]);

  useEffect(() => {
    api
      .get('/signed/cliente/pesquisa/gerenciamento/pesquisas')
      .then(response => setPesquisas(response.data));
  }, []);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let thisData = {
          ...data,
          itens,
          tipo,
          ...(mountedData?.id ? { id: mountedData?.id } : {}),
        }
        let response;
        if (mountedData?.id)
          response = await api.put("/signed/cliente/pesquisa/gerenciamento/pergunta", thisData);
        else
          response = await api.post("/signed/cliente/pesquisa/gerenciamento/pergunta", thisData);

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${mountedData?.id ? "atualizar" : "cadastrar"} essa Pergunta, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${mountedData?.id ? "atualizar" : "cadastrar"} essa Pergunta?`,
        successModal: `Pergunta ${mountedData?.id ? "atualizada" : "cadastrada"} com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">Preencha as informações corretamente, e se atente ao (*) pois são campos obrigatórios</p>
        </div>
        <div className="row no-margin">
          <div className="col s12 m12 l12 no-margin">
            <div className="row">
              <Label title="Pesquisa *" className="col s12 m3 l3">
                <Select
                  isString
                  list={{ list: pesquisa, value: "id", label: "titulo" }}
                  name="idPesquisa"
                  firstOption
                  disabled={mountedData ? true : false}
                  required={true}
                  defaultValue={mountedData?.idPesquisa}
                />
              </Label>
              <Label title="Título *" className="col s12 m5 l5">
                <Input name="titulo" required="true" defaultValue={mountedData?.titulo} disabled={mountedData ? true : false} />
              </Label>
              <Label title="Tipo *" className="col s12 m2 l2">
                <Select
                  isString
                  list={{ list: [{ id: "SF", titulo: "Satisfação" }, { id: "TX", titulo: "Texto" }, { id: "CF", titulo: "Deixar Comentário" }, { id: "MP", titulo: "Multiplos Itens" }], value: "id", label: "titulo" }}
                  name="tipo"
                  defaultValue={tipo}
                  disabled={mountedData ? true : false}
                  onChange={(e) => {
                    const v = e.currentTarget.value || "SF";
                    setTipo(v);

                    if (v == "SF") {
                      setItens([
                        { id: 1, titulo: "Muito Satisfeito", estrela: 1, },
                        { id: 2, titulo: "Satisfeito", estrela: 2 },
                        { id: 3, titulo: "Normal", estrela: 3 },
                        { id: 4, titulo: "Insatisfeito", estrela: 4 },
                        { id: 5, titulo: "Muito Insatisfeito", estrela: 5 },
                      ]);
                    } else {
                      setItens([]);
                    }
                  }}
                />
              </Label>
              <Label title="Ordem *" className="col s12 m2 l2">
                <Input name="ordem" required="true" defaultValue={mountedData?.ordem} />
              </Label>
            </div>
            <div className="row">
              <div className="col s12 m12 l6">
                {tipo == "SF" && (
                  <ItensSatisfacaoList setter={setItens} data={itens} defaultData={itens || []} disabled={mountedData ? true : false} />
                )}
                {tipo == "MP" && (
                  <ItensList setter={setItens} data={itens} defaultData={itens || []} disabled={mountedData ? true : false} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateEditContent;
