import styled from "styled-components";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";
import { Colors } from "~/config/Colors";

export const Container = styled(SLForm)`
  width: 100%;
  max-width: 100%;

  .padding-form {
    padding: 0 1em 1em 1em;
  }

  .row {
    margin-bottom: 0.5em;
  }

  .header {
    padding: 0 0.6em;
    margin-bottom: 1em;

    .h-descricao {
      font-size: 0.7125em;
      line-height: 0.8em;
      color: ${Colors.Color7};
    }
  }
`;