import React, { useState } from "react";
import { LandPage, Main } from './styles';
import EditContent from './EditContent';
import {
  SLCreateModal,
} from '~/pages/Sistema/componentes/SistemaList';

export default function Perfil() {
  const [mountedData, setMountedData] = useState();

  return (
    <Main>
      <LandPage>
        <div className="content">
          <SLCreateModal
            menu={true}
            defaultAtivo={true}
            title="Atualizar Senha"
            setter={setMountedData}
          >
            <EditContent />
          </SLCreateModal>
        </div>
      </LandPage>
    </Main>
  );
}
