import styled from 'styled-components';
import SistemaList from '~/pages/Sistema/componentes/SistemaList';
import { Scrollbars } from 'react-custom-scrollbars';

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const LandPage = styled(Scrollbars)`
  width: calc(100%) !important;

  .content {
    padding: 0em 2em 2em 2em;
  }
`;

export const Container = styled(SistemaList)``;

