import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent() {
  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell className="col-w-1" name="id" inputfilter>
            Id
          </SLTableCell>
          <SLTableCell className="col-w-2" name="codigoDevice" inputfilter>
            Device
          </SLTableCell>
          <SLTableCell className="col-w-1" name="valor" inputfilter>
            Valor
          </SLTableCell>
          <SLTableCell className="col-w-2" name="data" inputfilter>
            Data
          </SLTableCell>
          <SLTableCell className="col-w-1" name="hora" inputfilter>
            Hora
          </SLTableCell>
          <SLTableCell className="col-w-3" name="dispositivo.centroCusto.titulo" inputfilter>
            Centro de Custo
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
