import React from "react";
import { ThisIconButonStyle } from "../styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { useContext } from "react";
import sistemaListStore, { sistemaListShowModal } from "../../../ThisMainComponent/thisStore";

function ThisDeleteButton({ icon = "delete_outline", data, onClick, ...rest }) {
  const { state, dispatch } = useContext(sistemaListStore);
  const { requests, reloadData } = state || {};
  const { deleteOne } = requests || {};

  async function handleClick() {
    await ShowConfirmacao(async () => {
      const response = await deleteOne(data);

      if (response?.status === 200) {
        reloadData && reloadData();
        dispatch(sistemaListShowModal({ edit: false }, data));
      }

      return response;
    });

    onClick && onClick();
  }

  return (
    <ThisIconButonStyle
      moldalType="destroy"
      className={`material-icons`}
      onClick={handleClick}
      {...rest}
    >
      {icon}
    </ThisIconButonStyle>
  );
}

export default ThisDeleteButton;
