import React, { useState, useEffect, useMemo } from "react";

import Select from "~/componentes/CustomForm/Select";
import Input from "~/componentes/CustomForm/Input";

import { Container } from "./styles";
/**
 * @param {Object} props
 * @param {Object} props.reloadable Por padrão é false, as configurações de input carregam uma única vez, logo após perceber que o inputProps é um array com length > 0. Caso seja essa propriedade seja true, irá recarregar sempre que a referência  do input alterar, isso pode cuasar rerenderizações desnecessárias caso seja passado um array fora de um state.
 * @param {Object} props.inputProps demais props passadas para todos os inputs.
 * @param {Object[]} props.inputs
 * @param {string} props.inputs.filter Obrigatório, informa a chave de busca do filtro.
 * @param {string} props.inputs.label Obrigatório, informe o nome que deve aparecer no label ou no select.
 */
function FilterInputList({
  inputs,
  reloadable,
  useLabel,
  inputProps,
  ...rest
}) {
  const [selectInput, setSelectInput] = useState();
  const [inputValue, setInputValue] = useState("");
  const [loaded, setLoaded] = useState(false);

  const checkReload = useMemo(
    () => reloadable || !loaded,
    [loaded, reloadable]
  );

  useEffect(() => {
    if (
      checkReload &&
      Array.isArray(inputs) &&
      inputs.length > 0 &&
      inputs[0].filter
    ) {
      setLoaded(true);
      setSelectInput(inputs[0].filter);
    }
  }, [checkReload, inputs]);

  return (
    <Container className="CustomTable_Filter_Search" {...rest}>
      {selectInput && (
        <>
          <Select
            value={selectInput}
            notForm={true}
            onChange={(e) => {
              const thisValue = e.currentTarget.value;
              if (thisValue) {
                setSelectInput(thisValue);
              }
              setInputValue("");
            }}
            list={{ list: inputs, value: "filter", label: "label" }}
          />
          <Input
            type="text"
            value={inputValue}
            notForm={true}
            onChange={(e) => {
              const thisValue = e.currentTarget.value || "";
              setInputValue(thisValue);
            }}
            filter={selectInput}
            {...inputProps}
            placeholder="Pesquisar pela coluna"
          />
        </>
      )}
    </Container>
  );
}

export default FilterInputList;
