import React from "react";

import { Container } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { succesToggle } from "~/store/modules/successModal/actions";
import { modalTimeout } from "~/config/definicoesPadrao";

export default function SuccessModal(props) {
  const [closeAnimation, setCloseAnimation] = React.useState(() => () => { });
  const { ativo, message } = useSelector((state) => state.successModal);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => ativo && closeAnimation(), modalTimeout);
  }, [ativo, closeAnimation]);

  return (
    <Container
      fundoSombra={true}
      checkRedux={false}
      outClickAnimation={setCloseAnimation}
      outClick={() => dispatch(succesToggle())}
      {...props}
    >
      <div className="sucessHeader">
        <div className="title-select">
          <p>Alerta de Sucesso</p>
        </div>
      </div>
      <div className="sucessBody">
        <span className="select"></span>
        <div className="title">
          <i className="material-icons">check_circle_outline</i>
          <p>Concluído com sucesso</p>
        </div>
        <p className="message">{message || "Operação realizada com sucesso!"}</p>
      </div>
    </Container>
  );
}
