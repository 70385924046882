export default function formDeleteNamePath(name, object, options) {
  const { arrayValue, keepEmptyArray } = options || {};
  if (name && object) {
    let path = name.split(".");

    function createPath([key, ...rest], thisObject) {
      let deleteThis = false;

      if (rest.length > 0) {
        deleteThis = createPath(rest, thisObject?.[key], thisObject, key);
      } else {
        deleteThis = true;
      }

      if (deleteThis) {
        if (arrayValue && thisObject?.[key] && Array.isArray(thisObject[key])) {
          const thisIndex = thisObject[key].indexOf(arrayValue);

          if (thisIndex >= 0) {
            thisObject[key].splice(thisIndex, 1);
          }

          if (keepEmptyArray) {
            return false;
          }
        } else {
          delete thisObject?.[key];
        }
      }

      const checkKeys = thisObject && Object.keys(thisObject);

      if (!checkKeys || checkKeys?.length < 1) {
        return true;
      }
    }

    createPath(path, object);
  }
}
