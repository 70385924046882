import React, { useContext } from "react";
import { ThisIconButonStyle } from "../styles";
import sistemaListStore, { sistemaListEditShowModal } from "../../../ThisMainComponent/thisStore";

function ThisEditButton({ icon = "edit", ...rest }) {
  const { state, dispatch } = useContext(sistemaListStore);
  const { editShow } = state?.modal || {};

  async function handleClick() {
    dispatch(sistemaListEditShowModal({ edit: true, editShow: editShow ? !editShow : true }));
  }

  return (
    <ThisIconButonStyle
      moldalType="edit"
      className={`material-icons`}
      onClick={handleClick}
      {...rest}
    >
      {editShow ? icon : "edit_off"}
    </ThisIconButonStyle>
  );
}

export default ThisEditButton;
