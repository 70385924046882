import React from "react";

const FormStore = React.createContext({
  state: {},
  dispatch: () => { },
});

export function formHandleData(value, name, removeValue) {
  return { type: "formHandleData", payload: { value, name, removeValue } };
}

export function formClearData() {
  return { type: "formClearData" };
}

export default FormStore;
