import styled from 'styled-components';
import SistemaList from '~/pages/Sistema/componentes/SistemaList';
import { Scrollbars } from 'react-custom-scrollbars';

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const LandPage = styled(Scrollbars)`
  width: calc(100%) !important;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding: 0em 2em 1em 2em;
  }
`;

export const Container = styled(SistemaList)`
  height: 100%;
  overflow: auto;

  .sec-tabela {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
`;