import React from "react";
import { ButtonMenu, Modulo, ModuloScroll } from "./styles";
import ModuloButton from "./ModuloButton";

import IconeMenuC from "~/assets/icones/IconMenuC.svg";
import IconeMenuE from "~/assets/icones/IconMenuE.svg";

export default function ModuloLista({
  desktop,
  modulos,
  setComponentPath,
  currentModulo,
  setShowMaxMenu,
  showMaxMenu
}) {

  return (
    <Modulo {...{ desktop }}>
      <ButtonMenu>
        <img onClick={() => setShowMaxMenu(!showMaxMenu)} src={localStorage.getItem("tema") == "E" ? IconeMenuE : IconeMenuC} alt="Inicial" />
      </ButtonMenu>
      {modulos &&
        modulos.map(
          (modulo, mi) =>
            <div className="modulo-button" key={modulo.id || mi}>
              <ModuloButton
                {...{
                  desktop,
                  modulo,
                  selected: currentModulo?.id === modulo?.id,
                  setComponentPath,
                  currentModulo,
                  setShowMaxMenu,
                }}
              />
            </div>
        )}
    </Modulo>
  );
}
