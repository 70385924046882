import styled from "styled-components";
import Modal from "~/componentes/Modal";
import { Colors } from "~/config/Colors";

export const Container = styled.div`
  position: absolute;
  top: 0em;
  padding: 0 0em 0 0em;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: opacityAnimationIn 0.3s forwards;
  z-index: 3;

  
  ${(props) => props.close && `animation: opacityAnimationOut 0.4s forwards;`}
  ${(props) => props.fundoSombra && `background: #666a7042;`}

  form {
    height: calc(100% - 4.5em);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  @keyframes opacityAnimationIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes opacityAnimationOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const ModalContainer = styled(Modal)`
  position: relative;
  width: 100%;
  height: 100%;

  // border: 0.2em solid ${Colors.bgColor1};
  background: ${Colors.Color2};
  border-radius: 0.9em;

  animation: modalIn 0.3s forwards;

  ${(props) => props.close && `animation: modalOut 0.4s forwards;`}

  @keyframes modalIn {
    from {
      margin-top: -10%;
      transform: scaleX(0.7);
    }
    to {
      transform: scaleX(1);
      margin-top: 0%;
    }
  }
  @keyframes modalOut {
    from {
      margin-top: 0%;
      transform: scaleX(1);
    }
    to {
      transform: scaleX(0.7);
      margin-top: -10%;
    }
  }
`;
