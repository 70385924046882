import React from "react";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import formCheckDisabled from "../utils/formCheckDisabled";

/**
 * @param {Object} props
 * @param {String} props.link Envolve o botão com o componente Link, para que seja aplicado um redirecionado ao clicar.
 * @param {Boolean} props.noLoading Remove a trava do botão caso algo esteja sendo carregado.
 * @param {Boolean} props.clean Remove o css padrão de um button.
 */
const Button = React.forwardRef((props, ref) => {
  const {
    noLoading,
    children,
    disabled,
    link,
    small,
    clean,
    blueLayout,
    style,
    ...rest
  } = props || {};

  // const disableButtons = useSelector((state) => state.disableButtons);

  // const checkDisabled = React.useMemo(() => formCheckDisabled(props, disableButtons), [
  //   disableButtons,
  //   props,
  // ]);

  const renderElement = (
    <button
      {...(link ? {} : { ref: ref })}
      // disabled={checkDisabled}
      style={{
        // ...(checkDisabled && { cursor: "not-allowed" }),
        ...(clean && {
          background: "none",
          color: "inherit",
          border: "none",
          font: "inherit",
          cursor: "pointer",
          outline: "inherit",
        }),
        ...style,
      }}
      {...rest}
    >
      {children}
    </button>
  );

  return link ? (
    <Link ref={ref} to={link}>
      {renderElement}
    </Link>
  ) : (
    renderElement
  );
});

export default React.memo(Button);
