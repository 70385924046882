import React from "react";
import { Container } from "./styles";
import SubMenuButton from "./SubMenuButton";

export default function SubMenuLista({
  menu,
  subMenus,
  setComponentPath,
  currentModulo,
  currentSubMenu,
  selected,
}) {

  return (
    <Container>
      {subMenus &&
        subMenus.map(
          (subMenu, mi) =>
            <div className="subMenu-button" key={subMenu.id || mi}>
              <SubMenuButton
                {...{
                  menu,
                  subMenu,
                  selected: currentSubMenu?.id === subMenu?.id,
                  currentModulo,
                  setComponentPath,
                }}
              />
            </div>
        )
      }
    </Container>
  );
}
