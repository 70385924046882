import React, { useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "./config/ReactotronConfig";

import Routes from "./routes";
import history from "./services/history";
import GlobalStyle from "./styles/global";
import GlobalModals from "./componentes/GlobalModals";
import WebFont from "webfontloader";
import { store, persistor } from "./store";

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Nunito:300,400,500,600,700', 'cursive',],
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalModals />
          <GlobalStyle />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
