import React from "react";

import { ItemModulo, ItemButtonModulo } from "./styles";

function ModuloButton({
  desktop,
  modulo,
  selected,
  setComponentPath,
  currentModulo,
  setShowMaxMenu,
}) {

  return (
    <ItemModulo
      desktop={desktop}
      selected={selected}
    >
      <ItemButtonModulo
        desktop={desktop}
        selected={selected}
        onClick={() => {
          let thisModulo = modulo;
          let thisMenu = thisModulo?.menus?.[0];

          if (!thisMenu.possuiSubMenu) {
            setComponentPath({ moduloPath: thisModulo?.path, menuPath: thisMenu?.path, subMenuPath: false });
          } else {
            let thisSubMenu = thisMenu?.subMenus?.[0];
            setComponentPath({ moduloPath: thisModulo?.path, menuPath: thisMenu?.path, subMenuPath: thisSubMenu?.path });
          }
          setShowMaxMenu(true);
        }}
      >
        <img src={require('~/assets/modulos/' + (currentModulo?.id === modulo?.id ? "ativo/" : "desativo/") + (localStorage.getItem("tema") == "E" ? "dark-" + modulo.icone : modulo.icone) + '.svg')} alt={modulo.nome} />
      </ItemButtonModulo>
    </ItemModulo>
  );
}

export default ModuloButton;
