import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const TextAreaCustom = styled.textarea`
  font-size: 0.8em;
  line-height: 1em;
  border-radius: 0.625em;
  border: none;
  background-color: ${Colors.Color8};
  color: ${Colors.Color7} !important;

  align-self: center;
  padding: 0.9375em 0.875em;
  width: 10em;
  height: 7em;
  max-width: 100%;
  // box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
  border: 0;
  // border: 0.1em solid ${Colors.bgColor1};
  border-radius: 0.625em;

  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    :hover {
      width: 0.75em;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0.1em solid slategrey;
    border-radius: 0.5em;
  }

  &:read-only {
    background-color: ${Colors.Color37};
    border: 0.1em solid ${Colors.Color37};
  }

  :disabled {
    background-color: ${Colors.Color37};
    border: 0.1em solid ${Colors.Color37};
  }

  :focus,
  :hover {
    // background-color: ${Colors.bgColor3};
    // border: 0.1em solid ${Colors.bgColor3};
  }
`;
