import formCreateNamePath from "../../utils/formCreateNamePath";
import formDeleteNamePath from "../../utils/formDeleteNamePath";
import { IsObject } from "~/utils/CheckDataType";
import PassByValue from "~/utils/PassByValue";

export default function CheckWrapperReducer(state, { type, payload }) {
  switch (type) {
    case "CheckWrapperReloadDependency": {
      return { ...state, reloadDependency: payload.reloadDependency };
    }
    case "CheckWrapperDefaultData": {
      return { ...state, data: payload.data };
    }
    case "CheckWrapperDisabledList": {
      return { ...state, disabledData: payload.disabledData };
    }
    case "CheckWrapperSelectedDisabledList": {
      const { defaultDisabledData } = payload || {};
      const { data } = state || {};

      let newData = PassByValue(defaultDisabledData);

      if (data) {
        function recursiveAddValue(mainData, secondData) {
          if (IsObject(mainData) && IsObject(secondData)) {
            const mainKeys = Object.keys(mainData);
            const secondKeys = Object.keys(secondData);

            secondKeys.forEach((e) => {
              if (mainKeys.includes(e)) {
                mainData[e] = recursiveAddValue(mainData[e], secondData[e]);
              } else {
                mainData[e] = secondData[e];
              }
            });
          } else if (Array.isArray(mainData) && Array.isArray(secondData)) {
            mainData = [...new Set([...mainData, ...secondData])];
          }
          return mainData;
        }

        let oldData = PassByValue(data);
        newData = recursiveAddValue(newData, oldData);
      }

      return { ...state, data: newData };
    }
    case "CheckWrapperHandleData": {
      const { props, selected } = payload;
      const { value, name, isArrayProp } = props || {};

      let newState = state.data;

      if (state.isArray) {
        if (!Array.isArray(newState)) newState = [];

        if (Array.isArray(value)) {
          value.forEach((e) => {
            const thisIndex = newState.indexOf(e);

            if (selected && !(thisIndex >= 0)) {
              newState.push(e);
            } else if (!selected && thisIndex >= 0) {
              newState.splice(thisIndex, 1);
            }
          });
        } else {
          const thisIndex = newState.indexOf(value);

          if (selected && !(thisIndex >= 0)) {
            newState.push(value);
          } else if (!selected && thisIndex >= 0) {
            newState.splice(thisIndex, 1);
          } else {
            return state;
          }
        }
        newState = [...newState];
      } else if (name) {
        if (!selected) {
          const thisOptions = isArrayProp
            ? {
                arrayValue: value,
                keepEmptyArray: true,
              }
            : {};
          formDeleteNamePath(name, newState, thisOptions);
          newState = { ...newState };
        } else {
          newState = formCreateNamePath(name, state.data, value, isArrayProp);
        }
      } else {
        if (selected) {
          newState = value;
        } else if (newState === value) {
          newState = undefined;
        } else {
          return state;
        }
      }

      return { ...state, data: newState };
    }
    default:
      return state;
  }
}
