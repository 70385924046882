import React, { useState } from "react";
import FilterList from "~/componentes/FilterList";
import Select from "~/componentes/CustomForm/Select";
import Input from "~/componentes/CustomForm/Input";
import { Colors } from "~/config/Colors";

import Pagination from "~/componentes/Pagination";
// import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

import { InformationPage } from "./styles";
import ColoredButton from "~/componentes/Button/Styles/ColoredButton";
import FilterInputList from "../../../FilterInputList";
import sistemaListStore, {
  sistemaListConfirmSelectValues,
} from "../thisStore";
import ThisFilterModal from "../ThisModals/ThisFilterModal";
import ThisModalButtonHandler from "../ThisModalButtonHandler";
import IconConfirmFiltro from "~/assets/icones/IconConfirmFiltro.svg";
import IconConfirmFiltroB from "~/assets/icones/IconConfirmFiltroB.svg";
import IconListAtiva from "~/assets/icones/IconListAtiva.svg";
import useMenuHandler from "~/pages/Sistema/sistemaHooks/useMenuHandler";

function ThisTopComponent({
  data,
  setFilteredData,
  informationPage,
  setTabFilter,
  tabProps,
  topMain = true,
  setterModoComponent,
  pageSize,
  haveFilter,
  filteredData,
  pageData,
  setPageData,
  setConfirmSelects,
}) {
  const [showLateralMenu, setShowLateralMenu] = useState();
  const { state, dispatch } = React.useContext(sistemaListStore);
  const [newValues, setNewValues] = useState({});
  const { currentMenu } = useMenuHandler({ setShowLateralMenu });
  const {
    inputs,
    selects,
    selectValues,
    createModalButton,
    setCustomFilterModal,
  } = state || {};

  const buttonCreate = (
    <ThisModalButtonHandler
      moldalType="create"
      className="btn_circle"
      setType={ColoredButton}
      background={Colors?.Color18}
      color={Colors?.Color2}
      hoverBackground={Colors?.Color18 + "e3"}
      small
    >
      {/* <i className="material-icons">add</i> */}
      Adicionar
    </ThisModalButtonHandler>
  );

  return (
    <>
      {(informationPage && currentMenu) && (
        topMain == false ? (
          <InformationPage topMain={topMain} corMenu={currentMenu} noMargin={informationPage?.noMargin ? true : false}>
            <div className="top-header">
              <div className="lateral-e">
                <div className="divTitle">
                  <div className="div-icone">
                    {
                      informationPage?.icone ?
                        informationPage?.menu ? (
                          <img src={require('~/assets/menus/' + informationPage?.icone + '.svg')} alt={informationPage?.icone || ""} />
                        )
                          : (
                            <img src={require('~/assets/submenus/' + informationPage?.icone + '.svg')} alt={informationPage?.icone || ""} />
                          )
                        : (
                          <img src={require('~/assets/submenus/' + informationPage?.icone + '.svg')} alt={informationPage?.icone || ""} />
                        )
                    }
                    <h4 className="title">{informationPage?.title}</h4>
                  </div>
                </div>
              </div>
              {currentMenu != undefined && (
                <div className="lateral-d1">
                  {setterModoComponent && (
                    <button className="btn-list" onClick={() => setterModoComponent()}>
                      <img src={localStorage.getItem("tema") == "E" ? IconListAtiva : IconListAtiva} alt="Icone de Lista" />
                    </button>
                  )}
                  <div className="pesquisa">
                    {(inputs?.length > 0 || selects?.length > 0) && (
                      <FilterList
                        selectFilters={selectValues}
                        data={data}
                        singleInput
                        setter={setFilteredData}
                      >
                        {inputs?.length > 0 && (
                          <FilterInputList
                            className="ThisTopComponent_FilterInputList"
                            inputs={inputs}
                          />
                        )}
                      </FilterList>
                    )}
                  </div>
                  <div className="filtros">
                    {selects?.map((select, i) => {
                      const { outLabel, filter, list, onChange, type, ...thisRest } = select || {};
                      return (
                        type != "date"
                        ? <Select
                          firstOption={outLabel}
                          defaultValue={selectValues?.[filter]}
                          value={newValues?.[filter]}
                          notForm={true}
                          onChange={(e) => {
                            let thisValue = e.currentTarget.value;

                            setNewValues((prev) => ({
                              ...prev,
                              [select.filter]: thisValue,
                            }));

                            if (onChange) {
                              onChange(thisValue);
                            }
                          }}
                          list={list}
                          {...thisRest}
                        />
                        : <Input 
                            type
                            notForm={true}
                            defaultValue={selectValues?.[filter]}
                            {...thisRest}
                            onChange={(e) => {
                              let thisValue = e.currentTarget.value;

                              setNewValues((prev) => ({
                                ...prev,
                                [select.filter]: thisValue,
                              }));

                              if (onChange) {
                                onChange(thisValue);
                              }
                            }}
                        />
                      );
                    })}
                    {selects?.length > 0 && (
                      <button
                        className="btn-filtro"
                        onClick={() => {
                          dispatch(sistemaListConfirmSelectValues(newValues));
                          setConfirmSelects(true);
                        }}
                      >
                        <img src={localStorage.getItem("tema") == "E" ? IconConfirmFiltro : IconConfirmFiltroB} alt="Icone de Confirmação" />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </InformationPage >
        ) : (
          <InformationPage topMain={topMain} corMenu={currentMenu} noMargin={informationPage?.noMargin ? true : false}>
            <div className="top-header">
              <div className="lateral-e">
                <div className="divTitle">
                  <div className="div-icone">
                    {
                      informationPage?.icone ?
                        informationPage?.menu ? (
                          <img src={require('~/assets/menus/' + informationPage?.icone + '.svg')} alt={informationPage?.icone || ""} />
                        )
                          : (
                            <img src={require('~/assets/submenus/' + informationPage?.icone + '.svg')} alt={informationPage?.icone || ""} />
                          )
                        : (
                          <img src={require('~/assets/submenus/' + informationPage?.icone + '.svg')} alt={informationPage?.icone || ""} />
                        )
                    }
                    <h4 className="title">{informationPage?.title}</h4>
                  </div>
                  <h4 className="subTitle">{informationPage?.subTitle}</h4>
                </div>
              </div>
              {(selects?.length > 0 || createModalButton) && (
                <div className="lateral-d">
                  <div className="ThisTopComponent_buttons">
                    {setCustomFilterModal ? (
                      <ColoredButton
                        small
                        className="btn_circle ThisTopComponent_buttons_firstButton"
                        onClick={() => setCustomFilterModal?.(true)}
                      >
                        <i className="material-icons">filter_alt</i>
                        {/* Filtro */}
                      </ColoredButton>
                    ) : (
                      <></>
                    )}
                    {createModalButton && (buttonCreate)}
                  </div>
                  {!setCustomFilterModal && <ThisFilterModal />}
                </div>
              )}
            </div>
            <div className="top-body">
              {currentMenu != undefined && (
                <div className="lateral-d">
                  <div className="pesquisa">
                    {(inputs?.length > 0 || selects?.length > 0) && (
                      <FilterList
                        selectFilters={selectValues}
                        data={data}
                        singleInput
                        setter={setFilteredData}
                      >
                        {inputs?.length > 0 && (
                          <FilterInputList
                            className="ThisTopComponent_FilterInputList"
                            inputs={inputs}
                          />
                        )}
                      </FilterList>
                    )}
                  </div>
                  <div className="filtros">
                    {selects?.map((select, i) => {
                      const { outLabel, filter, list, onChange, type, ...thisRest } = select || {};
                      
                      return (
                        type != "date"
                        ? <Select
                          firstOption={outLabel}
                          defaultValue={selectValues?.[filter]}
                          value={newValues?.[filter]}
                          notForm={true}
                          onChange={(e) => {
                            let thisValue = e.currentTarget.value;

                            setNewValues((prev) => ({
                              ...prev,
                              [select.filter]: thisValue,
                            }));

                            if (onChange) {
                              onChange(thisValue);
                            }
                          }}
                          list={list}
                          {...thisRest}
                        />
                        : <Input 
                            className="input-select"
                            type={"date"}
                            notForm={true}
                            defaultValue={selectValues?.[filter]}
                            {...thisRest}
                            onChange={(e) => {
                              let thisValue = e.currentTarget.value == "" ? undefined : e.currentTarget.value;
                              
                              setNewValues((prev) => ({
                                ...prev,
                                [select.filter]: thisValue,
                              }));

                              if (onChange) {
                                onChange(thisValue);
                              }
                            }}
                        />
                      );
                    })}
                    {selects?.length > 0 && (
                      <button
                        className="btn-filtro"
                        onClick={() => {
                          if (setConfirmSelects) {
                            setConfirmSelects(true);
                          } else {
                            dispatch(sistemaListConfirmSelectValues(newValues));
                          }
                        }}
                      >
                        <img src={localStorage.getItem("tema") == "E" ? IconConfirmFiltro : IconConfirmFiltroB} alt="Icone de Confirmação" />
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div className="lateral-e">
                {pageSize && <Pagination data={haveFilter ? filteredData : data} size={pageSize} getInfo={({ pageData }) => setPageData(pageData)} />}

                {/* {tabProps?.list?.length > 0 && (
                  <TabsWrapper tabHeight={2.4} tab={tabProps?.tabAtivo}>
                    {tabProps?.list?.map((tab, i) => {
                      return (
                        <Tab onClick={() => setTabFilter(tab?.value)} {...tab}>
                          <p>{tab?.title}</p>
                        </Tab>
                      );
                    })}
                  </TabsWrapper>
                )} */}
              </div>
            </div>
          </InformationPage >
        )
      )
      }
    </>
  );
}

export default ThisTopComponent;
