import React, { useContext, useState, useMemo } from "react";
import sistemaListStore, { sistemaListShowModal } from "../../../ThisMainComponent/thisStore";
import { IsFunction } from "~/utils/CheckDataType";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function ThisLine({
  children,
  linhaAtiva,
  setLinhaAtiva,
  id,
  data,
  noEdit,
  noDelete,
  noRestore,
  onClick,
  setType,
  ...rest
}) {
  const moldalType = "edit";
  const { state, dispatch } = useContext(sistemaListStore);
  const { show, create, edit, destroy, filter } = state?.modal || {};
  const { findOne } = state?.requests || {};
  const [active, setActive] = useState();
  const { ativo: reduxConfirm } = useSelector((state) => state?.confirmacaoModal || {});

  useEffect(() => {
    if (!reduxConfirm && destroy && !active) {
      dispatch(sistemaListShowModal(false));
    }
  }, [reduxConfirm, destroy, dispatch, active]);

  const checkModal = useMemo(() => {
    return show || create || edit || destroy || filter;
  }, [create, destroy, edit, show, filter]);

  useEffect(() => {
    if (!checkModal && !!active) {
      dispatch(sistemaListShowModal({ [moldalType]: true }, active));
      onClick && onClick();
      setActive();
    }
  }, [active, checkModal, dispatch, moldalType, onClick]);


  return <tr {...{ ...rest }}
    onClick={async () => {
      if (!noEdit && linhaAtiva === id) {
        let entityData = moldalType && data;

        if (entityData && IsFunction(findOne)) {
          await findOne(data)
            .then((resp) => resp?.data && (entityData = resp.data))
            .catch(() => { });
        }
        if (checkModal) {
          setActive(entityData || true);
        } else {
          setActive();
          dispatch(sistemaListShowModal({ [moldalType]: true, editShow: true }, entityData, { noEdit, noDelete, noRestore }));

          onClick && onClick();
        }
      }
      setLinhaAtiva(id);
    }}
    title="Clique duas vezes para editar o item"
  >
    {children}
  </tr>;
}

export default ThisLine;
