import React, { useContext } from "react";
import { Colors } from "~/config/Colors";

import { ThisSistemaListaIconButonStyle } from "../styles";
import sistemaListStore, {
  sistemaListShowModal,
} from "../../../ThisMainComponent/thisStore";
import Button from "~/componentes/Button";

function ThisCloseButton({ closeCondition = true, className, ...rest }) {
  const { dispatch } = useContext(sistemaListStore);

  return (
    <ThisSistemaListaIconButonStyle
      style={{ color: Colors.bg_color_1 }}
      as={Button}
      onClick={() => {
        closeCondition && dispatch(sistemaListShowModal(false));
      }}
      className={`material-icons ${className}`}
      {...rest}
    >
      close
    </ThisSistemaListaIconButonStyle>
  );
}

export default ThisCloseButton;
