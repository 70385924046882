import React, { useContext, useState, useMemo } from "react";
import sistemaListStore, { sistemaListShowModal } from "../thisStore";
import { IsFunction } from "~/utils/CheckDataType";
import { useEffect } from "react";
import Button from "~/componentes/Button";
import { useSelector } from "react-redux";

function ThisModalButtonHandler({
  data,
  setType,
  children,
  moldalType = false,
  onClick,
  ...rest
}) {
  const { state, dispatch } = useContext(sistemaListStore);
  const { show, create, edit, destroy, filter } = state?.modal || {};
  const { findOne } = state?.requests || {};
  const [active, setActive] = useState();
  const { ativo: reduxConfirm } = useSelector((state) => state?.confirmacaoModal || {});

  const ThisButton = setType || Button;

  useEffect(() => {
    if (!reduxConfirm && destroy && !active) {
      dispatch(sistemaListShowModal(false));
    }
  }, [reduxConfirm, destroy, dispatch, active]);

  const checkModal = useMemo(() => {
    return show || create || edit || destroy || filter;
  }, [create, destroy, edit, show, filter]);

  useEffect(() => {
    if (!checkModal && !!active) {
      dispatch(sistemaListShowModal({ [moldalType]: true }, active));
      onClick && onClick();
      setActive();
    }
  }, [active, checkModal, dispatch, moldalType, onClick]);

  async function handleClick() {
    let entityData = moldalType && data;

    if (entityData && IsFunction(findOne)) {
      await findOne(data)
        .then((resp) => resp?.data && (entityData = resp.data))
        .catch(() => {});
    }
    if (checkModal) {
      setActive(entityData || true);
    } else {
      setActive();
      dispatch(sistemaListShowModal({ [moldalType]: true }, entityData));
      
      onClick && onClick();
    }
  }

  return (
    <ThisButton onClick={handleClick} {...rest}>
      {children}
    </ThisButton>
  );
}

export default ThisModalButtonHandler;
