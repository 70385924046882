import React, { useState, useContext } from "react";
import Select from "~/componentes/CustomForm/Select";
import Input from "~/componentes/CustomForm/Input";
import Label from "~/componentes/CustomForm/Label";

import sistemaListStore, {
  sistemaListConfirmSelectValues,
} from "../../thisStore";
import ThisModalsWrapper from "../../ThisModals/ThisModalsWrapper";
import { SLModalFooter } from "../../..";
import { useEffect } from "react";

function ThisFilterModal() {
  const { state, dispatch } = useContext(sistemaListStore);
  const { filter } = state?.modal || {};
  const { selects, selectValues } = state || {};
  const [newValues, setNewValues] = useState({});
  const [closeAnimation, setCloseAnimation] = useState(() => () => { });

  useEffect(() => {
    if (!filter) {
      setNewValues({});
    }
  }, [filter]);

  return filter ? (
    <ThisModalsWrapper
      outClickAnimation={setCloseAnimation}
      style={{ width: "30em" }}
      title="Filtro"
    >
      <div className="SistemaTable_Modal_Body padding-form">
        {selects?.map((select, i) => {
          const { outLabel, filter, type, list, ...thisRest } = select || {};

          return (
            <Label key={i} title={outLabel}>
              { type != "date" ? 
                <Select
                  firstOption
                  defaultValue={selectValues?.[filter]}
                  value={newValues?.[filter]}
                  onChange={(e) => {
                    let thisValue = e.currentTarget.value;
                    setNewValues((prev) => ({
                      ...prev,
                      [select.filter]: thisValue,
                    }));
                  }}
                  list={list}
                  {...thisRest}
                /> : <Input 
                  type
                  defaultValue={selectValues?.[filter]}
                  value={newValues?.[filter]}
                  onChange={(e) => {
                    let thisValue = e.currentTarget.value;

                    setNewValues((prev) => ({
                      ...prev,
                      [select.filter]: thisValue,
                    }));
                  }}
              />}
            </Label>
          );
        })}
      </div>
      <SLModalFooter
        // cancelButton={{ onClick: closeAnimation }}
        submitButton={{
          type: "button",
          onClick: () => {
            dispatch(sistemaListConfirmSelectValues(newValues));
            closeAnimation();
          },
        }}
      />
    </ThisModalsWrapper>
  ) : (
    <></>
  );
}

export default ThisFilterModal;
