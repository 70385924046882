import * as Dashboard from './Dashboard';
import * as Gerenciamento from './Gerenciamento';
import * as Relatorio from './Relatorio';
import * as Email from './Email';

export default {
  Dashboard,
  Gerenciamento,
  Relatorio,
  Email,
};
