import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Content, ButtonMenuPerfil, OpcaoButton, AcessoButton } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { signOut } from "~/store/modules/auth/actions";
import { changeToken } from "~/store/modules/auth/actions";

import IconePerfil from "~/assets/icones/IconePerfil.svg";
import IconModalAcessoSistema from "~/assets/icones/modal/IconModalAcessoSistema.svg";
import IconModalAcessoModulos from "~/assets/icones/modal/IconModalAcessoModulos.svg";
import IconModalAcessoPerfis from "~/assets/icones/modal/IconModalAcessoPerfis.svg";
import IconModalAcessoPerfil from "~/assets/icones/modal/IconModalAcessoPerfil.svg";
import IconModalAcessoSair from "~/assets/icones/modal/IconModalAcessoSair.svg";

export default function Perfil({
  desktop,
  perfilAtivo,
  modulos,
  setComponentPath,
  currentModulo,
  setShowMaxMenu,
}) {
  const dispatch = useDispatch();
  const { usuario, perfis } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);

  function configAcesso(perfil) {
    dispatch(changeToken(perfil));
  }

  return (
    <>
      <ButtonMenuPerfil desktop={desktop} onClick={() => setShowModal(!showModal)}>
        <div className="texto">
          <h5 className="nome">
            {
              usuario?.nome.split(" ").length > 1
                ? usuario?.nome.split(" ")[0] + " " + usuario?.nome.split(" ")[1]
                : usuario?.nome
            }
          </h5>
          <h5 className="filial">{perfilAtivo?.nomeUnidade}</h5>
        </div>
        <div className="circul-perfil">
          <h5 className="title">{usuario?.nome[0]}</h5>
        </div>
      </ButtonMenuPerfil>
      {showModal && (
        <Content outClick={() => setShowModal(false)} outClose={true}>
          <CustomScrollBar fixScrollMargin autoHeightMax="calc(100vh - 7.75em)">
            <div className="sec-title">
              <h5 className="title">Acessos</h5>
            </div>
            {perfis?.map((perfil, index) => (
              <AcessoButton
                clean
                key={index}
                ativo={perfil?.token === perfilAtivo?.token ? 1 : undefined}
                onClick={(e) => configAcesso(perfil, e)}
              >
                <img className="img-acesso" src={require('~/assets/acessos/' + (perfil?.token === perfilAtivo?.token ? "ativo/" : "desativo/") + perfil?.icone + '.svg')} alt="Acesso" />
                <div className="texto-acesso">
                  <h5 title="Empresa ErpWorks">{perfil?.nomeEmpresa} - {perfil?.nomeUnidade}</h5>
                  <h6 title={perfil?.descricaoTipo}>{perfil?.descricaoTipo}</h6>
                </div>
              </AcessoButton>
            ))}
            <span className="margin-top" />
            <div className="sec-title">
              <h5 className="title">Sistema</h5>
            </div>
            {/* <OpcaoButton
              onClick={() => {
                setComponentPath({ moduloPath: "Administracao", menuPath: "Perfil", subMenuPath: false });

                setShowMaxMenu(true);
              }}>
              <img src={IconModalAcessoPerfil} alt="Atualizar Dados" />
              <div className="texto-acesso">
                <h6>Perfil</h6>
              </div>
            </OpcaoButton> */}
            <OpcaoButton onClick={() => dispatch(signOut())}>
              <img src={IconModalAcessoSair} alt="Sair" />
              <div className="texto-acesso">
                <h6>Sair</h6>
              </div>
            </OpcaoButton>
          </CustomScrollBar>
        </Content>
      )}
    </>
  );
}
