import { takeLatest, call, put, all } from "redux-saga/effects";

import api from "~/services/api";
import { signInSucces, signFailure } from "./actions";
import history from "~/services/history";
import { tipoAcesso } from "~/config/definicoesPadrao";

function handlePreferencia(perfis, preferencias, localPreferencias) {
  if (Array.isArray(perfis) && perfis.length > 0) {
    const { tipo, idPerfil } = localPreferencias || {};
    const localPerfil = tipo && perfis.find((m) => m?.tipo === tipo && idPerfil === m?.idPerfil);

    return (
      localPerfil || preferencias?.perfil || perfis?.[1] || perfis[0]
    );
  }

  return undefined;
}

export function* signIn({ payload }) {
  try {
    const { email, senha, noRedirect } = payload;

    const response = yield call(api.post, "session", {
      email,
      senha,
    });

    const { usuario, perfis, perfilAtivo } = response.data;

    const preferencias = { perfil: perfilAtivo };
    const perfil = handlePreferencia(perfis, preferencias);

    if (perfil.token) {
      api.defaults.headers.Authorization = `Bearer ${perfil.token}`;

      yield put(signInSucces({ usuario, perfis, perfil, preferencias }));

      !noRedirect && history.push("/plataforma");
    } else {
      throw new Error("Essa conta não possui acesso a plataforma");
    }
  } catch (err) {
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, senha } = payload;

    yield call(api.post, "usuarios", {
      name,
      email,
      senha,
    });
  } catch (err) {
    yield put(signFailure());
  }
}

export function* setToken({ payload }) {
  if (!payload) return;
  const { token, perfil: perfilAtual, preferencias: localPref } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    try {
      const response = yield call(api.put, `signed/${tipoAcesso[perfilAtual?.tipo]}/session`);

      const { usuario, perfis, perfilAtivo } = response.data;

      const preferencias = { perfil: perfilAtivo };
      const perfil = handlePreferencia(perfis, preferencias, localPref);

      if (perfil.token) {
        api.defaults.headers.Authorization = `Bearer ${perfil.token}`;

        yield put(signInSucces({ usuario, perfis, perfil, preferencias }));
      } else {
        throw new Error("Essa conta não possui acesso ao sistema");
      }
    } catch (err) {
      yield put(signFailure());
    }
  }
}

export function signOut() {
  history.push("/");
  localStorage.removeItem("modulo");
  localStorage.removeItem("menu");
  localStorage.removeItem("subMenu");
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/CHANGE_PERFIS", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_UP_REQUEST", signUp),
  takeLatest("@auth/SIGN_OUT", signOut),
]);
