import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import { Colors } from "~/config/Colors";

export const Container = styled(SistemaFixedModal)`
  padding: 1em;
  width: 25em;
  height: fit-content;
  box-shadow: 0px 0px 20px rgba(174, 177, 200, 0.05) !important;
  z-index: 999;

  .loadingBody {
    position: relative;
    background: ${Colors.Color8};
    border-radius: 0.5em;
    padding: 1.5em 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span.select {
      position: absolute;
      top: 15%;
      left: 0em;
      background: ${Colors.Color13};
      width: 0.25em;
      height: 70%;
      border-radius: 1em;
    }

    .body {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 0.85em;
        font-weight: 800;
        line-height: normal;
        margin-left: 0.2em;
        color: ${Colors.Color4};
      }

      .message {
        color: ${Colors.Color7};
        font-size: 0.95em;
        font-weight: 400;
        line-height: normal;
      }
    }

  }
  .loadingContent {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1em solid #f5f5f5;
    padding: 0.5em;
  }

  .loadingIcon {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
