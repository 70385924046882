import React, { useEffect, useState } from 'react';
import api from '~/services/api';
import { Label, Select } from '~/componentes/CustomForm';

import { Container } from './styles';
import { Materialize } from '~/styles/materializeGrid';
import ShowConfirmacao from '~/utils/ShowConfirmacao';
import GroupAcoordion from '~/componentes/GroupAcoordion';
import FindOrCreateUser from '~/componentes/FindOrCreateUser';

function CreateContent({ unidades }) {
  const [modulos, setModulos] = useState([]);
  const [gruposPerfis, setGruposPerfis] = useState([]);
  const [usuario, setUsuario] = useState();
  const [tipo, setTipo] = useState('CLI');
  const [grupoPerfil, setGrupoPerfil] = useState(undefined);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;

        response = await api.post(
          '/signed/cliente/administracao/gerenciamento/perfil',
          {
            ...data,
            usuario,
            tipo,
            idGrupoPerfil: grupoPerfil?.id,
          },
        );

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao cadastrar esse Perfil, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja cadastrar esse Perfil?`,
        successModal: `Perfil cadastrado com sucesso!`,
      },
    );
  }

  useEffect(() => {
    api
      .get('/signed/geral/acessos/check', { params: { tipo: "CLI" } })
      .then(response => setModulos(response.data));
    api
      .get('/signed/cliente/administracao/gerenciamento/gruposperfis', {
        params: { tipo: "CLI", acesso: true },
      })
      .then(response => setGruposPerfis(response.data));
  }, []);

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">
            Preencha as informações corretamente, e se atente ao (*) pois são
            campos obrigatórios
          </p>
        </div>
        <FindOrCreateUser setter={setUsuario} />
        <div className="row">
          <Label title="Grupo *" className="col s4 m4 l4">
            <Select
              isNumber
              list={{ list: gruposPerfis, value: 'id', label: 'nome' }}
              name="idGrupoPerfil"
              onChange={e => {
                const v = e?.currentTarget?.value || 0;
                setGrupoPerfil(gruposPerfis.find(o => o?.id === parseInt(v)));
              }}
              firstOption
              required="true"
            />
          </Label>
          <Label title="Unidade *" className="col s4 m4 l4">
            <Select
              isNumber
              list={{ list: unidades, value: 'id', label: 'nome' }}
              name="idUnidade"
              firstOption
              required="true"
            />
          </Label>
        </div>
        <div className="row">
          <p className="col s12 m12 l12 text-separador">
            Selecione o Tipo e Marque os Acessos do Perfil abaixo:
          </p>
        </div>
        <div className="row">
          {modulos.length > 0 && (
            <GroupAcoordion
              defaultSelect={typeof grupoPerfil?.acessos == "string" ? JSON.parse(grupoPerfil?.acessos) : grupoPerfil?.acessos}
              title={'Acessos '}
              name={'acessos'}
              itens={modulos}
            />
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateContent;
