import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  input {
    padding-right: 2.5em;
  }

  button {
    background: none;
    border: none;
    position: absolute;
    right: 0.5em;
    bottom: 0.15em;
    color: #7C8084;
    font-size: 1.4em;

    :hover {
      cursor: pointer;
    }
  }
`;
