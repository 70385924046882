import React from "react";
import Check from "../..";

import { CheckBoxStyle } from "./styles";
import { useState } from "react";
import { useMemo } from "react";
import { IsFunction } from "~/utils/CheckDataType";

/**
 * @param {Object} props
 * @param {Function} props.indeterminateCondition Recebe o checked como argumento. Caso retorne true, será aplicado o ícone de indeterminado.
 * @param {string} props.name É utilizado como chave de um JSON. Caso não tenha um name, toda alteração de Check,
 *  irá sobrepor o valor do CheckWrapper. Caso seja passado um name, ele apenas irá sobrepor caso possua o mesmo name.
 *  Sendo utilizado para formulários de múltiplas escolhas.
 * @param {string} props.value Informe o valor que será utilizado quando o elemento for selecionado.
 * @param {Boolean} props.defaultHtml Transformará o Check em um checkBox padrão do html, logo, não aceita as demais props customizadas.
 * @param {string} props.type Padrão "checkBox". Usado apenas se for passado o defaultHtml.
 * @param {Boolean} props.checked Configura o estado inicial do elemento.
 * @param {Boolean} props.defaultChecked
 * @param {Function} props.onChange
 * @param {Function} props.checkedCondition Recebe o estado dos dados como argumento. Caso não seja passado a propriedade checked, pode ser adicionada uma condição que auxiliará a alteração de estados.
 * @param {Boolean} props.checkAllArray Caso o check possua um array como seu value, ele se mantém marcado caso algum de seus valores exista no estado. Caso essa opção seja true, só será marcado caso todos os valores do array existam no estado.
 * @param {Boolean} props.disabled Impede que o estado seja alterado.
 * @param {Function} props.setter Função que recebe o valor do check caso esteja confirmado ou null caso não esteja.
 * @param {Boolean} props.noLoading Se true, manterá o elemento ativo mesmo que um loading esteja ocorrendo.
 * @param {Object} props.inputProps Utilizado caso esteja usando um Check estilizado. Suas versões estilizadas utilizam um input e um span dentro de um elemento label.
 * @param {Object} props.spanProps Utilizado caso esteja usando um Check estilizado. Suas versões estilizadas utilizam um input e um span dentro de um elemento label.
 * @param {any} props.reloadDependency Adiciona um estado como dependencia forçando que o os Checks voltem ao seu estado inicial, muito usado quando o valor do checked depende de um estado que não é informado para o Check.
 */
function CheckBox({ getChecked, indeterminateCondition, className, style, subItem, ...rest }) {
  const [selected, setSelected] = useState();

  const thisIcon = useMemo(() => {
    const thisCheck = selected ? "check_box" : "check_box_outline_blank";

    if (IsFunction(indeterminateCondition)) {
      return indeterminateCondition(selected) ? "indeterminate_check_box" : thisCheck;
    }
    return thisCheck;
  }, [selected, indeterminateCondition]);

  return (
    <CheckBoxStyle
      {...{ className, style, subItem }}
      indeterminate={thisIcon === "indeterminate_check_box"}
    >
      <Check innerRef={(e) => setSelected(e?.checked)} {...rest} />
      <span className="material-icons">{thisIcon}</span>
    </CheckBoxStyle>
  );
}

export default CheckBox;
