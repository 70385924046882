/**
 * @param {Object} options
 * @param {string} options.message Texto que irá sobrepor o padrão no corpo do modal
 * @param {string} options.key Chave para verificar quem chamou o modal.
 * @param {Function} options.request Aceita uma requisição que será executada ao confirmar o modal.
 * @param {Object} options.data Insere um dado para obter no state.
 * @param {any} options.successModal Padrão true. Se false não mostrará modal de sucesso, se String, informará um texto para ser mostrado.
 * @param {any} options.errorModal Padrão true com errorHandle. Aceita um objeto com as definições do errorModal ou booleano.
 * @param {Boolean} options.errorModal.errorHandle Padrão true. Se true, mostrará no modal a mensagem de um erro recebido da api consultada que contenha a chave errorHandled = true, caso contrário mostrará a mensagem de erro padrão ou uma adicionada.
 * @param {string} options.errorModal.message Padrão true. Se true, mostrará no modal a mensagem de um erro recebido da api consultada que contenha a chave errorHandled = true, caso contrário mostrará a mensagem de erro padrão ou uma adicionada.
 */
export function confirmacaoModalToggle(options) {
  const {
    message = "Tem certeza que deseja realizar esta ação?",
    key = false,
    request = false,
    data = false,
    errorModal = { errorHandle: true },
    successModal = true,
  } = options || {};

  return {
    type: "@confirmacaoModal/confirmacaoModalToggle",
    payload: { message, key, request, data, errorModal, successModal },
  };
}

export function acceptConfirmacaoModalToggle() {
  return {
    type: "@confirmacaoModal/acceptConfirmacaoModalToggle",
  };
}

export function responseModalToggle(response) {
  return {
    type: "@confirmacaoModal/responseModalToggle",
    payload: { response },
  };
}
