const INITIAL_STATE = {
  ativo: false,
  message: "Operação realizada com sucesso.",
};

export default function successModal(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case "@successModal/succesToggle": {
      return {
        ativo: !state.ativo,
        message:
          typeof payload.message === "string"
            ? payload.message
            : "Operação realizada com sucesso.",
      };
    }
    default: {
      return state;
    }
  }
}
