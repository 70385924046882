import React, { useEffect, useState, useRef } from 'react';
import { Container, ThisProgress } from './styles';
import GaugeChart from 'react-gauge-chart';
import { Colors } from "~/config/Colors";

import IconInsatisfeito from '~/assets/icones/icon_insatisfeito.png';
import IconMuitoInsatisfeito from '~/assets/icones/icon_muito_insatisfeito.png';
import IconSatisfeito from '~/assets/icones/icon_satisfeito.png';
import IconMuitosatisfeito from '~/assets/icones/icon_muito_satisfeito.png';
import IconNormal from '~/assets/icones/icon_muito_normal.png';
import Logo from '~/assets/logos/Logo ManyFlows Vertical.png';

function PainelPDF({ data }) {
  const cores = [
    "#2E7AD3",
    "#FF5780",
    "#802ED3",
    "#C62ED3",
    "#CAC4C8",
    "#CE6D14",
    "#D32EA5",
    "#14ADCE",
    "#4691C7",
    "#FF9090",
    "#D4F37B",
    "#FFF27E",
    "#4F4F4F",
    "#B9FFDE",
    "#B9D1FF",
  ];

  return (
    <>
      <div>
        <Container>
          <div className="barra-sup"></div>
          <div className="header">
            <img src={Logo} alt="Logo" />
            <div class="text">
              <p class="titulo">Gestor Serviço</p>
              <p class="titulo-relatorio">Relatório do Dashboard</p>
              <p class="titulo-data">Relatório gerado em {new Date().toLocaleString()}</p>
            </div>
          </div>
          <div className="body">
            <div className="mid">
              <div className="col">
                <div className="linha">
                  <p className="titulo">Pesquisa:</p>
                  <p className="valor">{data?.tituloPesquisa}</p>
                </div>
                <div className="linha">
                  <p className="titulo">Período:</p>
                  <p className="valor">{data?.tituloPeriodo}</p>
                </div>
                {/* <div className="linha">
                  <p className="titulo">Unidade:</p>
                  <p className="valor">{data?.nomeUnidade}</p>
                </div> */}
              </div>
              <div className="col rigth">
                <div className="linha">
                  <p className="titulo">Turnos:</p>
                  <p className="valor">{data?.tituloTurno}</p>
                </div>
                <div className="linha">
                  <p className="titulo">Centro de Custo:</p>
                  <p className="valor">{data?.listaCCs}</p>
                </div>
              </div>
            </div>

            {data?.resultado?.map((itemPergunta, i) => (
              <div className="section-1">
                <div className="chart-barra">
                  <div className="title">
                    {i + 1}. {itemPergunta?.titulo}
                  </div>
                  {
                    itemPergunta?.tipo == "SF" && (
                      <div className="chart-total">
                        <div className="chart-tt-circle">
                          <GaugeChart id="gauge-chart5"
                            nrOfLevels={420}
                            arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                            colors={['#FF5780', '#FFAB48', '#FFD147', '#B6DF53', '#2ed3b5']}
                            textColor={Colors.Color3}
                            percent={itemPergunta?.percentualSatisfacao/100}
                            arcPadding={0.02}
                            style={{ width: '50%' }}
                            animDelay={200}
                            animateDuration={2000}
                          />
                          <p className="ct-title">Satisfação</p>
                          <p className="ct-quant">{itemPergunta?.quantidade + " respostas"}</p>
                        </div>
                      </div>
                    )
                  }
                  <div className="list">
                    {itemPergunta?.listaItens?.map((itemItem, a) => (
                      <div className="item-pergunta">
                        {
                          itemPergunta?.tipo == "SF" && (
                            itemItem?.estrela == "1"
                              ? (
                                <div className="col-1-1 align-left">
                                  <img src={IconMuitosatisfeito} alt="Vazio" />
                                </div>
                              ) : itemItem?.estrela == "2" ? (
                                <div className="col-1-1 align-left">
                                  <img src={IconSatisfeito} alt="Vazio" />
                                </div>
                              ) : itemItem?.estrela == "3" ? (
                                <div className="col-1-1 align-left">
                                  <img src={IconNormal} alt="Vazio" />
                                </div>
                              ) : itemItem?.estrela == "4" ? (
                                <div className="col-1-1 align-left">
                                  <img src={IconInsatisfeito} alt="Vazio" />
                                </div>
                              ) : (
                                <div className="col-1-1 align-left">
                                  <img src={IconMuitoInsatisfeito} alt="Vazio" />
                                </div>
                              )
                          )
                        }
                        <div className="col-3">
                          <p className="titulo-item">{itemItem?.titulo}</p>
                        </div>
                        <div className="col-6">
                          <div className="progress">
                            {
                              itemPergunta?.tipo == "SF" ? (
                                itemItem?.estrela == "1"
                                  ? (
                                    <ThisProgress backgound={'#2ED3B5'} width={itemItem?.porcentagem + "%"} />
                                  ) : itemItem?.estrela == "2" ? (
                                    <ThisProgress backgound={'#B6DF53'} width={itemItem?.porcentagem + "%"} />
                                  ) : itemItem?.estrela == "3" ? (
                                    <ThisProgress backgound={'#FFD147'} width={itemItem?.porcentagem + "%"} />
                                  ) : itemItem?.estrela == "4" ? (
                                    <ThisProgress backgound={'#FFAB48'} width={itemItem?.porcentagem + "%"} />
                                  ) : (
                                    <ThisProgress backgound={'#FF5780'} width={itemItem?.porcentagem + "%"} />
                                  )
                              ) : (
                                <ThisProgress backgound={cores[a]} width={itemItem?.porcentagem + "%"} />
                              )
                            }
                          </div>
                        </div>
                        <div className="col-1 align-right">
                          <p className="titulo-item">{itemItem?.quantidade}</p>
                        </div>
                        <div className="col-1-1 align-center">
                          <p className="titulo-item">|</p>
                        </div>
                        <div className="col-1-1 align-right">
                          <p className="titulo-item">{itemItem?.porcentagem?.toFixed(1)}%</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container >
      </div>
    </>
  );
};

export default PainelPDF;