import { IsArray, IsObject } from "./CheckDataType";

/**
 * Junta um json ao FormData do file enviado pelo FileInput, o json recebe uma chave chamada jsonData que é a mesma que o backend irá procurar.
 * @param {FormData} file O arquivo deve estar em um FormData com a chave 'file'. O FileInput já retorna um file nesse formato.
 * @param {JSON} data Deve ser um objeto do tipo JSON para que seja aplicado um JSON.stringfy, e dado um JSON.parse no backend.
 */
export default function HandleFormData(file, data) {
  const thisData = IsObject(data) && JSON.stringify(data);
  let result;

  if (file instanceof FormData) {
    if (file.has("jsonData")) {
      file.delete("jsonData");
    }
    file.append("jsonData", thisData);

    result = file;
  } else if (IsArray(file)) {
    let files = new FormData();

    for (let i = 0; i < file.length; i++) {
      if (file[i]) {
        files.append("file", file[i].get("anexo" + (i + 1)));
      }
    }
    files.append("jsonData", thisData);

    result = files;
  } else {
    result = new FormData();
    result.append("jsonData", thisData);
  }

  return result;
}
