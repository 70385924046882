import React, { useContext } from "react";
import Label from "~/componentes/CustomForm/Label";
import { Select, Input } from "~/componentes/CustomForm";
import ColoredButton from "~/componentes/Button/Styles/ColoredButton";
import SingleValueList from "~/componentes/SingleValueList";
import sistemaListStore from "../../../../../../componentes/SistemaList/ThisMainComponent/thisStore";
import { useState } from "react";
import { useEffect } from "react";
import api from "~/services/api";
import FindKeyValue from "~/utils/FindKeyValue";

import { Container } from "./styles";
import IconButton from "~/componentes/Button/Styles/IconButton";

function ItensSatisfacaoList({ defaultData, setter, data, disabled }) {
  const [dfAvailable, setDfAvailable] = useState([
    { id: 1, titulo: "Muito Satisfeito", estrela: 1, ordem: 1 },
    { id: 2, titulo: "Satisfeito", estrela: 2, ordem: 2 },
    { id: 3, titulo: "Normal", estrela: 3, ordem: 3 },
    { id: 4, titulo: "Insatisfeito", estrela: 4, ordem: 4 },
    { id: 5, titulo: "Muito Insatisfeito", estrela: 5, ordem: 5 },
  ]);
  const [responsavel, setResponsavel] = useState();
  const [dfSelected, setDfSelected] = useState([]);
  const [upgradeSetter, setUpgradeSetter] = useState(false);
  const { state } = useContext(sistemaListStore);
  const { edit, editShow } = state?.modal || {};

  // useEffect(() => {
  //   if (Array.isArray(defaultData)) {
  //     setDfSelected((prev) => {
  //       const newData =
  //         defaultData.filter((e) => !prev?.find((p) => p?.id === e?.id)) || [];

  //       return [...prev, ...newData];
  //     });

  //     if (edit) {
  //       setter((prev) => {
  //         const newData =
  //           defaultData.filter((e) => !prev?.find((p) => p?.id === e?.id)) || [];

  //         return [...prev, ...newData];
  //       });
  //     }
  //   }
  // }, [defaultData, setter, edit, editShow]);

  // useEffect(() => {
  //   const newData = responsaveis.filter((e) => !dfSelected?.find((p) => p?.id === e?.id)) || [];

  //   setDfAvailable(newData);
  // }, [dfSelected, responsaveis]);

  useEffect(() => {
    if (data.length > 0) {
      let lista = [];

      for (const itemDF of dfAvailable) {
        const findItem = data.find((e) => e?.id == itemDF?.id);
        if (!findItem) {
          lista.push(itemDF);
        }
      }

      setDfAvailable(lista);
    }

  }, [data]);

  // useEffect(() => {
  //   if (upgradeSetter === true) {
  //     setUpgradeSetter(false);
  //     setter(dfSelected);
  //   }
  // }, [dfSelected, upgradeSetter, setter]);

  function handleAddResponsavel() {
    if (responsavel?.id) {
      const checkResponsavel = dfSelected?.find((e) => e?.id === responsavel?.id);

      if (!checkResponsavel) {
        setter((prev) => [...prev, responsavel]);
      }
    }
  }

  function handleRemoveResponsavel(data) {
    setter((prev) => {
      const newPrev = [...prev];
      const checkIndex = prev.findIndex((e) => e?.id === data?.id);

      if (checkIndex >= 0) {
        let lista = [];

        for (const itemDF of dfAvailable) {
          lista.push(itemDF);
        }
        lista.push(prev[checkIndex]);
        setDfAvailable(lista);

        newPrev.splice(checkIndex, 1);
      }


      return newPrev;
    });
  }

  return (
    <Container>
      <Label title="Itens de Satisfação *" />
      {!(editShow && edit) && (
        <div className="Etapa_div_addResponsaveis">
          <Select
            list={{ list: dfAvailable, value: "id", label: "titulo" }}
            setList={setResponsavel}
            firstOption
            defaultValue={responsavel?.id}
            disabled={disabled}
          />
          {!disabled && (
            <ColoredButton small blueLayout type="button" onClick={handleAddResponsavel}>
              Adicionar
            </ColoredButton>
          )}
        </div>
      )}
      <div className="list-itens">
        {
          data?.map((item) => (
            <div className="item row">
              <div className="col s1 m1 l1 item-icon">
                <IconButton
                  className="SingleValueList_div_IconButton"
                  materialIcon={{ close: true }}
                  onClick={() => handleRemoveResponsavel(item)}
                />
              </div>
              <div className="col s7 m7 l6">
                <p>{FindKeyValue("titulo", item)}</p>
              </div>
              <Label title="Ordem:" className="col s4 m4 l5 align-left">
                <Input
                  name="ordem"
                  required="true"
                  defaultValue={item?.ordem}
                  onChange={(e) => {
                    const v = e.currentTarget.value || 0;

                    let lista = [];
                    for (const itemSelected of data) {
                      if (itemSelected?.id == item?.id) {
                        lista.push({ ...itemSelected, ordem: v });
                      } else {
                        lista.push(itemSelected);
                      }
                    }

                    setter(lista);
                  }}
                />
              </Label>
              {/* <div className="col s4 m4 l4">
                <p>{FindKeyValue("titulo", e)}</p>
              </div> */}
            </div>
          ))
        }
      </div>
      {/* <SingleValueList
        data={dfSelected}
        disabled={disabled}
        dataKey="titulo"
        {...{ ...(editShow && edit ? {} : { materialIcon: "close" }) }}
        iconClick={handleRemoveResponsavel}
      /> */}
    </Container>
  );
}

export default ItensSatisfacaoList;
