import React from "react";

import { Container } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { errorToggle } from "~/store/modules/errorModal/actions";
import { modalTimeoutErro } from "~/config/definicoesPadrao";

export default function ErrorModal(props) {
  const [closeAnimation, setCloseAnimation] = React.useState(() => () => {});
  const { ativo, message } = useSelector((state) => state.errorModal);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => ativo && closeAnimation(), modalTimeoutErro);
  }, [ativo, closeAnimation]);

  return (
    <Container
      fundoSombra={true}
      checkRedux={false}
      outClickAnimation={setCloseAnimation}
      outClick={() => ativo && dispatch(errorToggle())}
      {...props}
    >
      <div className="errorHeader">
        <div className="title-select">
          <p>Alerta de Error</p>
        </div>
      </div>
      <div className="errorBody">
        <span className="select"></span>
        <div className="title">
          <i className="material-icons">highlight_off</i>
          <p>Erro Encontrado</p>
        </div>
        <p className="message">{message || "Não foi possível realizar a operação!"}</p>
      </div>
    </Container>
  );
}
