import { Scrollbars } from "react-custom-scrollbars";
import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const Modulo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0.625em 0.625em 0 0;
  padding: 1rem 0.5rem;
  align-items: center;

  .modulo-button {
    width: 100%;
    margin-bottom: 1em;
  }
`;

export const ButtonMenu = styled.button`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125em;
  padding: 0;
  background: ${Colors.Color14};
  border: 0;
  margin-bottom: 2rem;

  img {
    width: 100%;
  }
`;

export const ModuloScroll = styled(Scrollbars)``;