import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { Label, Input, Select } from "~/componentes/CustomForm";

import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function CreateEditContent({ mountedData }) {
  const [ativo, setAtivo] = useState(!mountedData ? "true" : mountedData?.ativo ? "true" : "false");
  const [centroCustos, setCentroCustos] = useState([]);
  const [pesquisas, setPesquisas] = useState([]);

  useEffect(() => {
    api
      .get('/signed/cliente/gestorservico/gerenciamento/centroCustos', { params: { ativo: 1 } })
      .then(response => setCentroCustos(response.data));
    api
      .get('/signed/cliente/pesquisa/gerenciamento/pesquisas', { params: { ativo: 1 } })
      .then(response => setPesquisas(response.data));
  }, []);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let thisData = {
          ...data,
          ...(mountedData?.id ? { id: mountedData?.id } : {}),
          ativo: ativo === "true" ? true : false,
          tipo: "A",
        }
        let response;
        if (mountedData?.id)
          response = await api.put("/signed/cliente/gestorservico/gerenciamento/dispositivo", thisData);
        else
          response = await api.post("/signed/cliente/gestorservico/gerenciamento/dispositivo", thisData);

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${mountedData?.id ? "atualizar" : "cadastrar"} esse Dispositivo, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${mountedData?.id ? "atualizar" : "cadastrar"} esse Dispositivo?`,
        successModal: `Dispositivo ${mountedData?.id ? "atualizado" : "cadastrado"} com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">Preencha as informações corretamente, e se atente ao (*) pois são campos obrigatórios</p>
        </div>
        <div className="row">
          <Label title="Título *" className="col s5 m5 l5">
            <Input name="titulo" required="true" defaultValue={mountedData?.titulo} />
          </Label>
          <Label title="Codigo Device *" className="col s2 m2 l2">
            <Input name="codigoDevice" required="true" defaultValue={mountedData?.codigoDevice} />
          </Label>
          <Label title="Centro de Custo *" className="col s3 m3 l3">
            <Select
              isNumber
              list={{ list: centroCustos, value: 'id', label: 'titulo' }}
              name="idCentroCusto"
              firstOption
              required="true"
              defaultValue={mountedData?.idCentroCusto}
            />
          </Label>
          <Label title="Ativo *" className="col s2 m2 l2">
            <Select
              isString
              list={{ list: [{ id: "true", titulo: "Sim" }, { id: "false", titulo: "Não" }], value: "id", label: "titulo" }}
              name="ativo"
              defaultValue={mountedData?.ativo?.toString()}
              onChange={(e) => {
                const v = e.currentTarget.value || "true";
                setAtivo(v);
              }}
            />
          </Label>
        </div>
        <div className="row">
          <Label title="Possui Acesso *" className="col s6 m6 l3">
            <Select
              isString
              list={{ list: [{ id: "S", titulo: "Sim" }, { id: "N", titulo: "Não" }], value: "id", label: "titulo" }}
              name="possuiAcesso"
              defaultValue={mountedData?.possuiAcesso}
            />
          </Label>
          <Label title="Tipo *" className="col s6 m6 l2">
            <Select
              isString
              list={{ list: [{ id: "A", titulo: "Acesso" }], value: "id", label: "titulo" }}
              name="tipo"
              defaultValue={mountedData?.tipo}
            />
          </Label>
          <Label title="Id de Acesso" className="col s6 m6 l2">
            <Input name="idAcesso" required="true" defaultValue={mountedData?.idAcesso} />
          </Label>
          <Label title="Senha de Acesso" className="col s6 m6 l2">
            <Input name="senhaAcesso" required="true" defaultValue={mountedData?.senhaAcesso} />
          </Label>
          <Label title="Pesquisa Ativa *" className="col s3 m3 l3">
            <Select
              isNumber
              list={{ list: pesquisas, value: 'id', label: 'titulo' }}
              name="idPesquisa"
              firstOption
              required="true"
              defaultValue={mountedData?.idPesquisa}
            />
          </Label>
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateEditContent;
