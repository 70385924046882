import React from "react";
import { Menu, MenuScroll } from "./styles";
import MenuButton from "./MenuButton";
import SubMenuLista from "./SubMenuLista";

export default function MenuLista({
  desktop,
  right,
  setComponentPath,
  currentModulo,
  currentMenu,
  currentSubMenu,
  showMaxMenu,
  showSubMenu,
  setShowSubMenu,
  showMenuWithSubMenu,
  setShowMenuWithSubMenu,
}) {

  return (
    <Menu {...{ desktop, showMaxMenu }}>
      <div className="menu-scroll">
        {currentModulo?.menus &&
          currentModulo?.menus.map(
            (menu, mi) => (
              <div className="menu-full">
                <div className="menu-button" key={menu.id || mi}>
                  <MenuButton
                    {...{
                      desktop,
                      menu,
                      selected: currentMenu?.id === menu?.id,
                      setComponentPath,
                      showSubMenu,
                      setShowSubMenu,
                      currentModulo,
                      currentSubMenu,
                      showMenuWithSubMenu,
                      setShowMenuWithSubMenu,
                    }}
                  />
                </div>
                <SubMenuLista
                  {...{
                    menu: menu,
                    subMenus: menu?.subMenus,
                    setComponentPath,
                    currentModulo,
                    currentMenu,
                    currentSubMenu,
                    selected: currentMenu?.id === menu?.id,
                  }}
                />
              </div>
            )
          )
        }
      </div>
    </Menu>
  );
}
