import formDeleteNamePath from "~/componentes/CustomForm/utils/formDeleteNamePath";
import formCreateNamePath from "~/componentes/CustomForm/utils/formCreateNamePath";

export default function formReducer(state = {}, { type, payload }) {
  switch (type) {
    case "formHandleData": {
      const { value, name, removeValue } = payload || {};

      let newState = state;

      if (removeValue) {
        formDeleteNamePath(name, newState);
      } else {
        newState = formCreateNamePath(name, state, value);
      }
      return { ...newState };
    }
    case "formClearData":
      return {};
    default:
      return state;
  }
}
