import React from "react";
import { useSelector } from "react-redux";
import acessos from "~/pages/Sistema/acessos";
import { tipoAcesso } from "~/config/definicoesPadrao";

import { ThisButton } from "./styles";

function SubMenuButton({ selected, setComponentPath, currentModulo, menu, subMenu }) {
  const { auth: { perfil } } = useSelector((state) => state) || {};

  return (
    <ThisButton
      selected={selected}
      ftColorSelect={"#67B0CF"}
      onClick={() => {
        const Componente = acessos?.[tipoAcesso[perfil?.tipo]]?.[currentModulo?.path]?.[menu?.path]?.[subMenu?.path];

        if (typeof Componente === "function") {
          setComponentPath({
            moduloPath: currentModulo?.path,
            menuPath: menu?.path,
            subMenuPath: subMenu?.path,
          });
        }
      }}
    >
      <img src={require('~/assets/submenus/' + (localStorage.getItem("tema") == "E" ? "dark-" : "") + subMenu?.icone + '.svg')} alt={subMenu.nome} />
      <p>{subMenu.nome}</p>
    </ThisButton>
  );
}

export default SubMenuButton;
