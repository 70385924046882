import styled from "styled-components";
import Button from "~/componentes/Button";
import { Colors } from "~/config/Colors";

export const ThisButtonStyle = styled(Button)`
  transition: 0.1s ease;
  height: ${(props) =>
    props.tabheight > 2.4 ? `${props.tabheight}em` : "2.2em"};
  min-width: ${(props) =>
    props.tabwidth > 2.4 ? `${props.tabwidth}em` : "5.5em"};
  padding: 0.625em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 0.8em;
  position: relative;
  border: none;
  border-radius: 0.6em;
  margin-left: 0.5em;
  border-radius: 4rem;
  background: ${(props) =>
    props.ativo
      ? props.revertcolor
        ? Colors.Color1
        : Colors.Color18
      : props.revertcolor
        ? Colors.Color18
        : Colors.Color1};
  color: ${(props) =>
    props.ativo
      ? props.revertcolor
        ? Colors.Color4
        : Colors.Color2
      : props.revertcolor
        ? Colors.Color2
        : Colors.Color4};

  .TabsWrapper_Tab_DefaultTabIconStyle {
    ${(props) =>
    props.ismobile
      ? `
      margin: auto;
      font-size: 1.2em;
    `
      : `
      margin-right: 0.5em;
    `};
  }

  p {
    line-height: 1em;
  }

  &:active {
    transform: scale(0.97);
  }

  :hover {
    background: ${Colors.Color18};
    color: ${Colors.Color2};
  }
`;

export const Container = styled.div`
  width: fit-content;
  display: flex;
  z-index: 0;
  padding: 0.6rem;
  background: ${Colors.Color1};
  width: fit-content;
  border-radius: 4rem;
  margin-top: 0.8rem;
`;
