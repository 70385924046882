import React, { useContext } from "react";

import { Colors } from "~/config/Colors";
import { Container } from "./styles";

import ThisCloseButton from "../../ThisButtons/ThisCloseButton";
import ThisDeleteButton from "../../ThisButtons/ThisDeleteButton";
import ThisRestoreButton from "../../ThisButtons/ThisRestoreButton";
import ThisEditButton from "../../ThisButtons/ThisEditButton";
import sistemaListStore from "../../../ThisMainComponent/thisStore";

/**
 * @param {Object} props
 * @param {Object} props.title Apenas adiciona um <h3>title</h3>.
 * @param {Function} props.closeModal Se passado uma função, irá utilizar ela no lugar do closeModal padrão.
 */
function ThisModalHeader({
  title,
  icon,
  menu,
  data,
  children,
  closeCondition,
  noDelete,
  noRestore,
  background,
  color,
  hoverBackground,
  ...rest
}) {
  const { state } = useContext(sistemaListStore);
  const { requests, modal } = state || {};
  const { edit } = modal || {};

  return (
    <Container {...{ ...rest, background, color, hoverBackground }}>
      {title && (
        <div className="select-title">
          {icon ?
            menu ? (
              <img
                src={require('~/assets/menus/' + icon + '.svg')}
                alt={"Icone do Menu"}
              />
            ) : (
              <img
                src={require('~/assets/submenus/' + icon + '.svg')}
                alt={"Icone do Menu"}
              />
            )
            : (
              <img
                src={require('~/assets/submenus/' + icon + '.svg')}
                alt={"Icone do Menu"}
              />
            )}
          <h3>{title}</h3>
        </div>
      )}

      <div className="SistemaList_ModalHeader_Actions">
        {(edit && requests?.findOne && data?.idDelete === 0) && (
          <ThisEditButton data={data} {...{ background: Colors?.Color18, color: Colors?.Color2, hoverBackground: background }} />
        )}
        {(edit && requests?.deleteOne && !noDelete && data?.idDelete === 0) && (
          <ThisDeleteButton data={data} {...{ background: Colors?.Color18, color: Colors?.Color2, hoverBackground: background }} />
        )}
        {(edit && requests?.restoreOne && !noRestore && data?.idDelete !== 0) && (
          <ThisRestoreButton data={data} {...{ background: Colors?.Color18, color: Colors?.Color2, hoverBackground: background }} />
        )}
        <ThisCloseButton
          className="SistemaList_ModalHeader_closeButton"
          closeCondition={closeCondition}
          {...{ background: Colors?.Color18, color: Colors?.Color2, hoverBackground: background }}
        />
      </div>
      {children}
    </Container>
  );
}

export default ThisModalHeader;
