import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const ItemModulo = styled.div`
  display: flex;
`;

export const ItemButtonModulo = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  border-radius: 0.375em;
  border: 0;
  width: 2rem;
  height: 2.2rem;

  :hover {
    background: ${Colors.Color11};
  }

  ${({ selected }) => {
    if (selected) {
      return `
        background: ${Colors.Color11};
      `;
    } else {
      return `
        background: ${Colors.Color2};
      `;
    }
  }}

  img {
    border-radius: 0.375em;
    width: 2.75em;
  }
`;