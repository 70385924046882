import React, { useState } from 'react';
import api from '~/services/api';
import { Label, SenhaInput } from '~/componentes/CustomForm';

import { Container } from './styles';
import { Materialize } from '~/styles/materializeGrid';
import ShowConfirmacao from '~/utils/ShowConfirmacao';

function EditContent({ mountedData, analistas }) {
  const [senha, setSenha] = useState("");
  const [confirmeSenha, setConfirmeSenha] = useState("");
  const [novaSenha, setNovaSenha] = useState("");

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let thisData = {
          senha,
          confirmeSenha,
          novaSenha,
        }
        let response = await api.put("/signed/geral/usuario", thisData);

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao atualizar seu Perfil, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja atualizar seu Perfil?`,
        successModal: `Perfil atualizado com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">
            Preencha as informações corretamente, e se atente ao (*) pois são
            campos obrigatórios
          </p>
        </div>
        <div className="row">
          <div className="row">
            <Label title="Senha *" className="col s12 m4 l3">
              <SenhaInput
                name="senha"
                defaultHtml
                required={true}
                onChange={e => {
                  const v = e.currentTarget.value || "";
                  setSenha(v);
                }}
              />
            </Label>
            <Label title="Nova Senha *" className="col s12 m4 l3">
              <SenhaInput
                name="novaSenha"
                defaultHtml
                required={true}
                onChange={e => {
                  const v = e.currentTarget.value || "";
                  setNovaSenha(v);
                }}
              />
            </Label>
            <Label title="Confirme a Nova Senha *" className="col s12 m4 l3">
              <SenhaInput
                name="confirmeSenha"
                defaultHtml
                required={true}
                onChange={e => {
                  const v = e.currentTarget.value || "";
                  setConfirmeSenha(v);
                }}
              />
            </Label>
          </div>
        </div>
      </Materialize>
    </Container >
  );
}

export default EditContent;
