import React, { useEffect, useState } from 'react';
import api from '~/services/api';
import setRegex from "~/utils/setRegex";
import HandleFormData from "~/utils/HandleFormData";
import { Label, Input, Select } from '~/componentes/CustomForm';
import PreviewFileInput from "~/componentes/CustomForm/FileInput/styles/PreviewFileInput";
import GroupAcoordion from "~/componentes/GroupAcoordion";

import { Container, CustomScrollBarCust } from './styles';
import { Materialize } from '~/styles/materializeGrid';
import { SLModalFooter } from '~/pages/Sistema/componentes/SistemaList';
import ShowConfirmacao from '~/utils/ShowConfirmacao';
import ProdutosList from '../ProdutosList';
import FindOrCreateUser from '~/componentes/FindOrCreateUser';

function CreateEditContent({ mountedData }) {
  const [estados, setEstados] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [file, setFile] = useState();
  const [fileExist, setFileExist] = useState();
  const [usuario, setUsuario] = useState();
  const [ativo, setAtivo] = useState(
    !mountedData ? 'true' : mountedData?.ativo ? 'true' : 'false',
  );
  const [acessos, setAcessos] = useState(typeof mountedData?.empresaCliente?.acessos == "string" ? JSON.parse(mountedData?.empresaCliente?.acessos) : mountedData?.empresaCliente?.acessos);

  const [modulos, setModulos] = useState([]);

  useEffect(() => {
    api
      .get("/signed/geral/acessos/check/cliente", { params: { tipo: "CLI" } })
      .then(response => {
        setModulos(response.data);
      });
  }, []);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let thisData = HandleFormData(file, {
          ...data,
          produtos,
          usuario,
          ...(mountedData?.id ? { id: mountedData?.id } : {}),
          ativo: ativo === 'true' ? true : false,
          fileExist: fileExist,
        });
        let response;
        if (mountedData?.id)
          response = await api.put("/signed/empresa/gestorservico/cliente/cliente", thisData);
        else
          response = await api.post("/signed/empresa/gestorservico/cliente/cliente", thisData);

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${mountedData?.id ? 'atualizar' : 'cadastrar'} esse Cliente, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${mountedData?.id ? 'atualizar' : 'cadastrar'} esse Cliente?`,
        successModal: `Cliente ${mountedData?.id ? 'atualizado' : 'cadastrado'} com sucesso!`,
      },
    );
  }

  useEffect(() => {
    api.get("/signed/geral/estados").then((response) => setEstados(response.data));
  }, []);

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">
            Preencha as informações corretamente, e se atente ao (*) pois são
            campos obrigatórios
          </p>
        </div>
        <div className="row">
          <Label title="Razão Social *" className="col s4 m4 l4">
            <Input
              name="razaoSocial"
              required="true"
              defaultValue={mountedData?.razaoSocial}
            />
          </Label>
          <Label title="Nome Fantasia *" className="col s3 m3 l3">
            <Input
              required="true"
              name="nomeFantasia"
              defaultValue={mountedData?.nomeFantasia}
            />
          </Label>
          <Label title="Cnpj/Cpf *" className="col s3 m3 l3">
            <Input
              required="true"
              name="identificacao"
              defaultValue={mountedData?.identificacao}
            />
          </Label>
          <Label title="Estado *" className="col s6 m6 l2">
            <Select
              isNumber
              list={{ list: estados, value: "id", label: "nome" }}
              name="idEstado"
              firstOption
            />
          </Label>
        </div>
        <div className="row">
          <Label title="E-mail *" className="col s3 m3 l3">
            <Input
              required="true"
              name="email"
              type="email"
              defaultValue={mountedData?.email}
            />
          </Label>
          <Label title="Telefone 1 *" className="col s2 m2 l2">
            <Input
              name="telefone1"
              required="true"
              defaultValue={mountedData?.telefone1}
            />
          </Label>
          <Label title="Telefone 2 " className="col s2 m2 l2">
            <Input name="telefone2" defaultValue={mountedData?.telefone2} />
          </Label>
          <Label title="Ativo *" className="col s2 m2 l2">
            <Select
              isString
              list={{
                list: [
                  { id: 'true', titulo: 'Sim' },
                  { id: 'false', titulo: 'Não' },
                ],
                value: 'id',
                label: 'titulo',
              }}
              name="ativo"
              defaultValue={mountedData?.ativo?.toString()}
              onChange={e => {
                const v = e.currentTarget.value || 'true';
                setAtivo(v);
              }}
            />
          </Label>
        </div>
        {
          mountedData ?
            <div className="row">
              <Label title="Nome Representante *" className="col s8 m8 l4">
                <Input
                  name="nome"
                  disabled={true}
                  defaultValue={mountedData?.perfilResponsavel?.usuario?.nome}
                />
              </Label>
              <Label title="Cpf Rep. *" className="col s4 m4 l2">
                <Input
                  name="cpf"
                  disabled={true}
                  defaultValue={mountedData?.perfilResponsavel?.usuario?.cpf}
                />
              </Label>
              <Label title="Email Representante *" className="col s12 m12 l4">
                <Input
                  name="email"
                  disabled={true}
                  defaultValue={mountedData?.perfilResponsavel?.usuario?.email}
                />
              </Label>
              <Label title="Data Nascimento Rep. *" className="col s6 m6 l2">
                <Input
                  type="date"
                  name="dataNascimento"
                  disabled={true}
                  defaultValue={mountedData?.perfilResponsavel?.usuario?.dataNascimento}
                />
              </Label>
            </div>
            : <FindOrCreateUser representante={true} setter={setUsuario} />
        }
        <div className="row">
          {modulos.length > 0 && (
            <GroupAcoordion
              defaultSelect={acessos}
              title={"Acessos "}
              name={"acessos"}
              itens={modulos}
            />
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateEditContent;
