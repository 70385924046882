import { useLayoutEffect, useState, useMemo } from "react";

/**
 * @param {Object} props - Json with props.
 *  @param {Number} props.mobileMaxWidth - Set mobile max-width in number.
 *  @param {Object} props.tablet - Json with min and max width.
 *    @param {Number} props.tablet.minWidth - Set tablet min-width in number.
 *    @param {Number} props.tablet.maxWidth - Set tablet max-width in number.
 *  @param {Object} props.rotatedTablet - Json with min and max width.
 *    @param {Number} props.rotatedTablet.minWidth - Set rotatedTablet min-width in number.
 *    @param {Number} props.rotatedTablet.maxWidth - Set rotatedTablet max-width in number.
 *  @param {Number} props.desktopMinWidth - Set desktop min-width in number.
 *  @param {Number} props.ratio - Set the ratio of width / heigth, it uses 1.4 as default,
 *    if setted a number it will only return true for desktop or rotatedTablet
 *    if the width check return true and screen ratio >= the ratio setted.
 *    if setted false, it will not check the ratio.
 * @returns {{ screenWidth, screenHeight, mobile, tablet, desktop, rotatedTablet, deviceRatio }}
 */
export default function UserDevice(props = {}) {
  const memoProps = useMemo(() => props, [props]);

  const mobileMaxWidth =
    memoProps && memoProps.mobileMaxWidth && memoProps.mobileMaxWidth
      ? memoProps.mobileMaxWidth + 1
      : 768;

  const tabletMinWidth =
    memoProps && memoProps.tablet && memoProps.tablet.minWidth
      ? memoProps.tablet.minWidth
      : 768;

  const tabletMaxWidth =
    memoProps && memoProps.tablet && memoProps.tablet.maxWidth
      ? memoProps.tablet.maxWidth + 1
      : 1200;

  const rotatedTabletMinWidth =
    memoProps && memoProps.rotatedTablet && memoProps.rotatedTablet.minWidth
      ? memoProps.rotatedTablet.minWidth
      : 1000;

  const rotatedTabletMaxWidth =
    memoProps && memoProps.rotatedTablet && memoProps.rotatedTablet.maxWidth
      ? memoProps.rotatedTablet.maxWidth + 1
      : 1200;

  const desktopMinWidth =
    memoProps.desktopMinWidth && memoProps.desktopMinWidth
      ? memoProps.desktopMinWidth
      : 1200;

  const checkRatioBoolean = memoProps.ratio !== false && true;

  const ratio = memoProps.ratio ? memoProps.ratio : 1.4;

  const { innerWidth: width, innerHeight: height } = window;

  const [userDevice, setUserDevice] = useState({
    screenWidth: width,
    screenHeight: height,
    mobile: width < mobileMaxWidth,
    tablet: width < tabletMaxWidth && width >= tabletMinWidth,
    rotatedTablet:
      width > height &&
      ((width >= rotatedTabletMinWidth && width < rotatedTabletMaxWidth) ||
        (checkRatioBoolean && width >= rotatedTabletMaxWidth && width / height < ratio)),
    desktop: width >= desktopMinWidth && width / height >= ratio,
    deviceRatio: width / height,
  });

  useLayoutEffect(() => {
    function updateSize() {
      const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
      const mobile = screenWidth < mobileMaxWidth;
      const tablet = screenWidth < tabletMaxWidth && screenWidth >= tabletMinWidth;
      const rotatedTablet =
        screenWidth > screenHeight &&
        ((screenWidth >= rotatedTabletMinWidth && screenWidth < rotatedTabletMaxWidth) ||
          (checkRatioBoolean &&
            screenWidth >= rotatedTabletMaxWidth &&
            screenWidth / screenHeight < ratio));
      const desktop =
        screenWidth >= desktopMinWidth && screenWidth / screenHeight >= ratio;

      const deviceRatio = screenWidth / screenHeight;

      setUserDevice({
        screenWidth,
        screenHeight,
        mobile,
        tablet,
        rotatedTablet,
        desktop,
        deviceRatio,
      });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [
    checkRatioBoolean,
    desktopMinWidth,
    mobileMaxWidth,
    ratio,
    rotatedTabletMaxWidth,
    rotatedTabletMinWidth,
    tabletMaxWidth,
    tabletMinWidth,
  ]);

  return userDevice;
}
