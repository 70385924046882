import Scrollbars from "react-custom-scrollbars";
import styled from "styled-components";
import { Colors } from "~/config/Colors";
import Card from '~/pages/Sistema/componentes/Card';

export const ThisCardAtvi = styled.div`
  margin-bottom: 1.3em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;

  .atividade {
    padding: 0.8em;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 3.5em;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(174, 177, 200, 0.05) !important;
    background: ${Colors.Color1};

    .content {
      display: flex;
      justify-content: space-between;

      .content-left {
        display: flex;
        flex-direction: row;
        align-items: center;

        .circul-ativ{
          height: 2em;
          width: 2em;
          border-radius: 2em;
          background: ${Colors.Color8};
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1em;

          img{
            height: 1.3em;
            width: 1.3em;
          }
        }

        .card-top {
          margin-top: 0.5em;

          .titulo {
            font-size: 0.7em;
            font-weight: bold;
            color: ${Colors.Color4};
            margin-bottom: 5px;
            line-height: 0.1em;
          }

          .subtitulo {
            font-size: 0.65em;
            font-weight: 600;
            color: ${Colors.Color14};
          }
        }
      }

      .content-right{
        display: flex;
        flex-direction: row;
        align-items: center;

        .card-total {
          background: ${Colors.Color8};
          border-radius: 0.3em;
          padding: 0.2em 0.6em;
          display: flex;
          flex-direction: row;
          height: 1.2em;
          margin-left: 1em;
          justify-content: center;
          align-items: center;
    
          .total {
            font-size: 0.7em;
            font-weight: bold;
            color: ${Colors.Color4};
            width: 100%;
          }
        }
        .active{
          background: ${Colors.Color8};
          margin-left: 2em;
          width: 1.5em;
          height: 1.5em;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.3em;
        }
      }
    }
  }

  .card-bottom{
    font-size: 0.7em;
    font-weight: 600;
    color: ${Colors.Color14};
    padding: 0.5em 1em 0.5em 1em;
    display: flex;
    background-color: ${Colors.Color23};
    height: 25%;
    width: 93%;
    border-radius: 0px 0px 15px 15px;
   
    p{
      padding: 10px;
    }
  }
`;

export const ThisCardOS = styled.div`
  margin-bottom: 1.3em;
  margin-left: 3em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  height: 100%;
  align-items: center;

  .content-os {
    height: 100%;
    width: 100%;
  }
`;

export const ThisCardTopOS = styled.div`
  margin-bottom: 1.3em;
  padding: 0.8em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 11em;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(174, 177, 200, 0.05) !important;
  background: ${Colors.Color1};

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 10px;

    .content-top {
      display: flex;
      flex-direction: column;

      .card-top {
        margin-top: 0.5em;
        line-height: 0.9em;

        .titulo {
          font-size: 0.7em;
          font-weight: bold;
          color: ${Colors.Color4};
          margin-bottom: 5px;
        }

        .analista {
          font-size: 0.65em;
          line-height: 1.3em;
          font-weight: 600;
          color: ${Colors.Color14};
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .card-cod {
        background: ${Colors.Color8};
        border-radius: 0.4em;
        padding: 0.2em 0.6em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .cod {
          font-size: 0.6em;
          font-weight: bold;
          color: ${Colors.Color4};
        }
      }

      .data {
        font-size: 0.6em;
        font-weight: 600;
        margin-top: 5px;
        color: ${Colors.Color14};
      }
    }
  }

  .totalizadores {
    display: flex;
    align-items: center;
    margin-left: 1em;
    margin-top: 2.2em;

    .desconto {
      font-size: 0.7em;
      font-weight: 600;
      color: ${Colors.Color14};
    }

    .translado {
      font-size: 0.7em;
      font-weight: 600;
      margin-left: 1em;
      color: ${Colors.Color14};
    }

    .card-total {
      background: ${Colors.Color8};
      border-radius: 0.3em;
      padding: 0.2em 0.6em;
      display: flex;
      flex-direction: row;
      height: 1.2em;
      margin-left: 1em;
      justify-content: center;
      align-items: center;

      .total {
        font-size: 0.7em;
        font-weight: bold;
        color: ${Colors.Color4};
        width: 100%;
      }
    }

    .aprovar{ 
      background: ${Colors.Color4};
      color: ${Colors.Color13};
      font-weight: bold;
      margin-left: 5em;
      font-size: 0.7em;
      height: 1.9em;
      width: 7em;
      border-style: none;
      border-radius: 6px;
    }
  }
`;

export const ThisCard = styled.div`
  margin-bottom: 1.3em;
  padding: 0.8em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 37%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(174, 177, 200, 0.05) !important;
  background: ${Colors.Color1};

  textArea{
    font-size: 0.8em;
    font-weight: 600;
    color: ${Colors.Color14} !important;
    margin-top: 2.5em;
  }

  .padding-form{
    padding: 0em !important;
    margin-top: 1em;
    font-size: 0.8em !important;
  }

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 10px;

    .content-top {
      display: flex;
      flex-direction: column;

      .card-top {
        margin-top: 0.5em;
        line-height: 0.9em;

        .titulo {
          font-size: 0.7em;
          font-weight: bold;
          color: ${Colors.Color4};
          margin-bottom: 5px;
        }

        .analista {
          font-size: 0.65em;
          line-height: 1.3em;
          font-weight: 600;
          color: ${Colors.Color14};
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .card-cod {
        background: ${Colors.Color8};
        border-radius: 0.4em;
        padding: 0.2em 0.6em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .cod {
          font-size: 0.6em;
          font-weight: bold;
          color: ${Colors.Color4};
        }
      }

      .data {
        font-size: 0.6em;
        font-weight: 600;
        margin-top: 5px;
        color: ${Colors.Color14};
      }
    }
  }

  .produtos{
    display: flex;
    .card{
      background: ${Colors.Color8};
      border-radius: 0.3em;
      padding: 0.8em;
      display: flex;
      flex-direction: row;
      height: 1.2em;
      margin-left: 1em;
      align-items: center;
  
      .titulo-card{
        font-size: 0.7em;
        font-weight: bold;
        color: ${Colors.Color4};
        width: 50%;
      }
    }
  }

  .card-bottom{
    display: flex;
    flex-direction: row;
    margin-left: 1em;
    margin-top: 2em;
    
    .card-grupo{
      background-color: ${Colors.Color23};
      width: 95%;
      height: 2em;
      margin-right: 1em;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .anexo{
        font-size: 0.7em;
        font-weight: 600;
        color: ${Colors.Color14};
        padding: 0.5em 1em 0.5em 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .anexo-img{
        height: 0.85em;
        width: 0.85em;
        margin-right: 0.5em;
      }
    }
  }
`;

export const Container = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: ${Colors.Color2};
  color: ${Colors.Color3};

  .fundo-bottom{
    display: flex;
    align-items: center;

    .os{
      .os-info{
        display: flex;
        flex-direction: row;
        margin-left: 40px;
        padding: 5px 12px 5px 12px;
        background: ${Colors.Color8};
        border-radius: 6px;

        .titulo-os{
          font-size: 0.7em;
          font-weight: bold;
          margin-left: 6px;
          color: ${Colors.Color4};

        }
      }
    }
  }

  .button-menu {
    background: ${Colors.Color1};
    display: flex;
    align-items: center;

    @media (max-width: 993px) {
      .identificacao {
        display: flex !important;
      }
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100% - 5.9375em);
    width: 100%;
  }

  .body-mobile {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const TopMenu = styled.div`
  position: relative;
  height: 5.7625em;
  width: 100%;
  z-index: 4;

  .sombra {
    position: absolute;
    top: 0;
    left: 5.5em;
    width: calc(100% - ${(props) => props.widthSombra});
    height: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
    z-index: -1;
  }

  .fundo-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${Colors.Color2};
    width: 100%;
    height: calc(100% - 2.7em);
    padding: 0 1.5em;

    .cliente{
      display: flex;
      
      .cliente-content{
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .cliente-titulo{
          font-size: 0.75em;
          font-weight: bold;
          color: ${Colors.Color4};
        }

        .cliente-subtitulo{
          font-size: 0.7em;
          font-weight: 600;
          line-height: 0.9em;
          color: ${Colors.Color14};

        }
      }
    }
    
    .lateral-e {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .sec-logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // width: 14em;

        .logo {
          width: 6.79em;
        }
      }
    }

    .lateral-d {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 14em;

      button {
        margin-right: 2.5em;
      }
    }
  }

  .fundo-bottom {
    background: ${Colors.Color1};
    height: 2.7em;
    width: 100%;
    border-bottom: 2px solid ${localStorage.getItem("tema") == "E" ? "#28242b" : Colors?.Color2};

    .navigation-menu {
      display: flex;
      width: 4em;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: row;

      .nav {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 1.4em;
          height: 1.6em;
        }
      }
    }
  }
`;

export const ButtonMenuPerfil = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 1em;
  margin-right: 0em !important;
  border: 0;
  background: ${Colors.Color2};

  .circul-perfil {
    width: 1.8em;
    height: 1.8em;
    background: ${Colors.Color4};
    border-radius: 3em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      color: ${Colors.Color2};
      font-size: 0.8em;
      font-weight: 900;
    }
  }

  .info-perfil {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0.8em;

    .text-nome {
      font-size: 0.76em;
      font-weight: 600;
      line-height: 1em;
      color: ${Colors.Color5};
    }

    .text-email {
      font-size: 0.6em;
      line-height: 1.5em;
      color: ${Colors.Color6};
    }
  }
`;

export const ButtonMenu = styled.button`
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125em;
  padding: 0;
  background: ${Colors.Color2};
  border: 0;

  img {
    width: 1.4em;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  z-index: 2;
  background: ${Colors.Color2};
  transition: 0.5s;
  padding: 2em 2em;

  ${({ showMenuRight, showSubMenu, showSubMenuRight }) => {
    if (showMenuRight) {
      if ((showSubMenu && showSubMenuRight == false) || (showSubMenu == false && showSubMenuRight)) {
        return `
          overflow: initial;
          width: calc(100% - 20.8em);
        `;
      } else if (showSubMenu && showSubMenuRight) {
        return `
          width: calc(100% - 32em);
          overflow: hidden;
        `;
      } else {
        return `
            width: 100%;
            overflow: hidden;
          `;
      }
    } else {
      if (showSubMenu) {
        return `
          overflow: initial;
          width: calc(100% - 16em);
        `;
      } else {
        return `
            width: calc(100% - 4.8em);
            overflow: hidden;
          `;
      }
    }
  }}

  .mainScroll > div:last-child {
    bottom: 24px !important;
    top: 0px !important;
  }
`;

export const MainScroll = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  border-radius: 0.8125em;
  background: ${Colors.Color2};
  display: flex;
  flex-direction: row;
`;

export const ScrollOS = styled(Scrollbars)`
  width: calc(100%) !important;
`;