import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { Label, Input, Select } from "~/componentes/CustomForm";

import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function CreateEditContent({ mountedData }) {
  return (
    <Container defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">Preencha as informações corretamente, e se atente ao (*) pois são campos obrigatórios</p>
        </div>
        <div className="row">
          <div className="card-atv-header">
            <div className="titulos-tab">
              <div className="col col1">
                <p className="titulo">Id</p>
              </div>
              <div className="col col2">
                <p className="titulo">Dispositivo</p>
              </div>
              <div className="col col3">
                <p className="titulo">Pesquisa</p>
              </div>
              <div className="col col4">
                <p className="titulo">Pergunta</p>
              </div>
              <div className="col col2">
                <p className="titulo">Item</p>
              </div>
              <div className="col col2">
                <p className="titulo">Data</p>
              </div>
              <div className="col col1">
                <p className="titulo">Hora</p>
              </div>
            </div>
          </div>
          {mountedData?.map((itemData, index) => (
            <div className="card-atv-body">
              <div className="titulos-tab">
                <div className="col col1">
                  <p className="titulo">{itemData?.codigoPesquisa}</p>
                </div>
                <div className="col col2">
                  <p className="titulo">{itemData?.dispositivo?.idAcesso}</p>
                </div>
                <div className="col col3">
                  <p className="titulo">{itemData?.pesquisa?.titulo}</p>
                </div>
                <div className="col col4">
                  <p className="titulo">{itemData?.pergunta?.titulo}</p>
                </div>
                <div className="col col2">
                  <p className="titulo">{itemData?.perguntaItem?.titulo}</p>
                </div>
                <div className="col col2">
                  <p className="titulo">{itemData?.dataCadastro}</p>
                </div>
                <div className="col col1">
                  <p className="titulo">{itemData?.horaCadastro}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateEditContent;
