import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const Container = styled.div`
  position: relative;

  input {
    padding-left: 2.5em;
  }

  i {
    position: absolute;
    left: 0.4em;
    top: 0.3em;
    color: #fff;
  }

`;

export const InputCustom = styled.input`
  color: ${Colors.Color7} !important;

  ${({ notForm }) => {
    if (notForm == true) {
      return `
        background-color: ${Colors.Color8};
        color: ${Colors.Color7} !important;
      `;
    } else {
      return `
        background-color: ${Colors.Color8};
        color: ${Colors.Color7} !important;
      `;
    }
  }}

  font-size: 0.79rem;
  line-height: 1em;
  width: 20em;
  max-width: 100%;
  height: 2.5em;
  border: 0;
  border-radius: 0.625em;
  padding: 0em 1em;
  align-self: center;

  &::placeholder {
    color: ${Colors.Color7} !important;
  }

  &:read-only {
    background-color: ${Colors.Color9};
    border: 0.1em solid ${Colors.Color9};
  }

  &:disabled {
    background-color: ${Colors.Color37};
    border: 0.1em solid ${Colors.Color37};
  }

  :focus,
  :hover {
    // border: 0.1em solid ${Colors.Color4};
  }
`;
