import styled from "styled-components";
import ThisModalButtonHandler from "../../ThisMainComponent/ThisModalButtonHandler";
import { Colors } from "~/config/Colors";

export const ThisSistemaListaIconButonStyle = styled(ThisModalButtonHandler)`
  position: relative;
  margin-left: 0.5em;
  width: 1.5em;
  height: 1.5em;
  font-size: 1em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25em;
  padding: 0;
  background: ${Colors.Color31};
  color: ${Colors.Color36};
  // ${(props) => `background-color: ${props?.background ? props?.background : Colors.bgColor4};`}
  // ${(props) => `color: ${props?.color ? props?.color : Colors.ftColor3} !important;`}
  border: 0;

  :hover {
    // ${(props) => `background-color: ${props?.hoverBackground ? props?.hoverBackground : Colors.bgColor5};`}
    background: ${Colors.Color32};
  }
`;

export const ThisIconButonStyle = styled.button`
  position: relative;
  margin-left: 0.5em;
  width: 1.7em;
  height: 1.7em;
  font-size: 0.9em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25em;
  padding: 0;
  background: ${Colors.Color31};
  color: ${Colors.Color36};
  // ${(props) => `background-color: ${props?.background ? props?.background : Colors.Color1};`}
  // ${(props) => `color: ${props?.color ? props?.color : Colors.Color7};`}
  border: 0;

  :hover {
    // ${(props) => `background-color: ${props?.hoverBackground ? props?.hoverBackground : Colors.Color8};`}
    background: ${Colors.Color32};
  }
`;
