import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    padding: 0.4em 1.5em;
    margin: 1.4em 0 0 1em;
  }
`;
