import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import { Colors } from "~/config/Colors";

export const Container = styled(SistemaFixedModal)`
  padding: 1em;
  width: 25em;
  height: fit-content;
  box-shadow: 0px 0px 20px rgba(174, 177, 200, 10%) !important;
  z-index: 2;

  .errorHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-select {
      border-radius: 0.4em;
      padding: 0.15em 0.8em 0.15em 0.5em;
      background: ${Colors.Color34};

      p {
        color: ${Colors.Color35};
        font-size: 0.8125em;
        font-weight: 800;
      }
    }
  }

  .errorBody {
    position: relative;
    background: ${Colors.Color34};
    border-radius: 0.5em;
    margin: 1em 0em 0em 0em;
    padding: 1.5em 2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    span.select {
      position: absolute;
      top: 15%;
      left: 0em;
      background: ${Colors.Color35};
      width: 0.25em;
      height: 70%;
      border-radius: 1em;
    }

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${Colors.Color35};

      i {
        font-size: 0.85em;
      }

      p {
        font-size: 0.85em;
        font-weight: 800;
        line-height: normal;
        margin-left: 0.2em;
      }
    }

    .message {
      color: ${Colors.Color7};
      font-size: 0.95em;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
