import React from "react";
import api from "~/services/api";
import Label from "~/componentes/CustomForm/Label";
import Select from "~/componentes/CustomForm/Select";
import Input from "~/componentes/CustomForm/Input";

import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";

function EditContent({ estados, mountedData }) {
  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    ShowConfirmacao(
      async () => {
        let response;

        response = await api.put("/signed/empresa/administracao/organizacao/unidade", {
          ...data,
          id: mountedData.id,
        });

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao editar essa Unidade, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja editar essa Unidade?`,
        successModal: `Unidade atualizada com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">Preencha as informações corretamente, e se atente ao (*) pois são campos obrigatórios</p>
        </div>
        <div className="row">
          <Label title="Nome *" className="col s6 m6 l6">
            <Input name="nome" defaultValue={mountedData?.nome} />
          </Label>
          <Label title="Estado *" className="col s6 m6 l2">
            <Select
              isNumber
              list={{ list: estados, value: "id", label: "nome" }}
              defaultValue={mountedData?.idEstado}
              name="idEstado"
              firstOption
            />
          </Label>
        </div>
      </Materialize>
    </Container >
  );
}

export default EditContent;
