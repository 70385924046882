import styled from "styled-components";
import ColoredButton from "~/componentes/Button/Styles/ColoredButton";
import { Form } from "~/componentes/CustomForm";
import { Colors } from "~/config/Colors";

export const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 4em);
  padding: 5em 6.5em 0em 6.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  font-family: 'Nunito', 'cursive';
  
  .logo-fundo {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .top {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titulo{
      color: ${Colors?.Color3};
      font-size: 1em;
      font-weight: 600;
    }

    .subtitulo{
      color: ${Colors?.Color12};
      font-size: 0.8em;
    }
  }


  @media (max-width: 993px) {
    display: flex;
    flex-direction: column;

    .login-logo {
      width: 14em;
      height: 5em;
      margin-right: 0em !important;
      
    }

    .titulo{
      color: #A7A7A7;
      font-weight: 700;
      font-size: 0.8em;
      margin-left: 230px;
      width: 100%;
      display: block; 
    }
  }

  .col {
    margin-bottom: 1em;
  }
`;

export const Formulario = styled(Form)`
  width: 22em;
  background: #;
  border-radius: 1em;
  position: relative;
  padding: 1.5em;
  height: 100%;
  display: flex;
  flex-direction: column;

  Input{
    background-color: ${Colors?.Color38} !important;

    &:active {
      background-color: ${Colors?.Color38} !important;
    }

    &:hover {
      background-color: ${Colors?.Color38} !important;
    }
  }

  .Label_title_p{
    margin-left: 15px;
  }

  @media (max-width: 993px) {
    .form-login {
      margin: 5px !important
    }
    .row{
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      margin-top: 30px;
    }
    button{
      border-radius: 10px;
    }
  }

  .form-login {
    margin: auto;
  }
  
  h1 {
    text-align: center;
    color: #32495f;
    font-size: 1.4em;
    margin-bottom: 0.5em;
  }
`;

export const ThisColoredButton = styled(ColoredButton)`
  margin: 1em 0em 0em 0em;
  font-size: 0.85em;
  height: 2.2em;
  width: 100%;
  background: #35BFA6 !important; 
  border: #35BFA6 !important; 
  
`;

export const Footer = styled.div`
  width: 100%;
  height: 4em;
  padding: 0 4em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 993px) {
    .text-direitos {
      text-align: center;
    }
  }

  .text-direitos {
    font-family: 'Nunito', 'cursive';
    font-size: 0.8125em;
    color: #9A9A9A;
  }
`;
