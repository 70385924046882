export function loadingToggle() {
  return {
    type: "@loadingModal/loadingToggle",
    // payload: { request },
  };
}

export function loadingActive() {
  return {
    type: "@loadingModal/loadingActive",
    // payload: { request },
  };
}

export function loadingDeactive() {
  return {
    type: "@loadingModal/loadingDeactive",
    // payload: { request },
  };
}