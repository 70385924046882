import React from "react";
import { Input } from "../../styles";

import { Container } from "./styles";

function SenhaInput(props) {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Container>
      <Input {...props} type={showPassword ? "text" : "password"} />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="material-icons"
      >
        {showPassword ? "visibility_off" : "visibility"}
      </button>
    </Container>
  );
}

export default SenhaInput;
