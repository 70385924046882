const INITIAL_STATE = false;

export default function loadingModal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@loadingModal/loadingToggle": {
      return !state;
    }
    case "@loadingModal/loadingActive": {
      return true;
    }
    case "@loadingModal/loadingDeactive": {
      return false;
    }
    default: {
      return state;
    }
  }
}
