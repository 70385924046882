import React from "react";
import { Route, Redirect } from "react-router-dom";
import { store } from "~/store";

export default function CustomRoute({
  component: Componente,
  layout: Layout,
  privado,
  footer,
  ...resto
}) {
  const { logado } = store.getState().auth;

  if (!logado && privado) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...resto}
      render={(props) => {
        return Layout ? (
          <Layout footer={footer}>
            <Componente {...props} />
          </Layout>
        ) : (
          <Componente {...props} />
        )
      }
      }
    />
  );
}
