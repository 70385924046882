import React, { useEffect, useState } from "react";
import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import { LandPage, Container, Main } from './styles';
import { Colors } from "~/config/Colors";
import {
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";
import { useSelector } from "react-redux";
import TableComponent from "./TableComponent";

export default function RelatorioContagem() {
  const [dispositivos, setDispositivos] = useState([]);
  const [centroCustos, setCentroCustos] = useState([]);
  const [dispositivoFiltro, setDispositivoFiltro] = useState("");
  const [ccFiltro, setCCFiltro] = useState([]);
  const [confirmSelects, setConfirmSelects] = useState(false);

  const [data, setData] = useState([]);
  const [loadedData, setLoadedData] = useState(false);
  const { perfil } = useSelector(state => state.auth);

  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);

  useEffect(() => {
    if (confirmSelects) {
      if (dispositivoFiltro == "") {
        setDispositivoFiltro([]);
      }
      setLoadedData(false);
      setConfirmSelects(false);
    }
  }, [confirmSelects]);

  useEffect(() => {
    api.get("/signed/cliente/gestorservico/gerenciamento/dispositivos", { params: { ativo: 1, tipo: "S" } })
      .then(response => setDispositivos(response.data));

    api.get("/signed/cliente/gestorservico/gerenciamento/centroCustos", { params: { ativo: 1 } })
      .then(response => setCentroCustos(response.data));
  }, []);

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Container
            requests={{
              findAll: () =>
                api.get('/signed/cliente/gestorservico/relatorio/contagens', { params: { codigoDevice: dispositivoFiltro, idCentroCusto: ccFiltro } }),
            }}
            setter={setData}
            setLoaded={setLoadedData}
            loaded={loadedData}
            informationPage={{
              title: 'Relatório de Contagem',
              subTitle: "Visualize o histórico de contagem dos dispositivos",
              icone: 'submenu-dispositivo',
            }}
            pageSize={7}
            inputs={[
              { filter: 'data', label: 'Data' },
              { filter: 'hora', label: 'Hora' },
            ]}
            setConfirmSelects={setConfirmSelects}
            selects={[
              {
                list: dispositivos,
                value: "codigoDevice",
                label: "titulo",
                outLabel: "Todos os Dispositivos",
                filter: "codigoDevice",
                onChange: (codigoDevice) => {
                  console.log(codigoDevice);
                  setDispositivoFiltro(codigoDevice);
                }
              },
              {
                list: centroCustos,
                value: "id",
                label: "titulo",
                outLabel: "Todos os Centros de Custos",
                filter: "idCentroCusto",
                onChange: (id) => {
                  setCCFiltro(id);
                }
              },
            ]}
          >
            <TableComponent />
          </Container>
        </div>
      </LandPage>
    </Main>
  );
}
