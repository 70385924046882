import styled from 'styled-components';
import { Colors } from '~/config/Colors';

export const Container = styled.div`
  margin: 0;
  width: 29.1vw;
  font-size: 12px;
  background: #fffff;

  p, pre {
    font-family: 'Nunito', 'cursive';
  }

  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .barra-sup {
    background: #2ED3B5;
    width: 100%;
    height: 13px;
    content: "";
  }

  .header {
    padding: 1em 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      width: 70px;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .titulo {
        font-size: 9px;
        color: #787979;
      }

      .titulo-relatorio {
        font-size: 11px;
        color: #787979;
      }

      .titulo-data {
        font-size: 9px;
        color: #A4A2A2;
      }
    }
  }
  
  .body {
    padding: 0em 2em;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .text {
        .titulo {
          font-size: 11px;
          color: #5269A7;
        }

        .sub-titulo {
          font-size: 9px;
          color: #A4A2A2;
        }
      }
    }

    .mid {
      display: flex;
      flex-direction: row;
      align-items: align-start;
      justify-content: space-between;
      width: 100%;
      border-radius: 6px;
      background: #EFF1F8;
      padding: 0.5em;

      .rigth {
        align-items: flex-end;
      }
      
      .col {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 6px;
        padding: 0.5em 1em;

        .linha {
          display: flex;
          flex-direction: row;
          
          .titulo {
            font-size: 0.5rem !important;
            color: #333233 !important;
          }

          .valor {
            font-size: 0.5rem !important;
            color: #737373 !important;
            margin-left: 0.2em;
          }
        }
      }
      
    }

    .section-1 {
      width: 100%;
      padding: 0em 1em;
      margin-top: 1rem;

      .chart-total {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .chart-tt-circle {
          width: 100%;
          height: 12em;
          border-radius: 1em;
          background: ${Colors.Color16};
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 1em;

          .ct-percent {
            width: fit-content;
            font-size: 1.2em;
            font-weight: 800;
          }

          .divisor {
            content: "";
            width: 2em;
            height: 0.2em;
            border-radius: 1em;
            margin: 0em 0em 0.2em 0;
          }

          .dv-colo1 {
            background: #2ed3b5;
          }

          .dv-colo2 {
            background: #B6DF53;
          }

          .dv-colo3 {
            background: #FFD147;
          }

          .dv-colo4 {
            background: #FFAB48;
          }

          .dv-colo5 {
            background: #FF5780;
          }

          .ct-title {
            width: fit-content;
            font-size: 0.6rem;
            font-weight: 800;
          }
            
          .ct-quant {
            width: fit-content;
            font-size: 0.9em;
            font-weight: 800;
            color: ${Colors.Color7};
          }
        }
      }

      .title {
        width: fit-content;
        font-size: 0.7em;
        font-weight: 800;
        color: #999999;
        margin-bottom: 1em;
      }

      .list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .item-pergunta {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 0.6rem;

          img {
            width: 1.1em;
          }

          .titulo-item {
            width: fit-content;
            font-size: 0.6em;
            font-weight: 600;
            color: #999999;
          }

          .progress {
            width: 100%;
            margin-left: 0.5em;
            border-radius: 1em;
            background: ${localStorage.getItem("tema") == "E" ? "#15141a" : "#f1f1f1"};
            height: 0.6em;
          }

          .align-right {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
          }

          .align-left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }

          .align-center {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }

          .col-1 {
            width: 5rem;
          }
          
          .col-1-1 {
            width: 3rem;
          }
          
          .col-2 {
            width: 10rem;
          }

          .col-3 {
            width: 15rem;
          }

          .col-4 {
            width: 20rem;
          }
          
          .col-5 {
            width: 25rem;
          }

          .col-6 {
            width: 30rem;
          }
        }
      }

    }

  }
`;

export const ThisProgress = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  content: "";
  width: ${props => props?.width};
  height: 0.6em;
  border-radius: 1em;
  background: ${props => props?.backgound};
`;