import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { Label, Input, Select } from "~/componentes/CustomForm";

import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function CreateEditContent({ mountedData }) {
  const [ativo, setAtivo] = useState(!mountedData ? "true" : mountedData?.ativo ? "true" : "false");
  const [centroCustos, setCentroCustos] = useState([]);

  useEffect(() => {
    api
      .get('/signed/cliente/gestorservico/gerenciamento/centroCustos')
      .then(response => setCentroCustos(response.data));
  }, []);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let thisData = {
          ...data,
          ...(mountedData?.id ? { id: mountedData?.id } : {}),
          ativo: ativo === "true" ? true : false,
        }
        let response;
        if (mountedData?.id)
          response = await api.put("/signed/cliente/pesquisa/gerenciamento/pesquisa", thisData);
        else
          response = await api.post("/signed/cliente/pesquisa/gerenciamento/pesquisa", thisData);

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${mountedData?.id ? "atualizar" : "cadastrar"} essa Pesquisa, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${mountedData?.id ? "atualizar" : "cadastrar"} essa Pesquisa?`,
        successModal: `Pesquisa ${mountedData?.id ? "atualizada" : "cadastrada"} com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">Preencha as informações corretamente, e se atente ao (*) pois são campos obrigatórios</p>
        </div>
        <div className="row">
          <Label title="Título *" className="col s7 m7 l7">
            <Input name="titulo" required="true" defaultValue={mountedData?.titulo} />
          </Label>
          <Label title="Validade *" className="col s3 m3 l3">
            <Input type="date" name="validade" required="true" defaultValue={mountedData?.validade} />
          </Label>
          <Label title="Ativo *" className="col s2 m2 l2">
            <Select
              isString
              list={{ list: [{ id: "true", titulo: "Sim" }, { id: "false", titulo: "Não" }], value: "id", label: "titulo" }}
              name="ativo"
              defaultValue={mountedData?.ativo?.toString()}
              onChange={(e) => {
                const v = e.currentTarget.value || "true";
                setAtivo(v);
              }}
            />
          </Label>
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateEditContent;
