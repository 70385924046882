import React from "react";
import { useDispatch } from "react-redux";

import history from "~/services/history";

import { toggleDisableButton } from "~/store/modules/disableButtons/actions";
import { signInRequest } from "~/store/modules/auth/actions";

import { Body, Container, Formulario, ThisColoredButton } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import ThisFormElements from "./ThisFormElements";
import LogoManyVert from "~/assets/logos/LogoManyFlowsVertical.svg";
import LogoManyFundo from "~/assets/logos/LogoManyLogin.svg";
import LogoManyFundoDark from "~/assets/logos/LogoManyLoginE.svg";

export default function Entrar() {
  const dispatch = useDispatch();
  const [params, setParams] = React.useState();

  const location = history?.location;

  React.useEffect(() => {
    if (!params && location?.search) {
      try {
        const handledParams = Object.fromEntries(new URLSearchParams(location.search));
        const { senhaToken, email } = handledParams || {};

        if (senhaToken && email) {
          setParams({ senhaToken, email });
        } else {
          throw new Error();
        }
      } catch {
        setParams({ failed: true });
      }
    }
  }, [location, params]);

  async function handleLogin({ email, senha }) {
    dispatch(toggleDisableButton());
    try {
      dispatch(signInRequest(email, senha));
      dispatch(toggleDisableButton());
    } catch {
      dispatch(toggleDisableButton());
    }
  }

  return (
    <>
      <Body>
        <Container>
          <img className={"logo-fundo"} src={localStorage.getItem("tema") == "E" ? LogoManyFundoDark : LogoManyFundo } alt="LogoManyFundo" />
          <img className={"login-logo"} src={LogoManyVert} alt="LogoManyVert" />
          <div className="top">
            <p className="titulo">Login</p>
            <p className="subtitulo">Bem-vindo</p>
          </div>
          <Formulario className="login-form" onSubmit={handleLogin}>
            <div className="form-login">
              <ThisFormElements />

              <Materialize>
                <div className="row">
                  <div className="botao col s12 m12 l12">
                    <ThisColoredButton type="submit" placeholder="example@erpworks.com.br" blueLayout>
                      Entrar
                    </ThisColoredButton>
                  </div>
                </div>
              </Materialize>
            </div>
          </Formulario>
        </Container>
        {/* <Footer>
          <p className="text-direitos">Erpworks {new Date().getFullYear()} - Todos os direitos reservados</p>
        </Footer> */}
      </Body>
    </>
  );
}

