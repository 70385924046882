import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { useSelector } from "react-redux";
import EditContent from "./EditContent";
import CreateContent from "./CreateContent";
import { LandPage, Container, Content, Main } from './styles';
import TableComponent from "./TableComponent";
import {
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";

export default function Unidades() {
  const [estados, setEstados] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [loadedData, setLoadedData] = useState(false);
  const { perfil } = useSelector(state => state.auth);

  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);

  useEffect(() => {
    api.get("/signed/geral/estados").then((response) => setEstados(response.data));
  }, []);

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Container
            requests={{
              findAll: () =>
                api.get('/signed/empresa/administracao/organizacao/unidades', {
                  params: { paranoid: false },
                }),
              findOne: thisData =>
                api.get('/signed/empresa/administracao/organizacao/unidade', {
                  params: { id: thisData.id },
                }),
              deleteOne: thisData =>
                api.delete(
                  '/signed/empresa/administracao/organizacao/unidade',
                  { data: { id: thisData.id } },
                ),
              restoreOne: thisData =>
                api.post(
                  '/signed/empresa/administracao/organizacao/unidade/restore',
                  { id: thisData.id },
                ),
            }}
            setLoaded={setLoadedData}
            loaded={loadedData}
            pageSize={6}
            informationPage={{
              title: 'Unidades',
              subTitle: 'Cadastre as unidades por estado e organize suas filiais',
              icone: 'submenu-unidades',
            }}
          >
            <TableComponent {...{ estados }} />

            <SLEditModal title="Atualizar Unidade" setter={setMountedData}>
              {mountedData && estados && (
                <EditContent {...{ estados, mountedData }} />
              )}
            </SLEditModal>

            <SLCreateModal title="Nova Unidade">
              {estados && <CreateContent {...{ estados }} />}
            </SLCreateModal>
          </Container>
        </div>
      </LandPage>
    </Main>
  );
}
