import FindKeyValue from "~/utils/FindKeyValue";
import { IsObject } from "~/utils/CheckDataType";

const stateExampe = {
  data: [],
  filteredSelects: [],
  filteredData: [],
  selects: {},
  inputs: {},
  singleSelect: false,
  singleInput: false,
};

function filterData(data, filterObject, equal) {
  let newData = [];
  if (Array.isArray(data)) {
    if (IsObject(filterObject)) {
      const thisFilter = Object.entries(filterObject).filter(
        ([, value]) => value && value !== ""
      );

      function findValue([key, ...rest], thisObject) {
        let checkObject = thisObject ? thisObject : {};
        if (rest.length > 0) {
          if (Array.isArray(checkObject[key])) {
            let i = 0;
            let values = [];
            for (const item of checkObject[key]) {
              const exist = findValue(rest, item);
              if (exist) {
                values.push(exist);
              }
              i++;
            }
            return values;
          } else {
            return findValue(rest, checkObject[key]);
          }
        } else {
          return checkObject[key];
        }
      }

      const verificaExist = (valid, itemCheck) => {
        if (Array.isArray(valid) && valid?.length > 0) {
          for (const item of valid) {
            const exist = verificaExist(item, itemCheck);
            if (exist) {
              return exist;
            }
          }
        } else {
          return valid?.toString()?.toLowerCase() == itemCheck?.toString()?.toLowerCase();
        }
      }

      if (Array.isArray(thisFilter) && thisFilter.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          const notInclude = thisFilter.some(([key, value]) => {
            let results = FindKeyValue(key, element);
            
            if (Array.isArray(results)) {
              return verificaExist(results, value) ? false : true;
            } else {
              if (equal) {
                return (!verificaExist(results, value));
              } else {
                return !FindKeyValue(key, element)
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(value?.toString()?.toLowerCase());
              }
            }
          });

          !notInclude && newData.push(element);
        }
      } else {
        newData = data;
      }
    } else {
      newData = data;
    }
  }

  return newData;
}

export default function FilterListReducer(state = stateExampe, { type, payload }) {
  switch (type) {
    case "FilterListStoreLoadData": {
      const { data } = payload || {};
      const { selects, inputs } = state || {};

      const filteredSelects = filterData(data, selects, true);

      const filteredData = filterData(filteredSelects, inputs, false);

      return { ...state, data, filteredSelects, filteredData };
    }
    case "FilterListStoreHandleSelect": {
      const { key, value } = payload || {};
      const { data, inputs, singleSelect } = state || {};

      const selects =
        singleSelect || !state?.selects
          ? { [key]: value }
          : { ...state.selects, [key]: value };

      const filteredSelects = filterData(data, selects, true);

      const filteredData = filterData(filteredSelects, inputs, false);

      return { ...state, selects, filteredSelects, filteredData };
    }
    case "FilterListStoreUpdateSelects": {
      const { selects } = payload || {};
      const { data, inputs } = state || {};

      const filteredSelects = filterData(data, selects, true);

      const filteredData = filterData(filteredSelects, inputs, false);

      return { ...state, selects, filteredSelects, filteredData };
    }
    case "FilterListStoreHandleInput": {
      const { key, value } = payload || {};
      const { filteredSelects, singleInput } = state || {};

      const inputs =
        singleInput || !state?.inputs
          ? { [key]: value }
          : { ...state.inputs, [key]: value };

      const filteredData = filterData(filteredSelects, inputs, false);

      return { ...state, inputs, filteredData };
    }
    default:
      return state;
  }
}
