import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  input {
    padding-left: 2.5em;
  }

  i {
    position: absolute;
    left: 0.4em;
    top: 0.3em;
    color: #fff;
  }
`;
