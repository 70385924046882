import React, { useCallback } from "react";

import { Container } from "./styles";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  acceptConfirmacaoModalToggle,
  responseModalToggle,
  confirmacaoModalToggle,
} from "~/store/modules/confirmacaoModal/actions";
import loading from "~/assets/icones/loading.svg";
import { errorToggle } from "~/store/modules/errorModal/actions";
import { succesToggle } from "~/store/modules/successModal/actions";
import ColoredButton from "~/componentes/Button/Styles/ColoredButton";

export default function ConfirmacaoInterna(props) {
  const { confirmado, request, message, errorModal, successModal } = useSelector(
    (state) => state.confirmacaoModal
  );
  const dispatch = useDispatch();
  const toggleModal = useCallback(
    () => !confirmado && dispatch(confirmacaoModalToggle()),
    [confirmado, dispatch]
  );

  useEffect(() => {
    async function handleRequest() {
      try {
        const response = await request();

        if (successModal && response?.status === 200) {
          dispatch(succesToggle(successModal));
        }
        return dispatch(responseModalToggle(response));
      } catch (err) {
        dispatch(responseModalToggle(err?.response || err));
        if (errorModal) {
          let thisErrorMessage =
            typeof errorModal === "string" ? errorModal : err?.response || err;

          dispatch(errorToggle(thisErrorMessage, errorModal?.defaultMessage));
        }
      }
    }

    confirmado && request && handleRequest();
  }, [confirmado, dispatch, errorModal, request, successModal]);

  return (
    <Container fundoSombra={true} checkRedux={false} outClick={toggleModal}>
      <div className="confirmacaoHeader">
        <div className="title-select">
          <p>Alerta de Confirmação</p>
        </div>
      </div>
      <div className="confirmacaoBody">
        <span className="select"></span>
        <div className="title">
          <i className="material-icons">warning_amber</i>
          <p>Confirmação</p>
        </div>
        <p className="message">{message || "Você realmente deseja fazer isso?"}</p>
      </div>
      <div className="confirmacaoBotoes">
        <ColoredButton small onClick={toggleModal} cancelLayout>
          Cancelar
        </ColoredButton>
        <ColoredButton
          small
          onClick={() => dispatch(acceptConfirmacaoModalToggle())}
          blueLayout
        >
          {confirmado ? (
            <img className="loadingIcon" src={loading} alt="loading" />
          ) : (
            "Confirmar"
          )}
        </ColoredButton>
      </div>
    </Container>
  );
}
