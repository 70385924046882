import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const InformationPage = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => {
    if (props.noMargin)
      return `margin-botton: 0;`;
    else
      return `margin-bottom: 1em;`;
  }}

  .top-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => props?.topMain ? "1em" : "0"};
    background: ${(props) => props?.topMain ? Colors.Color2 : Colors.Color2};
    border-radius: 1em;
    padding: 1em 0em 0.5em 0em;

    .lateral-e {
      display: flex;
      flex-direction: column;

      .divTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .div-icone {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          background: ${Colors.Color2};
          border-radius: 0.4em;
          padding: 0.15em 0.8em 0.15em 0.3em;

          img {
            width: 1.2em;
          }

          .title {
            font-size: 0.9em;
            font-weight: 700;
            color: ${Colors?.Color7};
            margin-left: 0.5em;
          }
        }

        .subTitle {
          margin-top: 0.5em;
          font-size: 0.7125em;
          font-weight: 500;
          color: ${Colors?.Color12};
        }
      }
    }

    .lateral-d {
      .ThisTopComponent_buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        .ThisTopComponent_buttons_firstButton {
          margin-right: 1em;
        }

        .btn_circle {
          height: 1.9rem;
          width: 6.5rem;
          padding: 0em;
        }
      }
    }

    .lateral-d1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .pesquisa {
        margin-right: 1em;

        input {
          width: 18em !important;
          height: 2.9em !important;
          background: ${Colors.Color8};
        }

        select {
          margin-right: 0.8em;
          max-width: 9em;
          background: ${Colors.Color2};
        }
          
        .input-select {
          width: 11em !important;
          margin-right: 0.8em;
          background: ${Colors.Color2};
        }

      }

      .filtros {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        select {
          margin-right: 0.8em;
          max-width: 9em;
          background: ${Colors.Color2};
        }

        .input-select {
          width: 11em !important;
          margin-right: 0.8em;
          background: ${Colors.Color2};
        }

        .btn-filtro {
          height: 2.1em;
          width: 2.2em;
          padding: 0em;
          font-size: 0.75em;
          font-weight: 600;
          background: ${Colors.Color13};
          border: 0.25em solid ${Colors.Color13};
          border-radius: 0.4em;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 1em;
          }
        }
      }

      .btn-list {
        height: 2.1em;
        width: 2.2em;
        padding: 0em;
        font-size: 0.88em;
        font-weight: 600;
        background: ${Colors.Color6};
        border: 0.25em solid ${Colors.Color6};
        border-radius: 0.4em;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.6em;

        img {
          width: 1.5em;
        }
      }
    }
  }

  .top-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .lateral-e {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    
    .lateral-d {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;

      .pesquisa {
        margin-right: 1em;
        margin-bottom: 0.8em;
      }

      .filtros {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        select {
          margin-right: 0.8em;
          max-width: 9em;
        }

        .input-select {
          width: 11em !important;
          margin-right: 0.8em;
          background: ${Colors.Color2};
        }

        .btn-filtro {
          height: 2.1em;
          width: 2.2em;
          padding: 0em;
          font-size: 0.88em;
          font-weight: 600;
          background: ${Colors.Color13};
          border: 0.25em solid ${Colors.Color13};
          border-radius: 0.4em;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 1em;
          }
        }
      }
    }
  }
`;