import React, { useState } from "react";

import { Container } from "./styles";
import ColoredButton from "~/componentes/Button/Styles/ColoredButton";
import sistemaListStore, {
  sistemaListShowModal,
} from "../../../ThisMainComponent/thisStore";
import { IsFunction } from "~/utils/CheckDataType";
import { useEffect } from "react";
import useMenuHandler from "~/pages/Sistema/sistemaHooks/useMenuHandler";
import { Colors } from "~/config/Colors";

function ThisModalFooter({
  closeCondition = true,
  children,
  closeModalSetter,
  cancelButton,
  submitButton,
  ...rest
}) {
  const { dispatch } = React.useContext(sistemaListStore);

  useEffect(() => {
    IsFunction(closeModalSetter) &&
      closeModalSetter(() => dispatch(sistemaListShowModal(false)));
  }, [closeModalSetter, dispatch]);

  return (
    <Container {...rest}>
      {cancelButton !== false && (
        <ColoredButton
          cancelLayout
          small
          type="button"
          {...cancelButton}
          onClick={() => {
            if (cancelButton?.onClick) {
              cancelButton.onClick(() => dispatch(sistemaListShowModal(false)));
            } else {
              closeCondition && dispatch(sistemaListShowModal(false));
            }
          }}
        >
          {cancelButton?.text || "Cancelar"}
        </ColoredButton>
      )}
      {children}
      {submitButton !== false && (
        <ColoredButton
          type="submit"
          small
          {...{
            background: Colors?.Color13,
            color: Colors?.Color2,
            hoverBackground: Colors?.Color13 + "e3",
          }}
          {...submitButton}
        >
          {submitButton?.text || "Salvar"}
        </ColoredButton>
      )
      }
    </Container >
  );
}

export default ThisModalFooter;
