const INITIAL_STATE = false;

export default function disableButtons(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@disableButtons/TOGGLE": {
      return !state;
    }
    case "@confirmacaoModal/acceptConfirmacaoModalToggle": {
      return true;
    }
    case "@confirmacaoModal/responseModalToggle": {
      return false;
    }
    default: {
      return state;
    }
  }
}
