import React, { useEffect, useState } from "react";
import sistemaListStore, { sistemaListShowModal } from "../../thisStore";
import { IsBoolean, IsFunction } from "~/utils/CheckDataType";
import { useMemo } from "react";
import { SLModalHeader, SLModalFooter } from "../../..";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import useMenuHandler from "~/pages/Sistema/sistemaHooks/useMenuHandler";
import { Colors } from "~/config/Colors";

function ThisModalsWrapper({
  children,
  outClick,
  customModal,
  reloadSetter,
  footer,
  closeCondition = true,
  menu = false,
  loadCondition,
  data,
  title,
  contentMount,
  contentUnmount,
  setter: setterProps,
  ...rest
}) {
  const { state, dispatch } = React.useContext(sistemaListStore);
  const { entityData, options, reloadData } = state || {};
  const [mount] = useState(() => contentMount);
  const [unmount] = useState(() => contentUnmount);
  const [setter] = useState(() => setterProps);
  const [loaded, setLoaded] = useState(false);
  const [showLateralMenu, setShowLateralMenu] = useState();
  const { currentMenu, currentSubMenu } = useMenuHandler({ setShowLateralMenu });

  useEffect(() => {
    IsFunction(reloadData) && reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    IsFunction(reloadSetter) && IsFunction(reloadData) && reloadSetter(reloadData);
  }, [reloadData, reloadSetter]);

  useEffect(() => {
    if (!loaded) {
      if (IsBoolean(loadCondition)) {
        setLoaded(loadCondition);
      } else if (Array.isArray(loadCondition)) {
        setLoaded(loadCondition.every((e) => (e ? true : false)));
      } else if (IsFunction(loadCondition)) {
        setLoaded(loadCondition());
      } else {
        setLoaded(true);
      }
    }
  }, [loadCondition, loaded]);

  const thisData = useMemo(() => data || entityData, [data, entityData]);

  useEffect(() => {
    IsFunction(mount) && mount(thisData);
  }, [thisData, mount]);

  useEffect(() => {
    const checkFunction = IsFunction(setter);

    checkFunction && setter(thisData);

    return () => checkFunction && setter();
  }, [thisData, setter]);

  useEffect(() => {
    return () => {
      IsFunction(unmount) && unmount();
    };
  }, [unmount]);

  return currentMenu ? (
    <SistemaFixedModal
      outClick={() => {
        if (outClick) {
          outClick((modalType) => dispatch(sistemaListShowModal(modalType)));
        } else {
          closeCondition && dispatch(sistemaListShowModal(false));
        }
      }}
      {...rest}
    >
      {loaded && title && (
        <SLModalHeader
          title={title}
          data={data}
          menu={menu}
          icon={menu ? currentMenu?.icone : currentSubMenu?.icone}
          {...options}
          background={Colors?.Color18}
          color={Colors?.Color4}
          hoverBackground={Colors?.Color4 + "33"}
        />
      )}
      {loaded && children}
      {loaded && footer && (<SLModalFooter {...footer} />)}
    </SistemaFixedModal>
  ) : (<></>);
}

export default ThisModalsWrapper;
