import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 1em;

  .select-title {
    ${(props) => `background: ${props?.background ? props?.background : Colors.Color2};`}
    border-radius: 0.5em;
    padding: 0.3em 0.8em 0.3em 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Colors.Color2} !important;

    h3 {
      font-size: 1rem;
      font-weight: 800;
      ${(props) => `color: ${props?.color ? props?.color : Colors.Color4};`}
      line-height: 1em;
    }

    img {
      width: 1.3125em;
      margin-right: 0.4em;
    }
  }

  .SistemaList_ModalHeader_Actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .SistemaList_ModalHeader_closeButton {
    //position: absolute;
    // top: 0.35em;
    //right: 0;
  }
`;
