import { createGlobalStyle } from "styled-components";
import { Colors } from "~/config/Colors";

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: none;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
  }

  html, body, #root {
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    background: ${Colors?.Color39};
  }

  body, button, input, a, textarea, li, select, option, label, pre {
    font-family: 'Nunito', 'cursive';
    color: #333;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    font-size: 2.5vh;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #ffa50000;
    cursor: pointer;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${localStorage.getItem("tema") == "E" ? Colors.Color27 : '#e4e4e4'};
    border-radius: 20px;
    border: 3px solid #ffa50000;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #666;
  }

  button {
    cursor: pointer;
  }

  .material-icons {
    font-size: 1.5em;
  }
  
  .bolinha {
    position: absolute;
    background: ${Colors.bg_color_5};
    width: 3.6em;
    height: 3.6em;
    border-radius: 3em;
  }
  
  .barra-selection-v {
    width: 0.2em;
    height: 1em;
    background: ${Colors.bgColor1};
    margin-right: 0.4em;
    border-radius: 1em;
  }

  .barra-selection-h {
    width: 3em;
    height: 0.2em;
    background: ${Colors.bgColor1};
    margin-right: 0.4em;
    border-radius: 1em;
  }
  
  .padding-form {
    padding: 1em;
  }
`;
