import React, { useEffect, useState } from "react";

import { PageButtonIcon, PageButtonNumber, Pages } from "./styles";

import UserDevice from "~/componentes/UserDevice";
import { useLayoutEffect } from "react";

/**
 * @param {Object} props
 * @param {Array} props.pageData Array contendo os dados da página selecionada.
 * @param {Number} props.page O número da página selecionada.
 * @param {Number} props.maxPage O número máximo de páginas.
 */
// eslint-disable-next-line no-unused-vars
function getInfoParam({ pageData, page, maxPage }) { }

/**
 * @param {Object} props
 * @param {Array} props.data Array de dados
 * @param {Number} props.assistPage Move para a página desejada, apenas quando o valor for alterado.
 * @param {Number} props.size Padrão 8. Quantidade de dados por página
 * @param {getInfoParam} props.getInfo Recebe como parâmetro um objeto. Função chamada ao sofrer alteração no data, size ou página.
 */
function Pagination({ data, children, assistPage, size = 8, getInfo }) {
  const [maxPage, setMaxPage] = useState(15);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [holdingButton, setHoldingButton] = useState(0);
  const [timeUp, setTimeUp] = useState(0);
  const { desktop, rotatedTablet } = UserDevice();

  // useEffect(() => {
  //   if (assistPage > 0) {
  //     setPage(assistPage > maxPage ? maxPage : assistPage);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [assistPage]);

  useEffect(() => {
    setPage((prev) => (prev > maxPage ? maxPage : prev));
  }, [maxPage]);

  useLayoutEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const pageFirstData = page > 1 ? size * (page - 1) : 0;

      const sizeDiffence = data.length - pageFirstData;
      const loopSize = pageFirstData + (sizeDiffence > size ? size : sizeDiffence);

      const thisPageData = [];

      for (let i = pageFirstData; i < loopSize; i++) {
        thisPageData.push(data[i]);
      }

      const newValue =
        Array.isArray(data) && data.length > 0 ? Math.ceil(data.length / size) : 1;

      setMaxPage((prev) => (newValue !== prev ? newValue : prev));

      setPageData(thisPageData);
    } else {
      setMaxPage(1);

      setPageData((prev) => (Array.isArray(prev) && prev.length === 0 ? prev : []));
    }
  }, [page, data, size]);

  useEffect(() => {
    if (getInfo) {
      const thisInfos = { pageData, page, maxPage };

      getInfo(thisInfos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPage, page, pageData]);

  useEffect(() => {
    let firstPage = page > 4 ? page - 4 : 1;
    let lastPage = firstPage + 4 > maxPage ? maxPage : firstPage + 4;

    let thisResult = [];
    for (let i = firstPage; i <= lastPage; i++) {
      thisResult.push(i);
    }
  }, [maxPage, page]);

  useEffect(() => {
    let hold = false;
    if (!hold) {
      window.addEventListener("mouseup", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      window.addEventListener("touchend", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      window.addEventListener("blur", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      if (holdingButton > 0) {
        hold = setInterval(() => {
          if (timeUp === 0) {
            setTimeUp(700);
          } else if (timeUp > 200) {
            setTimeUp(timeUp - 100);
          }
          page < maxPage && setPage(page + 1);
        }, timeUp);
      } else if (holdingButton < 0) {
        hold = setInterval(() => {
          if (timeUp === 0) {
            setTimeUp(700);
          } else if (timeUp > 200) {
            setTimeUp(timeUp - 100);
          }
          page > 1 && setPage(page - 1);
        }, timeUp);
      }
    }
    return () => {
      clearInterval(hold);
      window.removeEventListener("mouseup", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      window.removeEventListener("touchend", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      window.removeEventListener("blur", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
    };
  }, [holdingButton, maxPage, page, timeUp]);

  function addPagination(pag, maxPag) {
    // Cria o html do item da paginação
    const identificaAtivo = (paginaAtual, paginaAdd, points) => {
      if (!points) {
        return <PageButtonNumber
          type="button"
          onClick={() => setPage(paginaAdd)}
          key={paginaAdd}
          ativo={paginaAtual === paginaAdd ? 1 : undefined}
          numberdigits={paginaAdd}
        >
          <span />
          <p>{paginaAdd}</p>
        </PageButtonNumber>;
      } else {
        return <PageButtonNumber type="button">...</PageButtonNumber>;
      }
    };

    let itens = [];
    if (pag > 0) {
      if (maxPag === 0) {
        // html += identificaAtivo(0, 1);
      } else if (pag <= 3) {
        if (maxPag > 9) {
          itens.push(identificaAtivo(pag, 1));
          itens.push(identificaAtivo(pag, 2));
          itens.push(identificaAtivo(pag, 3));
          itens.push(identificaAtivo(pag, 4));
          itens.push(identificaAtivo(0, 0, true));
          itens.push(identificaAtivo(pag, maxPag - 3));
          itens.push(identificaAtivo(pag, maxPag - 2));
          itens.push(identificaAtivo(pag, maxPag - 1));
          itens.push(identificaAtivo(pag, maxPag));
        } else {
          for (var count = 1; count <= maxPag; count++) {
            itens.push(identificaAtivo(pag, count));
          }
        }
      } else if (pag <= maxPag - 3) {
        itens.push(identificaAtivo(pag, 1));
        itens.push(identificaAtivo(0, 0, true));
        itens.push(identificaAtivo(pag, pag - 2));
        itens.push(identificaAtivo(pag, pag - 1));
        itens.push(identificaAtivo(pag, pag));
        itens.push(identificaAtivo(pag, pag + 1));
        itens.push(identificaAtivo(pag, pag + 2));
        itens.push(identificaAtivo(0, 0, true));
        itens.push(identificaAtivo(pag, maxPag));
      } else if (pag > maxPag - 3) {
        if (maxPag > 9) {
          itens.push(identificaAtivo(pag, 1));
          itens.push(identificaAtivo(pag, 2));
          itens.push(identificaAtivo(pag, 3));
          itens.push(identificaAtivo(pag, 4));
          itens.push(identificaAtivo(0, 0, true));
          itens.push(identificaAtivo(pag, maxPag - 3));
          itens.push(identificaAtivo(pag, maxPag - 2));
          itens.push(identificaAtivo(pag, maxPag - 1));
          itens.push(identificaAtivo(pag, maxPag));
        } else {
          for (let count = 1; count <= maxPag; count++) {
            itens.push(identificaAtivo(pag, count));
          }
        }
      } else {
        itens.push(identificaAtivo(0, 1));
      }
    }
    return itens;
  }

  return children ? (
    children({ page, maxPage, setPage, setHoldingButton })
  ) : (
    <Pages
      summarydigits={String(page)?.length + String(maxPage)?.length}
      mobile={!desktop && !rotatedTablet}
    >
      {/* {(desktop || rotatedTablet) && (
        <p className="Pagination_p_pageSummary">
          {page} de {maxPage} página
        </p>
      )} */}
      <div className="Pagination_controls">
        <PageButtonIcon
          type="button"
          onClick={() => page > 1 ? setPage(page - 1) : setPage(page)}
          className="material-icons"
        >
          navigate_before
        </PageButtonIcon>
        <div className="pag-number">
          <PageButtonNumber
            type="button"
            onClick={() => setPage(page)}
            key={page}
            ativo={1}
            numberdigits={page}
          >
            <span />
            <p>0{page}</p>
          </PageButtonNumber>
          {/* {addPagination(page, maxPage)} */}
        </div>
        <PageButtonIcon
          type="button"
          onClick={() => { page < maxPage ? setPage(page + 1) : setPage(page) }}
          className=" material-icons"
        >
          navigate_next
        </PageButtonIcon>
      </div>
      <p className="Pagination_p_pageSummary">
        Página {page} de {maxPage} página(s)
      </p>
      {/* 
      <PageButtonIcon
        type="button"
        onClick={() => page > 1 && setPage(1)}
        className="material-icons"
      >
        first_page
      </PageButtonIcon>
      
      {desktop || rotatedTablet ? (
        buttonPageNumber?.map((e) => (
          <PageButtonNumber
            type="button"
            numberdigits={String(buttonPageNumber[buttonPageNumber.length - 1]).length}
            key={e}
            ativo={e === page ? 1 : undefined}
            onClick={() => setPage(e)}
          >
            {e}
          </PageButtonNumber>
        ))
      ) : (
        <p className="Pagination_p_pageSummary">
          {page} de {maxPage}
        </p>
      )}
      
      <PageButtonIcon
        type="button"
        onClick={() => page < maxPage && setPage(maxPage)}
        className="material-icons"
      >
        last_page
      </PageButtonIcon> */}
    </Pages>
  );
}

export default Pagination;
