import { createContext } from "react";

const sistemaStore = createContext({
  moduloPath: "",
  menuPath: "",
  subMenuPath: "",
  props: "",
  setComponentPath: ({ moduloPath, menuPath, subMenuPath, props }) => { },
});

export default sistemaStore;
