import styled from "styled-components";
import { Materialize } from "~/styles/materializeGrid";
import { Colors } from "~/config/Colors";

export const Container = styled(Materialize)`
  width: 100%;
  max-width: 100%;
`;

export const FlexDiv = styled.div`
  display: flex;

  .wrapper-input {
    margin: 0 0.25em;
    width: 100%;
  }

  .btn-search {
    display: flex;

    align-items: center;

    justify-content: center;
    width: 3.325em;
    height: 2.5em;
    margin-left: 1em;
    border: none;
    outline: none;
    padding: 0 0.5em;
    background-color: ${Colors.bgButton1};
    color: ${Colors.ftColor4};
    border-radius: 0.5em;
  }

  .btn-search:active {
    transform: translateY(0.3em);

    box-shadow: none;
  }
`;
