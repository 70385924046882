import { formHandleData } from "../formStore";
import formCreateNamePath from "./formCreateNamePath";
import formDeleteNamePath from "./formDeleteNamePath";
import { IsFunction, IsObject } from "~/utils/CheckDataType";
import PassByValue from "~/utils/PassByValue";
import FindKeyValue from "~/utils/FindKeyValue";

const checkObject = (data) =>
  Object.prototype.toString.call(data) === "[object Object]";

export default function formHandleOnChange(passedValue, props, dispatch) {
  let thisValueProp = PassByValue(passedValue);

  if (thisValueProp === "") {
    thisValueProp = undefined;
  }

  const {
    name: formName,
    list: listProps,
    changeValue,
    isDate,
    notForm,
    isBoolean,
    isNumber,
    isString,
    handleFormData,
    allowNull,
    setter,
    setList,
  } = props || {};

  const removeValue =
    !allowNull &&
    (thisValueProp === "" ||
      thisValueProp === undefined ||
      thisValueProp === null);

  if (isNumber) {
    thisValueProp = thisValueProp ? Number(thisValueProp) : thisValueProp;
  } else if (isBoolean) {
    if (
      thisValueProp === "true" ||
      thisValueProp === true ||
      thisValueProp === 1 ||
      thisValueProp === "1"
    ) {
      thisValueProp = true;
    } else {
      thisValueProp = false;
    }
  } else if (isDate) {
    thisValueProp = thisValueProp ? new Date(thisValueProp) : thisValueProp;
  } else if (isString) {
    thisValueProp = thisValueProp ? String(thisValueProp) : thisValueProp;
  }

  if (changeValue) {
    thisValueProp = changeValue(thisValueProp);
  }

  if (formName && dispatch && !notForm) {
    let formValue = thisValueProp;
    if (IsFunction(handleFormData)) {
      formValue = handleFormData(thisValueProp);
    }
    dispatch(formHandleData(formValue, formName, removeValue));
  }

  if (setList && listProps) {
    const { list, value: listValue } = IsObject(listProps)
      ? listProps
      : { list: listProps };

    const listName = IsObject(setList) && setList.name;
    const listSetter = setList?.setter || setList;

    let newValue = list?.find((e) => {
      if (IsObject(e) && typeof listValue === "string") {
        const thisKeyValue = FindKeyValue(listValue, e);

        if (thisKeyValue && String(thisKeyValue) === String(thisValueProp)) {
          return true;
        }
      } else if (String(e) === String(thisValueProp)) {
        return true;
      }
      return false;
    });

    if (listName) {
      listSetter((prev) => {
        let newState = prev;

        if (
          !allowNull &&
          (newValue === "" || newValue === undefined || newValue === null)
        ) {
          formDeleteNamePath(listName, newState);
        } else {
          newState = formCreateNamePath(listName, prev, newValue);
        }

        return { ...newState };
      });
    } else {
      listSetter(newValue);
    }
  }

  if (setter) {
    const valueName = checkObject(setter) && setter.name;
    const valueSetter = setter.setter || setter;
    if (valueName) {
      valueSetter((prev) => {
        let newState = prev;

        if (removeValue) {
          formDeleteNamePath(valueName, newState);
        } else {
          newState = formCreateNamePath(valueName, prev, thisValueProp);
        }

        return { ...newState };
      });
    } else {
      valueSetter(thisValueProp);
    }
  }
}
