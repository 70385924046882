export default function formCreateNamePath(name, object, value, isArray = false) {
  if (name) {
    object = Object.prototype.toString.call(object) === "[object Object]" ? object : {};

    let path = name.split(".");

    function handleValue(thisObj, key) {
      const thisValue = Array.isArray(value) ? [...value] : value;
      if (isArray) {
        const prevArrayValue =
          thisObj?.[key] && Array.isArray(thisObj[key]) ? thisObj[key] : [];
        if (!prevArrayValue.find((e) => e === value)) {
          return [...prevArrayValue, thisValue];
        } else {
          return prevArrayValue;
        }
      }
      return thisValue;
    }

    function createPath([key, ...rest], thisObject) {
      let checkObject = thisObject ? thisObject : {};
      if (rest.length > 0) {
        return { ...checkObject, [key]: createPath(rest, checkObject[key]) };
      } else {
        return { ...checkObject, [key]: handleValue(thisObject, key) };
      }
    }

    return createPath(path, object);
  }
}
