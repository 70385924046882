import React from "react";

/**
 * Célula utilizada pelo componente SistemaTable para formar tanto o th como o td da tabela.
 * @param {Object} props
 * @param {Function} props.formatValue Função usada para alterar o valor antes de ser mostrado em tela.
 * @param {Boolean} props.isDateOnly Converte o dado para dateOnly.
 * @param {string} props.name Informe a chave para retornar o valor, baseado no objeto da tabela.
 * @param {string} props.inputfilter Informe um nome para identificar o input que será utilizado como busca.
 * @param {Object} props.selectfilter Informe as configurações de filtro.
 * @param {Object} props.selectfilter.list Informe a lista que será utilizada para popular um dropDown, aceita array, string com a url de um request, ou uma função.
 * @param {Object} props.selectfilter.value Informe a chave que deve ser obtido o valor para a option
 * @param {Object} props.selectfilter.label Informe a chave que deve ser obtido o label da option.
 * @param {Object} props.selectfilter.outLabel Informe um nome para mostrar como label do dropdown.
 */
function ThisCell({
  children,
  inputfilter,
  formatValue,
  selectfilter,
  name,
  className,
  isDateOnly,
  inputType,
  addTextP,
  ...rest
}) {
  
  return addTextP
    ? <th {...{ ...rest, name, className }}><p {...{ className }}>{children}</p></th>
    : <th {...{ ...rest, name, className }}>{children}</th>;
}

export default ThisCell;
