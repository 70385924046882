import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const ItemMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-item-menu {
    width: 100%;

    p {
      font-size: 0.6rem;
      text-transform: uppercase;
      font-weight: 900;
      color: ${Colors.Color3};
    }
  }
`;