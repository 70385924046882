import { Colors } from "~/config/Colors";

export default function layout(props) {
  const { blueLayout, blueDarkLayout, cancelLayout, disabledLayout } = props || {};

  if (blueLayout) {
    return {
      background: Colors.Color18,
      color: Colors.Color2,
      borderColor: Colors.Color18,
      hoverBackground: Colors.Color18,
    };
  } else if (blueDarkLayout) {
    return {
      background: Colors.bgButton3,
      color: Colors.ftColor4,
      borderColor: Colors.bgButton3,
      hoverBackground: Colors.bgButton3,
    };
  } else if (cancelLayout) {
    return {
      background: Colors.Color1,
      color: Colors.Color3,
      borderColor: Colors.Color1,
      hoverBackground: Colors.Color1 + "e3",
      fontWeight: "500",
    };
  } else if (disabledLayout) {
    return {
      background: Colors.Color7,
      color: Colors.Color1,
      borderColor: Colors.Color7,
      hoverBackground: Colors.Color12,
      fontWeight: "500",
    };
  } else {
    return {
      background: props?.background,
      color: props?.color,
      borderColor: props?.background,
      hoverBackground: props?.hoverBackground,
      fontWeight: "500",
    };
  }
}
