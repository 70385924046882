import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable, Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import Select from 'react-select';
import { Label, Input, Select as SelectCF } from "~/componentes/CustomForm";
import IconConfirmFiltro from "~/assets/icones/IconConfirmFiltro.svg";
import IconConfirmFiltroB from "~/assets/icones/IconConfirmFiltroB.svg";

function TableComponent({
  pesquisas, 
  idPesquisa, 
  setIdPesquisa,
  centroCustos,
  setIdCentroCusto,
  servicos,
  setIdServico,
  dataDe,
  setDataDe,
  dataAte,
  setDataAte,
  setPrimeiraExec,
  setLoadedData,
}) {

  return (
    <Container>
      <Materialize className="materialize">
        <div className="filtro">
          <div className="row">
            <Label title="" className="col s2 m2 l2 no-padd-l">
              <SelectCF
                isNumber
                list={{ list: pesquisas, value: 'id', label: 'titulo' }}
                name="idPesquisa"
                firstOption={"Pesquisa"}
                defaultValue={idPesquisa}
                onChange={(e) => {
                  const v = e.currentTarget.value;
                  setIdPesquisa(v);
                }}
              />
            </Label>
            <Label title="" className="col s3 m3 l3">
              <Select
                defaultValue={centroCustos}
                isMulti
                name="colors"
                options={centroCustos}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Centro de Custos"
                onChange={(e) => {
                  let ids = [];
                  for (const itemE of e) {
                    ids.push(itemE?.value);
                  }
                  setIdCentroCusto(ids);
                }}
              />
            </Label>
            <Label title="" className="col s3 m3 l3">
              <Select
                defaultValue={servicos}
                isMulti
                name="colors"
                options={servicos}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Turnos"
                onChange={(e) => {
                  let ids = [];
                  for (const itemE of e) {
                    ids.push(itemE?.value);
                  }
                  setIdServico(ids);
                }}
              />
              {/* <SelectCF
                isNumber
                list={{ list: servicos, value: 'id', label: 'titulo' }}
                name="idServico"
                firstOption={"Turnos"}
                onChange={(e) => {
                  const v = e.currentTarget.value;
                  setIdServico(v);
                }}
              /> */}
            </Label>
            <Label title="" className="col s2 m2 l2">
              <Input
                type="date"
                name="dataDe"
                defaultValue={dataDe}
                onBlur={(e) => {
                  const v = e.currentTarget.value || "";
                  setDataDe(v);
                }}
              />
            </Label>
            <Label title="" className="col s2 m2 l2">
              <Input
                type="date"
                name="dataAte"
                defaultValue={dataAte}
                onBlur={(e) => {
                  const v = e.currentTarget.value || "";
                  setDataAte(v);
                }}
              />
            </Label>
          </div>
          <div className="button">
            <button
              className="btn-filtro"
              onClick={() => {
                setLoadedData(false);
                setPrimeiraExec(false);
              }}
            >
              <img src={localStorage.getItem("tema") == "E" ? IconConfirmFiltro : IconConfirmFiltroB} alt="Icone de Confirmação" />
            </button>
          </div>
        </div>
      </Materialize>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell className="col-w-1" name="id" inputfilter>
            Id
          </SLTableCell>
          <SLTableCell className="col-w-2" name="dispositivo.idAcesso" inputfilter>
            Dispositivo
          </SLTableCell>
          <SLTableCell className="col-w-5" name="pesquisa.titulo" inputfilter>
            Pesquisa
          </SLTableCell>
          <SLTableCell className="col-w-1" name="dataCadastro" inputfilter>
            Data
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </Container>
  );
}

export default TableComponent;
