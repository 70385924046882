import React from "react";

const sistemaListStore = React.createContext({
  state: {
    globalData: [],
    entityData: {},
    modal: { create: false, edit: false, editShow: true, show: false },
    data: [],
    selectValues: {},
    inputs: [],
    selects: [],
    requests: {
      findAll: async () => {},
      findOne: async () => {},
      deleteOne: async () => {},
      restoreOne: async () => {},
    },
    reloadData: (data = []) => {},
    createModalButton: false,
    createModalButtonT: false,
  },
  dispatch: () => {},
});

export function sistemaListCreateModalButton(createModalButton) {
  return { type: "sistemaListCreateModalButton", payload: { createModalButton } };
}

export function sistemaListCreateModalButtonT(createModalButtonT) {
  return { type: "sistemaListCreateModalButtonT", payload: { createModalButtonT } };
}

export function sistemaListUpdateGlobalData(globalData) {
  return { type: "sistemaListUpdateGlobalData", payload: { globalData } };
}

export function sistemaListUpdateEntityData(entityData) {
  return { type: "sistemaListUpdateEntityData", payload: { entityData } };
}

export function sistemaListShowModal(modal, entityData, options) {
  return { type: "sistemaListShowModal", payload: { modal, entityData, options } };
}

export function sistemaListUpdateData(data) {
  return { type: "sistemaListUpdateData", payload: { data } };
}

export function sistemaListCloseModalDate(closeModalDate) {
  return { type: "sistemaListCloseModalDate", payload: { closeModalDate } };
}

export function sistemaListUpdateProps(props) {
  return { type: "sistemaListUpdateProps", payload: { ...props } };
}

export function sistemaListConfirmSelectValues(selectValues) {
  return { type: "sistemaListConfirmSelectValues", payload: { selectValues } };
}

export function sistemaListUpdateFilterConfig({ inputs, selects, setCustomFilterModal }) {
  return { type: "sistemaListUpdateFilterConfig", payload: { inputs, selects, setCustomFilterModal } };
}

export function sistemaListEditShowModal(modal, options) {
  return { type: "sistemaListEditShowModal", payload: { modal, options } };
}

export default sistemaListStore;
