import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { Label, Input, Select } from "~/componentes/CustomForm";

import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import GroupAcoordion from "~/componentes/GroupAcoordion";

function CreateEditContent({ mountedData }) {
  const [modulos, setModulos] = useState([]);
  const [tipo, setTipo] = useState(
    mountedData?.tipo ? mountedData?.tipo : "CLI",
  );

  useEffect(() => {
    api
      .get("/signed/geral/acessos/check", { params: { tipo } })
      .then(response => {
        setModulos(response.data);
      });
  }, [tipo]);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let thisData = {
          ...data,
          ...(mountedData?.id ? { id: mountedData?.id } : {}),
          tipo,
        };
        let response;
        if (mountedData?.id)
          response = await api.put("/signed/cliente/administracao/gerenciamento/grupoperfil", thisData);
        else
          response = await api.post("/signed/cliente/administracao/gerenciamento/grupoperfil", thisData);

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${mountedData?.id ? 'atualizar' : 'cadastrar'} esse Grupo de Perfill, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${mountedData?.id ? 'atualizar' : 'cadastrar'} esse Grupo de Perfill?`,
        successModal: `Grupo de Perfill ${mountedData?.id ? 'atualizado' : 'cadastrado'} com sucesso!`,
      },
    );
  }

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">
            Preencha as informações corretamente, e se atente ao (*) pois são
            campos obrigatórios
          </p>
        </div>
        <div className="row">
          <Label title="Nome *" className="col s8 m8 l8">
            <Input name="nome" defaultValue={mountedData?.nome} />
          </Label>
          <Label title="Tipo *" className="col s4 m4 l4">
            <Select
              isNumber
              list={{
                list: [{ tipo: "CLI", nome: "Cliente" }],
                value: "tipo",
                label: "nome",
              }}
              name="tipo"
              defaultValue={mountedData?.tipo}
              onChange={e => {
                const v = e.currentTarget.value;
                setTipo(v);
              }}
            />
          </Label>
        </div>
        <div className="row">
          {modulos.length > 0 && (
            <GroupAcoordion
              defaultSelect={typeof mountedData?.acessos == "string" ? JSON.parse(mountedData?.acessos) : mountedData?.acessos}
              title={"Acessos "}
              name={"acessos"}
              itens={modulos}
            />
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateEditContent;
