import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import formStore from "../formStore";
import formHandleOnChange from "../utils/formHandleOnChange";
import formCheckDisabled from "../utils/formCheckDisabled";
import sistemaListStore from "../../../pages/Sistema/componentes/SistemaList/ThisMainComponent/thisStore";
import { TextAreaCustom } from "./styles";

/**
 * @param {Object} props Aceita todas as props de um componente React.
 * @param {any} props.value Caso seja passado um value, tanto o setter como o form irão obter o value
 *  informado e não mais o value do onChange. Sendo assim, é necessário passar um onChange caso deseje
 *  um elemento controlado.
 * @param {string} props.name Caso informado um name, o value será enviado para o form como um objeto,
 *  com o name como chave. O name utiliza a notação por pontos, logo, caso utilize por exemplo "pessoa.profissao",
 *  será enviado para o form {pessoa: {profissao: "value"}}.
 * @param {Function} props.onChange Por padrão, já possui um onChange que altera o estado da edição,
 *  Caso seja fornecido outro, ambos serão utilizados.
 * @param {Object} props.setter É usado junto do onChange, porém, já recebe o valor de forma já tratada.
 *  Pode ser fornecido um objeto contendo o setter e o name para adicionar um objeto ao estado do setter.
 *  Ao informar um name, segue o padrão de um form, porém utilizando um setter.
 * @param {Function} props.setter.setter Recebe um setState, que receberá o valor do elemento.
 * @param {string} props.setter.name Transforma o estado em um objeto contendo o name, mesmo padrão do name para Form.
 * @param {Function} props.allowNull Se true, usará dados vazios.
 * @param {Function} props.noLoading Se true, manterá o elemento ativo mesmo que um loading esteja ocorrendo.
 */
function TextArea(props) {
  const { noLoading, setter, allowNull, ...rest } = props || {};
  const { dispatch } = React.useContext(formStore);
  const disableButtons = useSelector((state) => state.disableButtons);
  const [hadValue, setHadValue] = useState(props?.value || props?.defaultValue);
  const { state } = React.useContext(sistemaListStore);
  const { edit, editShow } = state?.modal || {};

  useEffect(() => {
    if (!hadValue) {
      setHadValue(props.value || props.defaultValue);
    } else {
      formHandleOnChange(props.value || props.defaultValue, props, dispatch);
    }
  }, [hadValue, props.value, props.defaultValue, props, dispatch]);

  return (
    <TextAreaCustom
      {...rest}
      disabled={editShow && edit ? true : formCheckDisabled(props, disableButtons)}
      onChange={(e) => {
        !props.value && formHandleOnChange(e.target.value, props, dispatch);
        props?.onChange && props.onChange(e);
      }}
    />
  );
}

export default memo(TextArea);
